import { Mockup } from '@interfaces/mockup.interface';

export const TRACE_MOCKUP: Mockup = {
  success: [{
    response: {
      status: 201
    }
  }],
  failures: [
    {
      response: {}
    }
  ]
};
