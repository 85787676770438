import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EXECUTIVE_URL, POST_VENTA_URL } from '@constants';
import { ExecutivePorfolioResponse } from '@interfaces/executivePortfolioResponse.interface';
import { InfoExecutivePortfolio } from '@interfaces/infoExecutivePortfolio.interface';
import { SideMenuItem } from '@interfaces/sideMenuItem.interface';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { InactivityService } from '@services/inactivityTime/inactivityTime.service';
import { PostVentaService } from '@services/post-venta/post-venta.service';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MONTH_DICT_PORTFOLIO } from 'util/portfolio.constants';
import { DEVICE_TYPE, EXECUTIVE_RUT } from 'util/storage.constants';

@Component({
  selector: 'app-my-portfolio',
  templateUrl: './my-portfolio.component.html',
  styleUrls: ['./my-portfolio.component.scss']
})
export class MyPortfolioComponent implements OnInit {
  public loading = true;
  public searcher = false;
  public showSearcher = true;
  public isBiotablet: boolean;
  public infoExecutive: InfoExecutivePortfolio;
  public monthSelected = null;
  public affiliateSelected = null;
  public portfolio: ExecutivePorfolioResponse[];
  public sideNavItems: SideMenuItem[] = [];
  public serviceError: string;

  private backUpPortfolio: ExecutivePorfolioResponse[];
  private isFromSearcher = false;

  constructor(
    private router: Router,
    private postVentaService: PostVentaService,
    private authenticationService: AuthenticationService,
    private location: Location,
    private inactivityService: InactivityService

  ) { }

  public ngOnInit() {
    this.inactivityService.startInactivityPostVentaService();
    this.loading = true;
    const executiveRut = localStorage.getItem(EXECUTIVE_RUT);
    this.isBiotablet = localStorage.getItem(DEVICE_TYPE) === 'biotablet';
    const getExecutivePortfolioService = this.postVentaService.getExecutivePortfolio(executiveRut);
    const getExecutiveService = this.authenticationService.executiveLogin(executiveRut);

    forkJoin([getExecutivePortfolioService, getExecutiveService]).pipe(
      finalize(() => this.loading = false),
    ).subscribe(
      ([portfolio, executive]: [Array<ExecutivePorfolioResponse>, InfoExecutivePortfolio]) => {
        this.serviceError = null;
        this.portfolio = portfolio;
        this.backUpPortfolio = [...portfolio];
        this.infoExecutive = executive;
        this.infoExecutive.rut = executiveRut;
        this.generateSideNavItems();
      },
      (error) => {
        this.serviceError = 'portfolioService';
      }
    );
  }

  public getPortfolioMonth(monthNumber: number) {
    return this.portfolio.find(element => element.month === monthNumber);
  }

  public getPortfolioAffiliate(rut: string) {
    return this.getPortfolioMonth(this.monthSelected).affiliates.find(affiliate => affiliate.rut === rut);
  }

  public goToSearchMonth(monthNumber$) {
    this.portfolio = [...this.backUpPortfolio];
    this.affiliateSelected = null;
    this.monthSelected = monthNumber$;
  }

  public handleSearcher(option$) {
    this.portfolio = [...this.backUpPortfolio];
    this.searcher = Boolean(option$);
  }

  public goToAffiliateDetail(rut$: string) {
    this.portfolio = [...this.backUpPortfolio];
    this.affiliateSelected = rut$;
    this.searcher = false;
  }

  public goToAffiliateDetailFromSearcher(affiliate) {
    this.portfolio = [...this.backUpPortfolio];
    this.affiliateSelected = affiliate.rut;
    this.monthSelected = affiliate.month;
    this.searcher = false;
    this.isFromSearcher = true;
  }

  public back() {
    if (!this.backUpPortfolio) return this.location.back();

    this.portfolio = [...this.backUpPortfolio];

    if (this.isFromSearcher) {
      this.isFromSearcher = false;
      this.affiliateSelected = null;
      this.monthSelected = null;
      return this.searcher = true;
    }

    if (this.searcher) return this.searcher = false;
    if (this.affiliateSelected) return this.affiliateSelected = null;
    if (this.monthSelected) return this.monthSelected = null;
    return this.location.back();
  }

  public getWhiteIcon(monthNumber: number) {
    return `${MONTH_DICT_PORTFOLIO[monthNumber]}-white`;
  }

  public generateSideNavItems() {
    this.portfolio.forEach((month) => {
      if (month.affiliates.length > 0) this.sideNavItems.push({
        title: `Mes ${month.month}`,
        highlight: month.title,
        icon: this.getWhiteIcon(month.month),
        route: month.month,
      });
    }
    );
  }

  public reLoad() {
    const isBiotablet = localStorage.getItem(DEVICE_TYPE) === 'biotablet';
    const url = isBiotablet ? EXECUTIVE_URL : POST_VENTA_URL;
    this.router.navigate([url]);
  }

  public markAsContacted($event) {
    const affiliate = this.getPortfolioAffiliate(this.affiliateSelected);

    affiliate.contacted = true;
    affiliate.contactDate = new Date().toISOString();
  }
}
