import { Mockup } from '@interfaces/mockup.interface';

export const TOKEN_MOCK: Mockup = {
  success: [{
    response: [
      {
        token: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJod…wEhumHyCW8FjVFL-H0zCoUWHmTe1GjzLsAoEUA4IUt7GM_cqA'
      }
    ]
  }],
  failures: [],
};
