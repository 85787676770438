import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ASSISTED_LOGIN_URL, POST_VENTA_LOGIN_URL } from '@constants';
import { DEVICE_TYPE } from 'util/storage.constants';

@Component({
  selector: 'app-executive',
  templateUrl: './executive.component.html',
  styleUrls: ['./executive.component.scss']
})
export class ExecutiveComponent implements OnInit {
  public loading = true;
  constructor(private router: Router) { }

  public ngOnInit() {
    this.loading = false;
  }

  public goToAssistedTransfer() {
    this.router.navigateByUrl(ASSISTED_LOGIN_URL);
  }

  public goToLoginPostVenta() {
    localStorage.setItem(DEVICE_TYPE, 'biotablet');
    this.router.navigateByUrl(POST_VENTA_LOGIN_URL);
  }
}
