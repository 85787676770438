import { Mockup } from '@interfaces/mockup.interface';

export const SEARCH_EMPLOYER: Mockup = {
  success: [{
    response: {
      address: '1634 Hackett Station Apt. 559',
      communeCode: '1402',
      regionCode: '1',
      cellphoneNumber: '84584949',
      email: 'test@bridgette_halvorson.cl',
      phoneNumber: '284584949',
      rut: '111111111',
      businessName: 'Empresa SPA',
    }
  }],
  failures: [{
    response: {
      code: 40081,
      message: 'Error.',
      messageDescription: 'Error message.',
    }
  }]
};
