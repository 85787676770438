import { NgModule } from '@angular/core';
import { FormatRutDirective } from './format-rut/format-rut.directive';

@NgModule({
  declarations: [
    FormatRutDirective,
  ],
  exports: [
    FormatRutDirective,
  ]
})
export class DirectivesModule {}
