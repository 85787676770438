import { Component } from '@angular/core';
import { Util } from '@util';
// import { GoogleAnalyticsProvider } from '@providers/googleAnalytics/googleAnalytics';
import { FacebookPixelProvider } from '@providers/facebookPixel/facebookPixel';
import { GtmService } from '../../../services/gtm/gtm.service';

@Component({
  selector: 'app-contact-successful',
  templateUrl: './contact-successful.component.html',
  styleUrls: ['./contact-successful.component.scss']
})
export class ContactSuccessfulComponent {
  constructor(
    // private googleAnalyticsProvider: GoogleAnalyticsProvider,
    private facebookPixel: FacebookPixelProvider,
    private gtmService: GtmService,
    public util: Util,
  ) {
    // this.googleAnalyticsProvider.registerPageView('contactSuccessful');
    this.facebookPixel.registerPageView('contactSuccessful');
    this.gtmService.addTag('contactSuccesful');
  }
}
