import { Component, Input } from '@angular/core';
import { PortfolioAffiliateCard } from '@interfaces/executivePortfolioResponse.interface';

@Component({
  selector: 'app-info-card-action',
  templateUrl: './info-card-action.component.html',
  styleUrls: ['./info-card-action.component.scss']
})
export class InfoCardActionComponent {
  @Input() public title: string;
  @Input() public rut: string;
  @Input() public departureDate: string;
  @Input() public contacted: boolean;
  @Input() public currentUser: boolean;

  @Input() public markIcon: string;

  @Input() public info: PortfolioAffiliateCard[];
  @Input() public actionButton: () => any;

  constructor() { }

}
