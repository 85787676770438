import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CAV_OPENING_DISCLAIMER_TEXT } from '@constants';
import { ClientDataResponse } from '@interfaces/clientData.interface';
import { Util } from '@util';
import { CLIENT_DATA } from 'util/storage.constants';

@Component({
  selector: 'app-two-option-selection',
  templateUrl: './two-option-selection.component.html',
  styleUrls: ['./two-option-selection.component.scss']
})
export class TwoOptionSelectionComponent implements OnInit {
  @Input() public clientData: ClientDataResponse;
  // TODO: remove this
  @Input() public disableAPV = false;
  @Output() public next = new EventEmitter();
  public selectedOption: string;
  public cavOpeningDisclaimerText = CAV_OPENING_DISCLAIMER_TEXT;
  public disclaimerHidden = true;

  constructor(
    public util: Util,
  ) { }

  public ngOnInit() {
    this.clientData = JSON.parse(localStorage.getItem(CLIENT_DATA));
  }

  public goBack() {
    return window.history.go(-2);
  }

  public select(option: string): void {
    // TODO: remove this
    if (this.disableAPV && option === 'APV') return;
    this.selectedOption = option;
    this.disclaimerHidden = !this.isSelected('CAV');
  }

  public isSelected(option: string): boolean {
    return this.selectedOption === option;
  }

  public send() {
    this.next.emit({ step: 1, selectedOption: this.selectedOption });
    this.selectedOption = '';
  }

}
