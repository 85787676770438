export const ROUTES_WITH_RECAPTCHA_VALIDATION = [
    {
      route: 'contactar',
      method: 'POST',
      action: 'sendContactData'
    },
    {
      route: 'continuar',
      method: 'GET',
      action: 'validateCode'
    },
    {
      route: 'ingresar',
      method: 'POST',
      action: 'uniqueKeyLogin'
    },
    {
      route: 'ingresar',
      method: 'GET',
      action: 'securityQuestionsLogin'
    },
    {
      route: 'recaptcha-validator',
      method: 'GET',
      action: 'validateRecaptcha'
    },
    {
      route: 'withdrawals/generic/withdrawal/available',
      method: 'GET',
      action: 'isWithdrawalAvailable'
    },
  ];
