import { Mockup } from '@interfaces/mockup.interface';

export const SEND_PASSWORD_MOCKUP: Mockup = {
  success: [{
    response: {
      status: 200
    }
  }],
  failures: [{
    response: {
      code: 500,
      message: 'Error enviando contraseña temporal',
      messageDescription: 'Error enviando contraseña temporal',
    }
  }]
};
