import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSliderModule, MatDialogModule, MatFormFieldModule, MatInputModule, MatSelectModule } from '@angular/material';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ClientIdentityValidationComponent } from '@components/client-identity-validation/client-identity-validation.component';
import { ComplainTicketCardComponent } from '@components/my-portfolio-components/complain-ticket-card/complain-ticket-card.component';
import { InfoCardActionComponent } from '@components/info-card-action/info-card-action.component';
import { InfoCardPersonComponent } from '@components/info-card-person/info-card-person.component';
import { LoadingIdentityValidationComponent } from '@components/loading-identity-validation/loading-identity-validation.component';
import { PaginatorComponent } from '@components/paginator/paginator.component';
import { SideNavMenuComponent } from '@components/side-nav-menu/side-nav-menu.component';
import { SwitchButtonComponent } from '@components/switch-button/switch-button.component';
import { DirectivesModule } from '@directives/directives.module';
import { HeaderBiodesktopComponent } from '@components/header-biodesktop/header-biodesktop.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatSliderModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    DirectivesModule,
    MatSidenavModule
  ],
  declarations: [
    ClientIdentityValidationComponent,
    ComplainTicketCardComponent,
    InfoCardActionComponent,
    InfoCardPersonComponent,
    LoadingIdentityValidationComponent,
    SwitchButtonComponent,
    SideNavMenuComponent,
    HeaderBiodesktopComponent,
    PaginatorComponent,
  ],
  exports: [
    ClientIdentityValidationComponent,
    ComplainTicketCardComponent,
    CommonModule,
    InfoCardActionComponent,
    InfoCardPersonComponent,
    LoadingIdentityValidationComponent,
    SwitchButtonComponent,
    SideNavMenuComponent,
    HeaderBiodesktopComponent,
    PaginatorComponent,
  ],
  providers: [
  ]
})
export class SharedModule {}
