import { Mockup } from '@interfaces/mockup.interface';

export const ATTENTION_CODE_MOCK: Mockup = {
  success: [{
    response: { attentionCode: 1 },
  }],
  failures: [{
    response: {
      message: 'Error',
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
      code: 50001,
    },
  }, {
    response: {
      message: 'Error',
      messageDescription: 'Error Description Bad Request',
      code: 40000,
    },
  }],
};
