import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FilterInterface } from '@interfaces/filter.interface';

@Component({
  selector: 'app-filter-form',
  templateUrl: './filter-form.component.html',
  styleUrls: ['./filter-form.component.scss']
})
export class FilterFormComponent implements OnInit, OnChanges {
  @Input() public filters: Array<FilterInterface>;
  @Input() public selectedFilterIn: FilterInterface;
  @Output() public selectedFilterOut = new EventEmitter();
  public filterForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
  ) {
  }

  public ngOnChanges(): void {
    if (this.selectedFilterIn.id !== 'closed') return;
    this.filterForm.controls['filter'].setValue('withoutFilter');
  }

  public ngOnInit(): void {
    this.filterForm = this.formBuilder.group({
      filter: [this.selectedFilterIn.id],
    });
    this.filterSubscription();
  }

  private filterSubscription() {
    this.filterForm.get('filter').valueChanges
      .subscribe(selectedFilterId => {
        const selectedFilter = this.filters.find((filter) => filter.id === selectedFilterId);
        this.selectedFilterOut.emit(selectedFilter);
      });
  }
}
