import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SIMULATOR_DETAILS, INVESTOR_PROFILE_BOX_URL } from '@constants';
import { ClientDataResponse } from '@interfaces/clientData.interface';
import { InvestorProfileBox } from '@interfaces/investor-profile.interface';

@Component({
  selector: 'app-affiliate-simulator',
  templateUrl: './affiliate-simulator.component.html',
  styleUrls: ['./affiliate-simulator.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AffiliateSimulatorComponent implements OnInit {
  @Input() public clientData: ClientDataResponse;

  public cards;
  public clientDataForSimulation: InvestorProfileBox;
  public showInformation = true;

  constructor(private router: Router) {}

  public ngOnInit() {
    this.cards = SIMULATOR_DETAILS.filter(card => card.url);
  }

  public openNewTab(card) {
    window.open(card.url);
  }

  public goToInvestorProfileBox(): void {
    this.router.navigateByUrl(INVESTOR_PROFILE_BOX_URL);
  }
}
