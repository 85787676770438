import { AuthGuardService } from '@services/auth-guard/auth-guard.service';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PostVentaModule } from './modules/post-venta/post-venta.module';

const routes: Routes = [
  {
    path: '', loadChildren: './modules/login/login.module#LoginModule',
  },
  {
    path: 'afp',
    loadChildren: './modules/transfer/transfer.module#TransferModule',
    canActivate: [AuthGuardService],
  },
  {
    path: 'post-venta',
    loadChildren: () => import('./modules/post-venta/post-venta.module').then(module => module.PostVentaModule)
    , canActivate: [AuthGuardService],
  },
  // {
  //   path: 'access-portal',
  //   loadChildren: () => import('./modules/client-balances/client-balances.module').then(module => module.ClientBalancesModule)
  //   , canActivate: [AuthGuardService],
  // },
  {
    path: '**', redirectTo: '/'
  }
];

@NgModule({
  imports: [
    PostVentaModule,
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
