import { Component } from '@angular/core';

@Component({
  selector: 'app-profitability-disclaimer',
  templateUrl: './profitability-disclaimer.component.html',
  styleUrls: ['./profitability-disclaimer.component.scss']
})
export class ProfitabilityDisclaimerComponent {

  // Disclaimer estos datos hay que recuperarlos luego del cms
  public disclaimerTitle = 'Sobre la rentabilidad de los fondos de pensiones';
  public disclaimerText = `
    La rentabilidad es variable, por lo que nada garantiza que las rentabilidades pasadas se repitan en el futuro.
    Infórmese sobre la rentabilidad de su Fondo de Pensiones, las comisiones y la calidad de servicio de las AFP
    en el sitio web de la Superintendencia de Pensiones
  `;
  public disclaimerLinkText = ' https://www.spensiones.cl.';

}
