import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ClientBusinessTube, ClientsBusinessTubeResponse } from '@interfaces/clientsBusinessTube.interface';
import { StatusBusinessTube } from '@interfaces/statusBusinessTube.interface';
import { Util } from '@util';
import * as _ from 'lodash';

@Component({
  selector: 'app-business-tube-search-client',
  templateUrl: './search-client.component.html',
  styleUrls: ['./search-client.component.scss']
})
export class BusinessTubeSearchClientComponent {
  @Input() public clients: ClientsBusinessTubeResponse;
  @Output() public affiliateSelect$ = new EventEmitter<any>();
  @Output() public actionRoute = new EventEmitter();
  @Output() public closeSearcherEmitter = new EventEmitter();
  @Output() public clientSelected = new EventEmitter();
  @Output() public reloadClients = new EventEmitter();
  @Output() public changeSelectedStatus = new EventEmitter();
  public searchForm: FormGroup;
  public searchResult = [];
  public searched = false;
  public loading = false;

  constructor(
    public util: Util,
    private formBuilder: FormBuilder,
  ) {
    this.searchForm = this.formBuilder.group({
      search: [''],
    });
  }

  get isSearchActive() {
    const { search } = this.searchForm.controls;
    return search.value !== '';
  }

  get isSearchingByRut() {
    const { search } = this.searchForm.controls;
    return !isNaN(search.value[0]);
  }

  public setLoading($event: boolean) {
    this.loading = $event;
  }

  public updatedStatus($event: StatusBusinessTube) {
    this.reloadClients.emit();
    this.changeSelectedStatus.emit($event);
  }

  public goToClientDetail($client: ClientBusinessTube) {
    this.clientSelected.emit($client);
    this.actionRoute.emit('details');
  }

  public closeSearcher() {
    this.closeSearcherEmitter.emit();
  }

  public retrySearch() {
    this.searchResult = [];
    this.searched = false;
    const { search } = this.searchForm.controls;
    search.setValue('');
  }

  public searchClients() {
    this.searchResult = [];
    const { search } = this.searchForm.controls;

    const reg = this.isSearchingByRut ?
    this.util.rutClean(search.value) : new RegExp(this.util.removeAccents(search.value).replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'gi');

    for (const key of Object.keys(this.clients)) {
      this.clients[key].forEach((client) => {
        const match = this.util.removeAccents(client.name).match(reg) ||
          this.util.rutClean(client.clientRut) === reg;
        if (match) this.searchResult.push({ ...client, status: key });
      });
    }
    this.searched = true;
  }
}
