import { Injectable } from '@angular/core';
import { environment } from '@env';
import { LogInterface } from '@interfaces/log.interface';
import { HttpClientInterceptor } from '@providers/httpClientInterceptor/httpClientInterceptor';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { Util } from '@util';
import { Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LOG_MOCKUP } from './log.mock';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  public baseURL: string;

  constructor(
    public http: HttpClientInterceptor,
    public util: Util,
    public authenticationService: AuthenticationService,
  ) {
    this.baseURL = environment.baseURL;
  }

  public sendLog(request: LogInterface): Subscription {
    return this.http.post(`${ this.baseURL }logger/`, request, LOG_MOCKUP).pipe(
      catchError((error) => throwError(error))
    ).subscribe(() => {}, () => {});
  }
}
