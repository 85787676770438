import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TRANSFER_STATES, commissionStructureLink, profitabilityLink, serviceQualityLink } from '@constants';
import { ClientValidationData } from '@interfaces/client.interface';
import { GoogleAnalyticsProvider } from '@providers/googleAnalytics/googleAnalytics';
import { LoadingProvider } from '@providers/loading/loading';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { Util } from '@util';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FirebaseUtil } from 'util/firebase-util';


@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  providers: [
    FirebaseUtil,
  ]
})
export class ConfirmComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  private ref: AngularFireObject<ClientValidationData>;
  public firebaseId: string;
  public accountsSelected: any;
  public client: any;
  public transferStatus: any;
  public voucherRenderData: any;
  public legalTerms: FormGroup;
  public commissionStructureLink = commissionStructureLink;
  public profitabilityLink = profitabilityLink;
  public serviceQualityLink = serviceQualityLink;
  public currentStatus: string;
  public transferStates: any = TRANSFER_STATES;
  public completed: boolean;
  public completedProductOpening: boolean;

  constructor(
    private router: Router,
    private firebaseDatabase: AngularFireDatabase,
    private loadingProvider: LoadingProvider,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private googleAnalyticsProvider: GoogleAnalyticsProvider,
    private firebaseUtil: FirebaseUtil,
    public util: Util,
  ) {
    this.setFirebaseId();
    this.legalTerms = this.formBuilder.group({
      commissionStructureAccordance: [false, [Validators.requiredTrue]],
      profitabilityAccordance: [false, [Validators.requiredTrue]],
    });
    this.googleAnalyticsProvider.registerPageView('continue');
  }

  public async ngOnInit() {
    await this.authenticationService.logout();
    if (this.firebaseId) {
      this.ref = this.firebaseDatabase.object(`client/${this.firebaseId}`);
      this.loadOngoingValidation();
    }
  }

  public loadOngoingValidation() {
    this.ref.valueChanges()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.currentStatus = data.status;
        if (data.completed) {
          this.loadingProvider.hideLoading();
          this.completed = data.completed;
        }
        if (data.completedProductOpening) {
          this.completedProductOpening = data.completedProductOpening;
        }
        if (data.status === TRANSFER_STATES.WAITING) {
          this.loadingProvider.hideLoading();
        }
        if (data.voucherRenderData) {
          this.voucherRenderData = data.voucherRenderData;
          this.loadingProvider.hideLoading();
        }
      });
  }

  public confirm() {
    this.firebaseUtil.confirm(this.firebaseId);
  }

  public cancel() {
    this.firebaseUtil.cancel(this.firebaseId);
  }

  public setFirebaseId() {
    if (!this.router.getCurrentNavigation().extras.state) return;
    const { firebaseId } = this.router.getCurrentNavigation().extras.state;
    this.firebaseId = firebaseId;
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
