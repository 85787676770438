import { Injectable } from '@angular/core';
import { environment } from '@env';
import { ProductResponse } from '@interfaces/getProductsResponse.interface';
import { OpeningDiscountDataRequest } from '@interfaces/openingDiscountDataRequest.interface';
import { Transfer, TransferRequest, TransferResponse } from '@interfaces/transfer.interface';
import { HttpClientInterceptor } from '@providers/httpClientInterceptor/httpClientInterceptor';
import { Util } from '@util';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CLIENT_DATA } from 'util/storage.constants';
import { AuthenticationService } from '../authentication/authentication.service';
import { GET_APV_DISCOUNT_FORM_MOCK } from './getApvDiscountForm.mock';
import { GET_CAV_DISCOUNT_FORM_MOCK } from './getCavDiscountForm.mock';
import { PRODUCT_BALANCE_LIST } from './mocks/products.mock';
import { OPENING_CAV_MOCK } from './openingCav.mock.ts';
import { OPENING_DISCOUNT_MOCK } from './openingDiscount.mock';
import { TRANSFER_MOCK } from './transfer.mock';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {
  public baseURL: string;

  constructor(
    private http: HttpClientInterceptor,
    private util: Util,
    private authenticationService: AuthenticationService,
  ) {
    this.baseURL = environment.baseURL;
  }

  public transfer(data: Transfer, firebaseId?: string): Observable<TransferResponse> {
    const transfer = {
      ...data,
      rut: this.authenticationService.uid(),
      executiveRut: this.authenticationService.getExecutiveRut(),
      traceID: this.util.getTraceID(),
      originCode: this.util.getOriginTransfer()
    } as TransferRequest;
    if (firebaseId) transfer.firebaseId = firebaseId;
    return this.http.post(`${this.baseURL}accounts/transfer`, transfer, TRANSFER_MOCK)
      .pipe(catchError(error => this.util.generalCatchError(error)));
  }

  public getProductsBalance(rut: string): Observable<ProductResponse[]> {
    return this.http.get(`${this.baseURL}post-venta/products-balance?rut=${rut}`, PRODUCT_BALANCE_LIST)
      .pipe(catchError(error => this.util.generalCatchError(error)));
  }

  public apvOpenDiscount(data: OpeningDiscountDataRequest) {
    return this.openDiscount('apv', data);
  }

  public openDiscount(accountType: 'apv' | 'cav', data: OpeningDiscountDataRequest) {
    const clientData = JSON.parse(localStorage.getItem(CLIENT_DATA));
    const rut = clientData.rut;
    return this.http.post(`${environment.baseAppURL}accounts/${accountType}/opening_discount/`, { rut, ...data }, OPENING_DISCOUNT_MOCK)
      .pipe(catchError(error => this.util.generalCatchError(error)));
  }

  public getDiscountForm(accountType: 'apv' | 'cav') {
    const mock = accountType === 'apv' ? GET_APV_DISCOUNT_FORM_MOCK : GET_CAV_DISCOUNT_FORM_MOCK;
    const clientData = JSON.parse(localStorage.getItem(CLIENT_DATA));
    const rut = clientData.rut;
    return this.http.get(`${environment.baseAppURL}accounts/${accountType}/discount_form/?rut=${rut}`, mock)
      .pipe(catchError(error => this.util.generalCatchError(error)));
  }

  public openCav(request, rut) {
    return this.http.post(`${environment.baseAppURL}clients/clients/${rut}/accounts/v2/cav`, request, OPENING_CAV_MOCK)
      .pipe(catchError(error => this.util.generalCatchError(error)));
  }

}

