import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ROUTES_WITH_RECAPTCHA_VALIDATION } from '@recaptcha';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';


@Injectable()
export class RecaptchaInterceptor implements HttpInterceptor {

  constructor(
    private recaptchaService: ReCaptchaV3Service
  ) { }

  // tslint:disable-next-line: member-access
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const matches = ROUTES_WITH_RECAPTCHA_VALIDATION.filter(
      (routes) => routes.method === request.method && this.urlMatch(routes.route, request.url));
    if (matches.length === 1) {
      return from(this.getRecaptchaToken(matches[0].action))
        .pipe(
          switchMap((recaptchaToken: string) => {
            let requestClone: HttpRequest<any>;
            if (request.method === 'GET') {
              requestClone = request.clone({
                params: request.params.append('recaptchaToken', recaptchaToken),
                headers: request.headers.append('recaptcha-token', recaptchaToken)
              });
            } else if (request.method === 'POST') {
              requestClone = request.clone({
                body: { ...request.body, recaptchaToken },
              });
            }
            return next.handle(requestClone);
          })
        );
    }
    return next.handle(request);
  }

  private urlMatch(route: string, url: string) {
    if (url.includes('contacts') && route.includes('contactar')) { return true; }
    if (this.isUnassistedLoginUrl(url) && route.includes('ingresar')) { return true; }
    if (url.includes('recaptcha-validator') && route.includes('continuar')) { return true; }
    if (url.includes('generic/withdrawal/available') && route.includes('generic/withdrawal/available')) { return true; }
    return url.endsWith(route);
  }

  private isUnassistedLoginUrl(url: string) {
    return url.includes('unique-key') || url.includes('security-questions');
  }

  private getRecaptchaToken(actionKey: string) {
    return this.recaptchaService.execute(actionKey);
  }
}
