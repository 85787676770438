import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BUSINESS_TUBE_ERRORS, EMAIL_PATTERN, ERROR_MESSAGES_FORM, MAX_LENGTH_RUT, PHONE_PATTERN } from '@constants';
import { Util } from '@util';
import * as _ from 'lodash';
import { RutValidator } from 'ng2-rut';
import { options } from '../business-tube.component';
import { PostVentaService } from '@services/post-venta/post-venta.service';
import { finalize } from 'rxjs/operators';
import { Executive } from '@interfaces/executive.interface';
import { ClientBusinessTube } from '@interfaces/clientsBusinessTube.interface';
import { EmailDomainValidator } from '@providers/emailDomainValidator/emailDomainValidator';

@Component({
  selector: 'app-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.scss']
})
export class BusinessTubeClientFormComponent implements OnChanges, OnInit {
  @Input() public option: options;
  @Input() public infoExecutive: Executive;
  @Input() public clientSelected: any;
  @Output() public actionRoute = new EventEmitter();
  @Output() public reloadClients = new EventEmitter();
  @Output() public modifiedClient = new EventEmitter();

  public loading = false;
  public serviceError: string;
  public clientForm: FormGroup;
  public searchResult = [];
  public searched = false;
  public minLengthPhone = 9;
  public maxLengthPhone = 9;
  public maxLengthForRut = MAX_LENGTH_RUT;
  private alreadyExistsCode = '6';
  private clientsArray: string[];
  private businessArray: string[];

  constructor(
    public util: Util,
    private rutValidator: RutValidator,
    private formBuilder: FormBuilder,
    private postVentaService: PostVentaService,
    private emailDomainValidator: EmailDomainValidator,
  ) {
    this.clientForm = this.formBuilder.group({
      rut: ['', [Validators.required, this.rutValidator]],
      name: [''],
      phone: [, [Validators.maxLength(this.maxLengthPhone), Validators.minLength(this.minLengthPhone), Validators.pattern(PHONE_PATTERN)]],
      email: ['', [Validators.email, Validators.pattern(EMAIL_PATTERN)]],
      businessRut: [''],
      comments: [''],
    });
  }

  public ngOnInit() {
    const exemptPaymentClients = localStorage.getItem('exemptPaymentClients');
    const exemptPaymentBusiness = localStorage.getItem('exemptPaymentBusiness');
    this.clientsArray = exemptPaymentClients.split(',');
    this.businessArray = exemptPaymentBusiness.split(',');
  }

  public ngOnChanges(): void {
    this.serviceError = null;
    this.loadClientForm();
  }

  get clientRut() {
    return this.clientForm.controls['rut'] as FormControl;
  }

  get businessRut() {
    return this.clientForm.controls['businessRut'] as FormControl;
  }

  get form() {
    return this.clientForm.controls;
  }

  public getErrorMessage(controlName: string) {
    const control = this.form[controlName];
    if (control.hasError('required')) {
      return ERROR_MESSAGES_FORM['required'];
    }

    if (this.invalidEmail(controlName)) {
      return ERROR_MESSAGES_FORM['invalidEmail'];
    }

    if (control.hasError('invalidDomain')) {
      return ERROR_MESSAGES_FORM['invalidDomain'];
    }

    if (control.hasError('invalidRut')) {
      return ERROR_MESSAGES_FORM['invalidRut'];
    }

    if (this.invalidPhone(controlName)) {
      return ERROR_MESSAGES_FORM['invalidPhone'];
    }
    return '';
  }

  public cancel() {
    if (!this.clientSelected) return this.actionRoute.emit('summary');
    this.actionRoute.emit('details');
  }

  public saveProspect() {
    this.loading = true;
    const clientInfo = { ...this.clientForm.value };

    const { rut: clientRut, name, comments, phone, email, businessRut } = clientInfo;

    const exemptPayment = {
      client: this.clientsArray.includes(this.util.rutClean(clientRut.toUpperCase())),
      business: businessRut ? this.businessArray.includes(this.util.rutClean(businessRut.toUpperCase())) : false
    };

    const request = {
      clientRut,
      name,
      businessRut,
      email,
      comments,
      phone,
      executiveRut: this.infoExecutive.rut,
      exemptPayment,
      archived: false
    } as ClientBusinessTube;
    this.postVentaService.createClientBusinessTube(request).pipe(
      finalize(() => this.loading = false))
      .subscribe((client) => {
        this.reloadClients.emit();
        this.modifiedClient.emit({
          ...client,
          exemptPayment
        });
        this.actionRoute.emit('details');
      },
        (error) => {
          this.serviceError = this.entityAlreadyExists(error) ? BUSINESS_TUBE_ERRORS.ALREADY_EXISTS : BUSINESS_TUBE_ERRORS.CREATE;
        });
  }

  public updateClient() {
    this.loading = true;
    const clientInfo = { ...this.clientForm.value };
    const { rut: clientRut, name, comments, phone, email, businessRut } = clientInfo;
    const exemptPayment = {
      client: this.clientsArray.includes(this.util.rutClean(clientRut.toUpperCase())),
      business: businessRut ? this.businessArray.includes(this.util.rutClean(businessRut.toUpperCase())) : false
    };
    const request = {
      clientRut,
      name,
      email,
      businessRut,
      comments,
      phone,
      executiveRut: this.infoExecutive.rut,
      status: this.clientSelected.status,
      exemptPayment,
      archived: this.clientSelected.archived
    } as ClientBusinessTube;
    this.postVentaService.updateClientBusinessTube(request).pipe(
      finalize(() => this.loading = false))
      .subscribe((client) => {
        this.reloadClients.emit();
        this.modifiedClient.emit({
          ...client,
          exemptPayment
        });
        this.actionRoute.emit('details');
      },
        () => this.serviceError = BUSINESS_TUBE_ERRORS.UPDATE);
  }

  public async validateEmailDomain() {
    const emailControl = this.clientForm.controls['email'];
    const email = this.clientForm.value['email'];
    if (this.invalidEmail('email') || !email) return;
    const validDomain = await this.emailDomainValidator.validateEmailDomain(email);
    if (!validDomain) return emailControl.setErrors({ invalidDomain: true });
  }

  private entityAlreadyExists(error: any): boolean {
    return error && error.messageError && error.messageError[0] === this.alreadyExistsCode;
  }

  private invalidEmail(controlName: string) {
    const control = this.form[controlName];
    return control.hasError('email') || (control.hasError('pattern') && controlName === 'email');
  }

  private loadClientForm() {
    if (!this.clientSelected) return;
    this.clientForm.controls['rut'].setValue(this.clientSelected.clientRut);
    this.clientForm.controls['businessRut'].setValue(this.clientSelected.businessRut);
    this.clientForm.controls['phone'].setValue(this.clientSelected.phone);
    this.clientForm.controls['name'].setValue(this.clientSelected.name);
    this.clientForm.controls['email'].setValue(this.clientSelected.email);
    this.clientForm.controls['comments'].setValue(this.clientSelected.comments);
  }

  private invalidPhone(controlName: string) {
    const control = this.form[controlName];
    return control.hasError('minlength') || control.hasError('maxlength') ||
      (control.hasError('pattern') && controlName === 'cellphoneNumber');
  }
}
