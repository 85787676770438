import { Mockup } from '@interfaces/mockup.interface';

export const VERIFY_BLOCKED_CLIENT: Mockup = {
  success: [{
    response: {
      'blocked': false,
      'success': true
    }
  }, {
    response: {
      'blocked': false,
      'success': true,
      'lockDate': '2019-09-02T13:57:23.290',
      'attemps': 1,
    }
  }, {
    response: {
      'blocked': true,
      'success': true,
      'lockDate': '2019-09-02T13:57:23.290',
      'unlockDate': '2019-09-25T13:57:23.290',
      'attemps': 2,
    }
  }],
  failures: [{
    response: {
      code: 50001,
      message: 'Error.',
      messageDescription: 'Error.',
    }
  }],
};
