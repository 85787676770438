import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  EMAIL_PATTERN, ERROR_MESSAGES_FORM
} from '@constants';
import { ClientDataResponse } from '@interfaces/clientData.interface';
import { EmailDomainValidator } from '@providers/emailDomainValidator/emailDomainValidator';
import { Util } from '@util';
import { CLIENT_DATA } from 'util/storage.constants';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.scss']
})
export class EmailConfirmationComponent implements OnInit {
  @Input() public skipEmployersStep: boolean;
  @Input() public selectedOption: string;
  @Input() public origin: string;
  @Output() public next = new EventEmitter();
  @Output() public goBackEmit = new EventEmitter();
  @Output() public isLoading = new EventEmitter();
  public clientData: ClientDataResponse;
  public emailForm: FormGroup;

  constructor(
    private emailDomainValidator: EmailDomainValidator,
    private formBuilder: FormBuilder,
    public util: Util,
  ) {
    this.emailForm = this.formBuilder.group({
      email: [{value: '', disabled: true}, [Validators.required, Validators.email, Validators.pattern(EMAIL_PATTERN)]],
    });
  }

  public ngOnInit() {
    this.clientData = JSON.parse(localStorage.getItem(CLIENT_DATA));
    this.emailForm.controls['email'].setValue(this.clientData.email);
  }

  public get isCodeOrigin() {
    return this.origin === 'code';
  }

  get form() {
    return this.emailForm.controls;
  }

  public getErrorMessage(controlName: string) {
    const control = this.form[controlName];
    if (control.hasError('required')) {
      return ERROR_MESSAGES_FORM['required'];
    }
    if (this.invalidEmail(controlName)) {
      return ERROR_MESSAGES_FORM['invalidEmail'];
    }
    if (control.hasError('invalidDomain')) {
      return ERROR_MESSAGES_FORM['invalidDomain'];
    }
    return '';
  }

  public async validateEmailDomain() {
    const emailControl = this.emailForm.controls['email'];
    const email = this.emailForm.value['email'];
    if (this.invalidEmail('email') || !email) return;
    const validDomain = await this.emailDomainValidator.validateEmailDomain(email);
    if (!validDomain) return emailControl.setErrors({ invalidDomain: true });
  }

  public sendVoucher() {
    this.next.emit({step: 4});
  }

  public goBack() {
    return this.skipEmployersStep ? this.goBackEmit.emit({ step: 1 }) : this.goBackEmit.emit({ step: 2 });
  }

  private invalidEmail(controlName: string) {
    const control = this.form[controlName];
    return control.hasError('email') || (control.hasError('pattern') && controlName === 'email');
  }

  public isLoadingEmit($event) {
    this.isLoading.emit($event);
  }

  public get isApv(): boolean {
    return this.selectedOption === 'APV';
  }
}
