import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { Router } from '@angular/router';
import {
  CHILE_COUNTRY_CODE,
  DEPENDANT_EMPLOYEE_CODE,
  EMPLOYEE_INCOME_SOURCE_CODE,
  EXECUTIVE_URL,
  GENERAL_REGIME_CODE,
  MONEY_INPUT_TYPES_CODES,
  onServiceError,
  PESOS_INPUT_CODE,
  regexNumbersAndCommas,
  regexOnlyNumbers,
  VOLUNTARY_ACCOUNT_CODE,
  VOLUNTARY_PENSION_ACCOUNT_CODE,
} from '@constants';
import { ClientDataResponse } from '@interfaces/clientData.interface';
import { OpeningDiscountDataRequest } from '@interfaces/openingDiscountDataRequest.interface';
import { LoadingProvider } from '@providers/loading/loading';
import { ModalProvider } from '@providers/modal/modal';
import { AccountsService } from '@services/accounts/accounts.service';
import { ClientService } from '@services/client/client.service';
import { InactivityService } from '@services/inactivityTime/inactivityTime.service';
import { Util } from '@util';
import { addMonths, format } from 'date-fns';
import { EXECUTIVE_RUT, PENDING_AUTH, CLIENT_DATA } from 'util/storage.constants';

@Component({
  selector: 'app-product-opening',
  templateUrl: './product-opening.component.html',
  styleUrls: ['./product-opening.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class ProductOpeningComponent implements OnInit {
  @ViewChild('stepper') public stepper: MatStepper;
  public clientData: ClientDataResponse;
  public selectedOption: string;
  public sendOption = 'send';
  public skipEmployersStep: boolean;
  public cancelOption = 'cancel';
  public apvOpeningForm: FormGroup;
  public todayDate = new Date();
  public selectedEmployerForm;
  public isDeposit = false;
  public employerEmail: string;
  private dateFormat = 'YYYY-MM-DD';
  public discountId = 1;
  public depositId = 2;
  public isLoading = false;
  public userType = 'clientSignature';
  public infoExecutive: string;
  public maxExecutiveNameLength = 20;
  public origin: string;
  public firebaseClientKey: string;
  public currentValidationRef: AngularFireObject<any>;

  constructor(
    private accountsService: AccountsService,
    private clientService: ClientService,
    private firebaseDatabase: AngularFireDatabase,
    private formBuilder: FormBuilder,
    private loadingProvider: LoadingProvider,
    private location: Location,
    private modalProvider: ModalProvider,
    private router: Router,
    public util: Util,
    private inactivityService: InactivityService

  ) {
    this.apvOpeningForm = this.formBuilder.group({
      inputType: [null],
      inputAmount: [null],
      contractValidity: [null],
      firstDiscountDate: [{ value: format(addMonths(this.todayDate, 1), this.dateFormat), disabled: true }],
      lastDiscountDate: [null],
      fund: [null, [Validators.required]],
      regime: [null],
      condition: [false, [Validators.requiredTrue]],
      incomeSource: [null],
      incomeSourceDescription: [''],
      country: [null],
      nif: [null],
      liquidIncome: [null],
      profession: [null],
      jobPosition: [null],
    });
  }

  public ngOnInit() {
    this.inactivityService.startInactivityPostVentaService();
    this.setExecutiveInfo();
    this.detectOrigin();
    this.clientData = JSON.parse(localStorage.getItem(CLIENT_DATA));
  }

  // TODO: Remove this
  public get disableAPV() {
    return this.origin === 'code' && !this.isChristianRut;
  }

  // TODO: Remove this
  public get isChristianRut() {
    const executiveRut = localStorage.getItem(EXECUTIVE_RUT);
    return executiveRut === '160747919';
  }

  private detectOrigin() {
    const origin = localStorage.getItem('productOpeningOrigin');
    localStorage.removeItem('productOpeningOrigin');
    this.origin = origin ? origin : 'normal';
    if (this.origin !== 'normal') this.getFirebaseCode();
  }

  private getFirebaseCode() {
    this.firebaseClientKey = localStorage.getItem(PENDING_AUTH);
    localStorage.removeItem(PENDING_AUTH);
  }

  private setExecutiveInfo() {
    const infoExecutive = localStorage.getItem('infoExecutive');
    // BYPASS
    // this.infoExecutive = 'Ejecutivo';
    this.infoExecutive = this.util.removeAccents(infoExecutive);
  }

  public goBackSkipEmployerStep(data) {
    return (this.stepper.selectedIndex = data.step);
  }

  public next(data) {
    this.skipEmployersStep = false;

    switch (data.step) {
      case 1:
        return this.handleFirstStep(data);
      case 2:
        return this.handleSecondStep(data);
      case 3:
        return this.handleThirdStep(data);
      case 4:
        return this.handleFourthStep();
      case 5:
        return this.handleFifthStep();
    }
  }

  public openCav() {
    const { rut } = this.selectedEmployerForm.controls;
    const {
      fund,
      incomeSource,
      incomeSourceDescription,
      country,
      liquidIncome,
      nif,
      profession,
      jobPosition,
      inputType,
      inputAmount,
      lastDiscountDate,
    } = this.apvOpeningForm.controls;
    const { cellphoneNumber, email } = this.clientData;

    const request = {
      employeeId: DEPENDANT_EMPLOYEE_CODE,
      employerRut: rut.value,
      fund: fund.value,
      regimeId: GENERAL_REGIME_CODE,
      cellphone: this.util.completeCellphone(cellphoneNumber),
      email,
      income: this.cleanInvalidCharacter(PESOS_INPUT_CODE, liquidIncome.value),
      profession: this.isDeposit ? profession.value.id : '',
      jobTitle: this.isDeposit ? jobPosition.value.id : '',
      incomeSourceDetail: incomeSourceDescription.value,
      incomeSourceId: this.isDeposit ? incomeSource.value : EMPLOYEE_INCOME_SOURCE_CODE,
      incomeSourceCountryId: CHILE_COUNTRY_CODE,
      uniqueResidenceJurisdiction: true,
      inputAmount: this.isDeposit ? '' : this.cleanInvalidCharacter(inputType.value, inputAmount.value),
      inputType: this.isDeposit ? '' : MONEY_INPUT_TYPES_CODES[inputType.value],
      lastDiscountDate: this.isDeposit ? '' : format(lastDiscountDate.value, this.dateFormat),
      taxResidenceCountries: this.isDeposit && country.value !== CHILE_COUNTRY_CODE ? [{ id: country.value, nif: nif.value }] : null,
      isDeposit: this.isDeposit,
      isBiotablet: true,
      executiveName: this.infoExecutive,
      workerType: 'dependentDD',
      documentSchema: null,
      birthCountryId: country.value
    };

    this.accountsService
      .openCav(request, this.clientData.rut)
      .toPromise()
      .then(() => {
        this.isLoading = false;
        this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
        this.updateFirebaseStatus();
      })
      .catch((error) => this.handleServiceError(error));
  }

  public goBack() {
    this.location.back();
  }

  public isSelected(option: string): boolean {
    return this.selectedOption === option;
  }

  private confirm(typeAccount: 'CCV' | 'CAV') {
    this.loadingProvider.showLoading();
    this.clientService.logFirstStepApv(this.clientData.rut, this.infoExecutive).subscribe();
    const { rut } = this.selectedEmployerForm.controls;
    const { fund, regime, contractValidity, inputType, lastDiscountDate, inputAmount } = this.apvOpeningForm.controls;
    const data: OpeningDiscountDataRequest = {
      employerRut: this.util.rutClean(rut.value),
      accountType: typeAccount,
      fund: fund.value,
      regime: regime && regime.value ? regime.value : GENERAL_REGIME_CODE,
      contractValidity: contractValidity.value,
      employerDiscount: true,
      inputType: inputType.value,
      endDate: format(lastDiscountDate.value, this.dateFormat),
      inputAmount: this.cleanInvalidCharacter(inputType.value, inputAmount.value),
      activityDeclaration: true,
      executiveName: this.infoExecutive,
    };

    const typeAccountLowerCase = typeAccount === VOLUNTARY_ACCOUNT_CODE ? 'cav' : 'apv';

    this.accountsService
      .openDiscount(typeAccountLowerCase, data)
      .toPromise()
      .then(() => {
        this.isLoading = false;
        this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
        this.updateFirebaseStatus();
      })
      .catch((error) => this.handleServiceError(error))
      .finally(() => this.loadingProvider.hideLoading());
  }

  private confirmDeposit() {
    if (this.apvOpeningForm.invalid) return;
    this.loadingProvider.showLoading();
    this.setExecutiveInfo();
    const { fund, regime, condition, incomeSource, incomeSourceDescription, country } = this.apvOpeningForm.controls;
    const data: OpeningDiscountDataRequest = {
      regime: regime.value,
      fund: fund.value,
      activityDeclaration: condition.value,
      employerDiscount: false,
      incomeSource: incomeSource.value,
      incomeSourceDescription: incomeSourceDescription.value,
      country: country.value,
      accountType: 'CCV',
      executiveName: this.infoExecutive,
    };
    this.accountsService
      .apvOpenDiscount(data)
      .toPromise()
      .then(() => {
        this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
        this.updateFirebaseStatus();
      })
      .catch((error) => this.handleServiceError(error))
      .finally(() => this.loadingProvider.hideLoading());
  }

  private handleServiceError(error) {
    this.skipEmployersStep = true;
    error = error.error || error;
    if (!error.code) {
      error = onServiceError;
    }
    this.modalProvider
      .openCustomErrorModal(error)
      .afterClosed()
      .subscribe(() => {
        this.isLoading = false;
        this.stepper.selectedIndex = 0;
      });
  }

  public back() {
    this.stepper.selectedIndex -= 1;
  }

  private cleanInvalidCharacter(inputType: string, inputValue: string): number {
    if (inputType !== 'U') {
      inputValue = inputValue.replace(regexOnlyNumbers, '');
    } else {
      const inputArray = inputValue.replace(regexNumbersAndCommas, '').split(',');
      inputValue = inputArray.shift() + '.' + inputArray.join('');
    }
    return Number(inputValue);
  }

  private handleFirstStep(data) {
    this.selectedOption = data.selectedOption;
    return (this.stepper.selectedIndex = this.stepper.selectedIndex + 1);
  }

  private handleSecondStep(data) {
    const isDepositSelected = data.selectedSegment === this.depositId;
    const skipCondition = isDepositSelected && this.isSelected(VOLUNTARY_PENSION_ACCOUNT_CODE);
    this.isDeposit = isDepositSelected;
    this.skipEmployersStep = skipCondition;
    return (this.stepper.selectedIndex = skipCondition ? this.stepper.selectedIndex + 2 : this.stepper.selectedIndex + 1);
  }

  private handleThirdStep(data) {
    this.selectedEmployerForm = data.employer;
    const { email } = this.selectedEmployerForm.controls;
    this.employerEmail = email.value;
    return (this.stepper.selectedIndex = this.stepper.selectedIndex + 1);
  }

  private handleFourthStep() {
    if (this.selectedOption === VOLUNTARY_ACCOUNT_CODE) {
      return this.isDeposit ? this.openCav() : this.confirm('CAV');
    }
    return this.isDeposit ? this.confirmDeposit() : this.confirm('CCV');
  }

  private handleFifthStep() {
    return this.router.navigateByUrl(EXECUTIVE_URL);
  }

  public isLoadingEmit($event) {
    this.isLoading = $event;
  }

  private updateFirebaseStatus() {
    if (this.origin !== 'code') return;
    this.currentValidationRef = this.firebaseDatabase.object(`client/${this.firebaseClientKey}`);
    this.currentValidationRef.update({ completedProductOpening: true });
  }
}
