import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatCurrency' })
export class FormatCurrencyPipe implements PipeTransform {

    constructor(private currencyPipe: CurrencyPipe) { }

    public transform(value) {
        return this.currencyPipe.transform(value, 'CLP', '$', '1.0');
    }
}
