// tslint:disable: ban-types
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ModalData } from '@interfaces/modalData.interface';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  public message: string;
  public messageDescription: string;
  public firstBtnText: string;
  public secondBtnText: string;
  private primaryCallback: Function;
  private secondaryCallback: Function;
  public showSecondaryButton = false;
  public iconName: string;
  public images: boolean;

  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
  ) {
    Object.keys(data).forEach(key => this[key] = data[key]);
    if (data.secondaryCallback) { this.showSecondaryButton = true; }
    if (data.images) { this.images = data.images; }
  }

  public primaryAction(): void {
    this.dialogClose();
    this.primaryCallback();
  }

  public secondaryAction(): void {
    this.dialogClose();
    this.secondaryCallback();
  }

  public dialogClose() {
    this.dialogRef.close();
  }
}
