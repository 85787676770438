import { Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router } from '@angular/router';
import { MAX_LENGTH_RUT, postVentaServiceError, PRODUCT_OPENING_URL, VALIDATION_OPERATIONS } from '@constants';
import { BiometricLoginData } from '@interfaces/biometricLoginData.interface';
import { BiometricResponse } from '@interfaces/biometricResponse.interface';
import { ClientPhoneValidation } from '@interfaces/client.interface';
import { ClientDataResponse } from '@interfaces/clientData.interface';
import { EarnedProfitability } from '@interfaces/earnedProfitability.interface';
import { ProductResponse } from '@interfaces/getProductsResponse.interface';
import { ModalProvider } from '@providers/modal/modal';
import { AccountsService } from '@services/accounts/accounts.service';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { InactivityService } from '@services/inactivityTime/inactivityTime.service';
import { PostVentaService } from '@services/post-venta/post-venta.service';
import { TraceService } from '@services/trace/trace.service';
import { Util } from '@util';
import * as firebase from 'firebase';
import { forkJoin } from 'rxjs';
import { CLIENT_DATA, EXECUTIVE_RUT, FUNCTIONALITY } from 'util/storage.constants';

export type loginStepType = 'login' | 'loginIdentityCard' | 'waitingIdentityCard' | 'waiting' | 'errorValidation' | 'errorService' | 'ok';

@Component({
  selector: 'app-affiliate',
  templateUrl: './affiliate.component.html',
  styleUrls: ['./affiliate.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AffiliateComponent implements OnInit {
  public clientData: ClientDataResponse;
  public selectedOpt = 'contact';
  public pageId: string;
  public token: string;
  public regexWhiteSpace = / /g;
  public url: string;
  public clientValidationStatus: loginStepType;
  public biometricOperations = VALIDATION_OPERATIONS;
  public clientPhoneValidationObject: ClientPhoneValidation = {
    status: 'login',
  } as ClientPhoneValidation;
  public initialLogin = 'login';
  public loading: boolean;
  public validationInfo: BiometricLoginData;
  public maxLengthForRut = MAX_LENGTH_RUT;
  public minLength = 9;
  public maxLength = 9;
  public biometricError = { code: null, description: '' };
  public options = [
    { value: 'contact', name: 'Datos de contacto', available: true },
    { value: 'password', name: 'Envío de Claves', available: true },
    { value: 'account', name: 'Cuentas y Saldos', available: true },
    { value: 'earnedProfitability', name: 'Rentabilidad Ganada', available: true },
    // UNCOMMENT IF IT IS NEEDED FOR ANY WITHDRAWAL 10
    // { value: 'request10', name: 'R10 - Bono', available: false },
    { value: 'certificate', name: 'Certificados', available: true },
    { value: 'simulator', name: 'Simuladores', available: true },
  ];

  public passwordMethod: string;
  public operations = [
    {
      name: VALIDATION_OPERATIONS.BIOMETRIC_VALIDATION,
      displayName: 'Validar con huella',
      userTypeOption: 'executive',
    },
    {
      name: VALIDATION_OPERATIONS.BARCODE,
      displayName: 'Validar con cédula',
      userTypeOption: 'executive',
    },
  ];
  public operation = VALIDATION_OPERATIONS.BIOMETRIC_VALIDATION;
  public userLoginType: 'executive' | 'client' = 'client';
  public validationInfoExecutive;
  public uid: string;
  public isCodeValidation = false;
  public earnedProfitability: EarnedProfitability;
  public CCOAccount: ProductResponse;
  private isPostVenta = true;
  private undefinedBarcode = undefined;
  private biometricWindow: Window;
  private validStatus = 'activo';
  private validStatusCode = 200;
  private executiveRut: string;
  private biometricLogin = { operation: '', barcode: '' };
  private insidePages = ['sendPassword', 'quoteCertificate', 'sacuPassword', 'investorProfile'];
  private isFromInternal = false;
  private biotabletValidationObject;
  private minProfit = 50000;
  private minBalance = 10000000;
  private mandatoryAccount = 'CCO';

  constructor(
    private accountsService: AccountsService,
    private authenticationService: AuthenticationService,
    private postVentaService: PostVentaService,
    private firebaseDatabase: AngularFireDatabase,
    private router: Router,
    private modalProvider: ModalProvider,
    private ngZone: NgZone,
    private traceService: TraceService,
    public util: Util,
    private inactivityService: InactivityService
  ) {
  }

  get validateCard() {
    return this.clientValidationStatus === 'loginIdentityCard' ? 'Ingresar cédula' : 'Validar Cliente';
  }

  public validate(type: string) {
    if (this.isCodeValidation) return;
    this.verifyBiometricIdentity(this.biotabletValidationObject.rut, type);
  }

  public getOperations(userType: string) {
    if (userType === 'client') {
      return this.operations;
    }
    return this.operations.filter(({ userTypeOption }) => userType === userTypeOption);
  }

  public async ngOnInit() {
    this.inactivityService.startInactivityPostVentaService();
    const functionality = localStorage.getItem(FUNCTIONALITY);
    if (functionality === 'productOpening') {
      this.operations.push({
        name: VALIDATION_OPERATIONS.EMAIL_SECURITY_QUESTIONS,
        displayName: 'Validar vía web',
        userTypeOption: 'executive',
      });
    }
    const fromPage = localStorage.getItem('originAffiliate');
    localStorage.removeItem('originAffiliate');
    this.isFromInternal = this.insidePages.some((insidePage) => insidePage === fromPage);
    // BYPASS
    // this.clientValidationStatus = this.isFromInternal ? 'ok' : 'ok'; // this one is pretty critical. DO NOT forget it.
    this.clientValidationStatus = this.isFromInternal ? 'ok' : 'login';
    this.checkCurrentOption();
    this.clientData = JSON.parse(localStorage.getItem(CLIENT_DATA));
    this.executiveRut = localStorage.getItem(EXECUTIVE_RUT);
    if (!this.isFromInternal) {
      await this.authenticationService.logout();
    }
    this.getToken();
    await this.validateAvailableFunctionalities();
    this.loading = false;
    // BYPASS
    // this.validation();
  }

  public get showClientValidationForm() {
    return this.clientValidationStatus === 'login' || this.clientValidationStatus === 'loginIdentityCard';
  }

  public statusEmit() {
    this.isCodeValidation = false;
    this.clientValidationStatus = 'login';
  }

  public checkCurrentOption(): void {
    const sendPassword = JSON.parse(localStorage.getItem('sendPassword'));
    const quoteCertificate = localStorage.getItem('quoteCertificate');
    const sacuPassword = localStorage.getItem('sacuPassword');
    const investorProfile = localStorage.getItem('investorProfile');

    if (sendPassword && sendPassword.option) {
      this.selectedOpt = sendPassword.option;
      this.passwordMethod = sendPassword.method;
      localStorage.removeItem('sendPassword');
    } else if (quoteCertificate) {
      this.selectOption('certificate');
      localStorage.removeItem('quoteCertificate');
      return;
    } else if (sacuPassword) {
      this.selectOption('password');
      localStorage.removeItem('sacuPassword');
      return;
    } else if (investorProfile) {
      this.selectOption('simulator');
      localStorage.removeItem('investorProfile');
      return;
    } else {
      this.selectOption('contact');
    }
  }

  public selectOption(opt: string) {
    this.selectedOpt = opt;
    this.passwordMethod = null;
  }

  public updateClient(event: ClientDataResponse) {
    this.clientData = event;
    this.validateAndSetToken(this.clientData.rut);
  }

  public goToContactData(event: any) {
    this.selectOption('contact');
  }

  public biotabletValidationEmit($event) {
    this.biotabletValidationObject = $event;
    this.changeOptionSelected(this.biotabletValidationObject.operation);
    this.validate('client');
  }

  public clientPhoneValidationObjectEmit($event) {
    this.clientPhoneValidationObject = $event;
  }

  public isCodeValidationEmit($event) {
    this.isCodeValidation = $event;
    this.clientValidationStatus = 'waiting';
  }

  private changeOptionSelected(option) {
    this.operation = option;
    const userStatus = `${this.userLoginType}ValidationStatus`;
    if (option === VALIDATION_OPERATIONS.BARCODE && this[userStatus] === 'login') this.setLoginWithIdentityCard();
    if (option === VALIDATION_OPERATIONS.BIOMETRIC_VALIDATION && this[userStatus] === 'loginIdentityCard') this.setLogin();
  }

  private setLoginWithIdentityCard() {
    const userStatus = `${this.userLoginType}ValidationStatus`;
    this[userStatus] = 'loginIdentityCard';
    this.initialLogin = 'loginIdentityCard';
  }

  private setLogin() {
    const userStatus = `${this.userLoginType}ValidationStatus`;
    this[userStatus] = 'login';
    this.initialLogin = 'login';
  }

  private async validation() {
    this.loading = true;
    this.traceService.notifyValidatedClient(this.clientData.rut, this.executiveRut).subscribe();
    await this.validateAndSetToken(this.clientData.rut);
    this.loading = false;
    const path = localStorage.getItem('path');
    localStorage.removeItem('path');
    if (path === 'productOpening') {
      return this.router.navigateByUrl(PRODUCT_OPENING_URL);
    }
    this.clientValidationStatus = 'ok';
  }

  private async validateAndSetToken(rut: string) {
    if (this.isFromInternal) return;
    const option = this.options.find(({ value }) => value === 'certificate');
    await this.authenticationService
      .getToken(rut)
      .toPromise()
      .then(async ({ token }) => {
        option.available = true;
        await this.authenticationService.setAffiliateIdToken(token);
      })
      .catch((error) => {
        option.available = false;
        this.handleServiceError(error);
      });
  }

  private verifyBiometricIdentity(rut: string, type: string) {
    const statusName = `${type}ValidationStatus`;
    this.generateUrlAndPageId(this.util.rutClean(rut));
    this[statusName] = this.clientValidationStatus === 'loginIdentityCard' ? 'waitingIdentityCard' : 'waiting';
    this.callBiometricApk();
    this.validateUser(type);
  }

  private generateUrlAndPageId(rut: string) {
    const { url, pageId } = this.getUrlAndPageId(rut);
    this.url = url;
    this.pageId = pageId;
  }

  private getUrlAndPageId(rut: string) {
    const userStatus = `${this.userLoginType}ValidationStatus`;
    const isVerifyIdentity = this.operation === VALIDATION_OPERATIONS.BIOMETRIC_VALIDATION;
    const failValidation = this[userStatus] === 'errorValidation';

    if (isVerifyIdentity) {
      return this.authenticationService.biometricUrl(rut, this.token, this.uid, this.undefinedBarcode, this.isPostVenta);
    }
    if (this.initialLogin === 'loginIdentityCard' || failValidation) {
      return this.authenticationService.biometricUrlForIdentityCard(this.token, this.uid, this.isPostVenta);
    }
    if (this.initialLogin === 'login') {
      return this.authenticationService.biometricUrl(rut, this.token, this.uid, this.biometricLogin.barcode, this.isPostVenta);
    }
  }

  private async validateUser(type: string) {
    this.loading = true;
    this.cancelOnChangeDataEvent();
    return this.firebaseDatabase.database.ref(`user/${this.uid}/${this.pageId}`).on('value', (data) => this.handleFirebaseData(data, type));
  }

  private async getToken() {
    if (this.isFromInternal) return;
    const sessionData = await this.authenticationService.signInAnonymously();

    if (!sessionData || !sessionData.token) return this.invalidToken();
    this.token = sessionData.token;
    this.uid = sessionData.uid;
  }

  private invalidToken() {
    this.modalProvider
      .openErrorTokenInvalid()
      .afterClosed()
      .subscribe(() => this.getToken());
  }

  private cancelOnChangeDataEvent() {
    this.firebaseDatabase.database.ref(`user/${this.uid}/${this.pageId}`).off('value');
  }

  private isBiometricResponseInvalid(data: any) {
    return data.verification_codigo !== this.validStatusCode;
  }

  private handleInvalidBiometricResponse(data) {
    const { verification_codigo: code, verification_mensaje: description } = data;
    this.biometricError = { code, description };
    this.handleUserValidationError();
    this.cancelOnChangeDataEvent();
  }

  private handleUserValidationError() {
    this.clientValidationStatus = 'errorValidation';
    this.clientPhoneValidationObject.status = 'errorValidation';
    this.loading = false;
  }

  private handleFirebaseData(data: firebase.database.DataSnapshot, type: string) {
    const firebaseData = data.val();
    if (!firebaseData || firebaseData.status !== this.validStatus) return;
    this.ngZone.run(async () => {
      this.loading = false;

      if (this.isBiometricResponseInvalid(firebaseData)) return this.handleInvalidBiometricResponse(firebaseData);

      const isBarcodeOperation = this.operation === VALIDATION_OPERATIONS.BARCODE;
      const userStatus = `${this.userLoginType}ValidationStatus`;
      if (isBarcodeOperation && this[userStatus] === 'waitingIdentityCard') return this.setBarCode(userStatus, firebaseData);

      this.setValidationInformation(type, firebaseData);
      this.validation();
      this.cancelOnChangeDataEvent();
    });
  }

  private setBarCode(userStatus: string, firebaseData) {
    this.biometricLogin.barcode = firebaseData.verification_codigo_barra;
    this[userStatus] = 'login';
    this.initialLogin = 'login';
    this.cancelOnChangeDataEvent();
  }

  private buildUserRequest(biometricData: BiometricResponse) {
    let { verification_apellidos: lastName, verification_nombres: name } = biometricData;
    const { verification_rut: rut, verification_transaccion: verificationCode, verification_url: verificationUrl } = biometricData;

    lastName = lastName.replace(this.regexWhiteSpace, '-');
    name = name ? name.trim().replace(this.regexWhiteSpace, '-') : lastName;

    return {
      lastName,
      motherLastName: lastName,
      name,
      rut,
      verificationCode,
      verificationUrl,
    };
  }

  private setValidationInformation(type: string, firebaseData) {
    this.validationInfo = { ...this.validationInfo };
    this.validationInfo[type] = this.buildUserRequest(firebaseData);
  }

  private callBiometricApk() {
    this.biometricWindow = window.open(this.url);
    if (this.biometricWindow) {
      setTimeout(() => this.biometricWindow.close(), 1000);
    }
  }

  private handleServiceError(error) {
    error = error.error || error;
    if (!error.code) {
      error = postVentaServiceError;
    }
    this.modalProvider.openGenericRetryErrorModal(error);
  }

  private async validateAvailableFunctionalities() {
    const rut = JSON.parse(localStorage.getItem(CLIENT_DATA)).rut;
    const earnedProfitabilityService = this.postVentaService.earnedProfitability();
    const productsBalanceService = this.accountsService.getProductsBalance(rut);
    forkJoin([earnedProfitabilityService, productsBalanceService])
      .subscribe(([earnedProfitability, productsBalance]) => {
        const CCOAccount = productsBalance.find(product => product.type === this.mandatoryAccount);
        if (!CCOAccount || earnedProfitability.profit < this.minProfit || CCOAccount.balanceTotal < this.minBalance)
          return this.shutdownOption('earnedProfitability');
        this.earnedProfitability = earnedProfitability;
        this.CCOAccount = CCOAccount;
      },
        () => this.shutdownOption('earnedProfitability'));
  }

  private shutdownOption(optionOff: string) {
    for (const option of this.options) {
      if (option.value === optionOff) {
        option.available = false;
      }
    }
  }
}
