import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClientDataResponse } from '@interfaces/clientData.interface';
import { Util } from '@util';
import { CLIENT_DATA } from 'util/storage.constants';

@Component({
  selector: 'app-successful-request',
  templateUrl: './successful-request.component.html',
  styleUrls: ['./successful-request.component.scss']
})
export class SuccessfulRequestComponent implements OnInit {
  @Input() public employerEmail;
  @Input() public isDeposit: boolean;
  @Input() public selectedOption: string;
  @Output() public next = new EventEmitter();
  public clientData: ClientDataResponse;

  constructor(
    private location: Location,
    public util: Util,
  ) {
  }

  public ngOnInit() {
    this.clientData = JSON.parse(localStorage.getItem(CLIENT_DATA));
  }

  public goBack() {
    this.location.back();
  }

  public continue() {
    this.next.emit({step: 5});
  }

}
