import { Mockup } from '@interfaces/mockup.interface';

export const MARK_AFFILIATE_AS_CONTACTED_MOCKUP: Mockup = {
  success: [{
    response:
      [{}]
  }],
  failures: [{
    response: {
      'code': 50001,
    }
  }],
};
