import { Mockup } from '@interfaces/mockup.interface';

export const EXECUTIVE_AUTHENTICATION_MOCKUP: Mockup = {
  success: [{
    response: {
      name: 'John Doe Boris',
      code: '1234K',
      cellphone: '912345678',
      email: 'john.doe@planvital.cl',
      agencyCode: '201',
    },
  }],
  failures: [{
    response: {
      code: 40101,
      message: 'Ejecutivo no encontrado.',
      messageDescription: 'El ejecutivo no fue encontrado en el sistema.',
    },
  }, {
    response: {
      statusCode: 500,
      message: 'Error',
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.'
    }
  }],
};
