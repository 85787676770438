import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ClientBusinessTube, ClientsBusinessTubeResponse } from '@interfaces/clientsBusinessTube.interface';
import { Executive } from '@interfaces/executive.interface';
import { StatusBusinessTube } from '@interfaces/statusBusinessTube.interface';
import { Util } from '@util';
import * as _ from 'lodash';
import { BusinessTubeComponent } from '../business-tube.component';
import { FilterInterface } from '@interfaces/filter.interface';
import { BUSINESS_TUBE_DEFAULT_FILTERS } from '@constants';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})
export class BusinessTubeClientListComponent implements OnChanges {
  @Input() public selectedFilterIn: FilterInterface;
  @Input() public selectedStatus: StatusBusinessTube;
  @Input() public infoExecutive: Executive;
  @Input() public clients: ClientsBusinessTubeResponse;
  @Input() public isArchivedView: boolean;
  @Output() public actionRoute = new EventEmitter();
  @Output() public clientSelected = new EventEmitter();
  @Output() public reloadClients = new EventEmitter();
  @Output() public changeSelectedStatus = new EventEmitter();
  @Output() public selectedFilterEmitter = new EventEmitter();

  public filters = BUSINESS_TUBE_DEFAULT_FILTERS;
  public backUpFilters = JSON.parse(JSON.stringify(BUSINESS_TUBE_DEFAULT_FILTERS));
  public showConfirmationComponent = false;
  public typeCard = 'executive';
  public pageSize = 3;
  public paginationFrom = 0;
  public paginationTo = 3;
  public selectedOpt;
  public loading = false;
  public serviceError: string = null;

  constructor(
    public util: Util,
    public businessTubeComponent: BusinessTubeComponent
  ) { }

  public ngOnChanges(): void {
    this.filters = JSON.parse(JSON.stringify(this.backUpFilters));
  }

  get executiveName() {
    if (!this.infoExecutive.name) return 'Solicita actualizar tu información de ejecutivo';

    const splitted = this.infoExecutive.name.split(' ');
    const name = `${this.util.capitalizeFirstLetter(splitted[2])}`;
    const lastName = `${this.util.capitalizeFirstLetter(splitted[0])}`;
    const secondLasName = `${splitted[1] ? this.util.capitalizeFirstLetter(splitted[1]) : ''}`;
    return `${name} ${lastName} ${secondLasName}`;
  }

  public pagination($event: PageEvent) {
    this.paginationFrom = $event.pageIndex * 3;
    this.paginationTo = ($event.pageIndex + 1) * 3;
  }

  public setLoading($event: boolean) {
    this.loading = $event;
  }

  public updatedStatus($event: StatusBusinessTube) {
    this.reloadClients.emit();
    this.changeSelectedStatus.emit($event);
  }

  public goToClientDetail($client: ClientBusinessTube) {
    this.actionRoute.emit('details');
    this.clientSelected.emit($client);
  }

  public setSelectedFilter($event) {
    this.selectedFilterEmitter.emit($event);
  }
}
