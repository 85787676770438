import { InvestorProfile, InvestorProfileQuestion, InvestorProfileResult } from '@interfaces/investor-profile.interface';

export const TEXT_CONTENT = {
  disclaimer: '<p>Si ya eres parte de AFP PlanVital cámbiate de fondos ingresando a tu Sucursal Virtual.</p>',
  transferButtonText: '<p>Cámbiate a <strong>AFP PlanVital</strong></p>',
  loginButtonText: '<p>Ir a <strong>Sucursal Virtual</strong></p>',
};

export const INVESTOR_SIMULATOR_QUESTIONS: Array<InvestorProfileQuestion> = [
  {
    description: '¿A cuál de los siguientes tramos de edad perteneces?',
    options: [
      {
        description: 'Menos de 35 años.',
        value: 1,
      },
      {
        description: 'Entre 36 y 45 años.',
        value: 2,
      },
      {
        description: 'Entre 46 y 60 años.',
        value: 3,
      },
      {
        description: 'Más de 60 años.',
        value: 4,
      },
    ],
  },
  {
    description: '¿En cuántos años más piensas jubilarte?',
    options: [
      {
        description: 'Menos de 2 años.',
        value: 4,
      },
      {
        description: 'Entre 2 y 5 años.',
        value: 3,
      },
      {
        description: 'Entre 6 y 10 años.',
        value: 2,
      },
      {
        description: 'Más de 10 años.',
        value: 1,
      },
    ],
  },
  {
    description: 'Sobre el Mercado de Valores, puedes afirmar que:',
    options: [
      {
        description: 'Nunca he participado en el mercado de valores.',
        value: 4,
      },
      {
        description: 'He participado en el mercado de valores pero necesito asesoría.',
        value: 3,
      },
      {
        description: 'Participo activamente en el mercado de valores, tomo mis decisiones y necesito un menor nivel de asesoría.',
        value: 2,
      },
      {
        description: 'Me dedico a las inversiones en el mercado de valores.',
        value: 1,
      },
    ],
  },
  {
    description: 'Si dispones de $1.000.000 para invertir. ¿Qué alternativas de inversión a un año preferirías?',
    options: [
      {
        description: 'Probabilidad de ganar $1.010.000 sin perder.',
        value: 3,
      },
      {
        description: 'Probabilidad de ganar $1.200.000 o perder $100.000',
        value: 2,
      },
      {
        description: 'Probabilidad de ganar $3.200.000 o perder $750.000',
        value: 1,
      },
    ],
  },
  {
    description: 'Si tu saldo cae 20% en una semana ¿Qué harías?',
    options: [
      {
        description: 'Movería todo el saldo a un fondo menos riesgoso.',
        value: 4,
      },
      {
        description: 'Movería parte del saldo a un fondo menos riesgoso.',
        value: 3,
      },
      {
        description: 'No movería mi saldo, esperando ganar en el largo plazo.',
        value: 2,
      },
      {
        description: 'Aumentaría mi saldo invirtiendo más dinero a ese fondo.',
        value: 1,
      },
    ],
  },
  {
    description: '¿Cuáles de las siguientes frases te representa mejor?',
    options: [
      {
        description: 'Aceptaría cambios bruscos en mi saldo a fin de obtener rentabilidades altas a largo plazo.',
        value: 1,
      },
      {
        description: 'Aceptaría algunos cambios en mi saldo para mejores rentabilidades en el corto plazo.',
        value: 2,
      },
      {
        description: 'Prefiero que mi saldo crezca poco siempre que no tenga pérdidas.',
        value: 3,
      },
    ],
  },
];

export const INVESTOR_SIMULATOR_RESULT: Array<InvestorProfileResult> = [
  {
    fund: 'A',
    description: `<p>Tienes una visión de inversión activa entre riesgo y la tasa de retorno que buscas.
      Prefieres tu inversión en renta variable (acciones). a normativa permite a este fondo invertir
      entre un 40% y un 80% de los ahorros entre renta variable, y entre un 20% y un 60% en renta fija.</p>`,
    score: 8,
    detailsDescription: `<p>Este perfil invierte mayoritariamente en renta variable (acciones) y suele obtener mejores
      rentabilidades en el largo plazo que los fondos más conservadores (D y E). Si bien, el riesgo a fluctuaciones de
      rentabilidades en el corto plazo es más alto, el propósito es obtener mayores rentabilidades a futuro.</p>
      <p><strong>Renta variable: entre 40% y 80% <br> Renta fija: entre 20% y 60%</strong></p>`,
  },
  {
    fund: 'B',
    description: `<p>Tienes una visión de inversión activa entre riesgo y la tasa de retorno que buscas.
      Prefieres tu inversión en renta variable (acciones), y en menor porcentaje en renta fija.
      La normativa permite a este fondo invertir entre un 25% y un 60% de los ahorros entre renta variable,
      y entre un 40% y un 75% en renta fija.</p>`,
    score: 11,
    detailsDescription: `<p>Este perfil invierte más en renta variable (por ejemplo, acciones) y un porcentaje
      menor en renta fija (instrumentos de deuda).</p><p>Por normativa, la inversión de tus ahorros en este fondo
      es la siguiente:</p><p><strong>Renta variable: entre 25% y 60% <br> Renta fija: entre 40% y 75%</strong></p>`,
  },
  {
    fund: 'C',
    description: `<p>Tienes una visión de inversión equilibrada entre riesgo y la tasa de retorno que buscas.
      Prefieres distribuir tu inversión entre renta variable (acciones) y fija. La normativa permite a este
      fondo invertir entre un 15% y un 40% de los ahorros entre renta variable y entre un 60% y un 85% en renta fija.</p>`,
    score: 17,
    detailsDescription: `<p>Este perfil mantiene una visión equilibrada entre riesgo y ganancia. Los ahorros se
      distribuyen en renta variable (acciones) y renta fija. </p><p>Por normativa, la inversión de tus ahorros
      en este fondo es la siguiente:</p><p><strong>Renta variable: entre 15% y 40% <br> Renta fija: entre 60%
      y 85%</strong></p>`,
  },
  {
    fund: 'D',
    description: `<p>Tienes una visión conservadora entre riesgo y la tasa de retorno que buscas. Prefieres tu
      inversión entre renta fija, y en menor porcentaje en renta variable (acciones). En este fondo puedes
      invertir entre un 5% y un 20% de los ahorros entre renta variable, y entre un 80% y un 95% en renta fija.</p>`,
    score: 20,
    detailsDescription: `<p>En este perfil se asumen riesgos moderados para aumentar las ganancias. Mantiene un
      equilibrio entre rentabilidad y la seguridad de la inversión. </p><p>Por normativa, la inversión de tus
      ahorros en este fondo es la siguiente:</p><p><strong>Renta variable: entre 5% y 20% <br> Renta fija:
      entre 80% y 95%</strong></p>`,
  },
  {
    fund: 'E',
    description: `<p>Tienes una visión conservadora entre riesgo y la tasa de retorno que buscas. Prefieres
      tu inversión en renta fija. La normativa permite a este fondo invertir entre un 0% y un 5% de los ahorros
      entre renta variable, y entre un 95% y un 100% en renta fija.</p>`,
    score: 22,
    detailsDescription: `<p>Este perfil mantiene una visión conservadora entre riesgo y ganancia. Al invertir
      en renta fija, evita fluctuaciones en las rentabilidades de corto plazo.</p><p>Por normativa, la
      inversión de tus ahorros en este fondo es la siguiente:</p><p><strong>Renta variable: entre 0% y 5%
      <br> Renta fija: entre 85% y 100%</strong></p>`,
  },
];

// tslint:disable-next-line: max-line-length
export const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_PATTERN = /^[1-9][0-9]*$/;
export const NUMBER_PATTERN = /^\d+$/;
export const NUMERIC_PATTERN = /^[0-9]*$/;
export const ALPHANUMERIC_PATTERN = /^[a-zA-Z0-9]*$/;
export const ALPHABETIC_PATTERN = /^[A-Za-záéíóúüÁÉÍÓÚÜñÑ ]*$/;
export const ADDRESS_PATTERN = /^[A-Za-záéíóúüÁÉÍÓÚÜñÑ0-9.,\-\/ ]*$/;

const pageKey = '';

export const INVESTOR_SIMULATOR_CONTENT: InvestorProfile = {
  header: {
    firstLine: `${pageKey}.HEADER.FIRST_LINE`,
    image: {
      alt: null,
      url: 'assets/images/simulador_inversor1.jpg',
    },
  },
  headerSimulation: null,
  benefitsSection: {
    title: `${pageKey}.BENEFITS.TITLE`,
    subtitle: `${pageKey}.BENEFITS.SUBTITLE`,
    benefits: [
      `${pageKey}.BENEFITS.PERSONALIZED_BENEFITS.1`,
      `${pageKey}.BENEFITS.PERSONALIZED_BENEFITS.2`,
      `${pageKey}.BENEFITS.PERSONALIZED_BENEFITS.3`,
      `${pageKey}.BENEFITS.PERSONALIZED_BENEFITS.4`,
      `${pageKey}.BENEFITS.PERSONALIZED_BENEFITS.5`,
    ],
  },
  investorProfileBox: {
    title: `${pageKey}.BOX.TITLE`,
    description: `${pageKey}.BOX.DESCRIPTION`,
    fullName: `${pageKey}.BOX.FULLNAME`,
    rut: `${pageKey}.BOX.RUT`,
    email: `${pageKey}.BOX.EMAIL`,
    phone: `${pageKey}.BOX.PHONE`,
  },
};

export const CONTROL_NAMES: { [key: number]: string } = {
  1: 'first',
  2: 'second',
  3: 'third',
  4: 'fourth',
  5: 'fifth',
  6: 'sixth',
  7: 'seventh',
};
