import { Mockup } from '@interfaces/mockup.interface';

export const CONTACT_DATA_MOCKUP: Mockup = {
  success: [{
    response: {
      status: 'ok'
    },
  }],
  failures: [{
    response: {
      messageError: {
        statusCode: 400,
        error: 'Bad Request',
        message: []
      },
      path: '/contacts/',
    },
  }, {
    response: {
      errorMessage: 'Service returned an error.',
      stack: '',
      path: '/contact/',
    }
  }],
};
