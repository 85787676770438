import { Mockup } from '@interfaces/mockup.interface';

export const TRANSFER_MOCK: Mockup = {
  success: [{
    response: {
      status: 200,
      voucherName: '1653941036603',
      transferDate: '30-05-2022'
    }
  }],
  failures: [{
    response: {
      code: 40106,
      message: 'Error transfiriendo',
      messageDescription: 'Error transfiriendo cuentas',
    }
  }]
};
