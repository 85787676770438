import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { BUSINESS_TUBE_DEFAULT_FILTERS, BUSINESS_TUBE_STATUSES } from '@constants';
import { ClientBusinessTube } from '@interfaces/clientsBusinessTube.interface';
import { Executive } from '@interfaces/executive.interface';
import { FilterInterface } from '@interfaces/filter.interface';
import { StatusBusinessTube } from '@interfaces/statusBusinessTube.interface';
import { Util } from '@util';

@Component({
  selector: 'app-business-tube-summary',
  templateUrl: './business-tube-summary.component.html',
  styleUrls: ['./business-tube-summary.component.scss']
})
export class BusinessTubeSummaryComponent implements OnChanges {
  @Input() public selectedFilterIn: FilterInterface;
  @Input() public isArchivedView: boolean;
  @Input() public infoExecutive: Executive;
  @Input() public clients: ClientBusinessTube;
  @Output() public actionRoute = new EventEmitter();
  @Output() public selectedStatus = new EventEmitter();
  @Output() public selectedFilterEmitter = new EventEmitter();
  @Output() public selectedArchivedEmitter = new EventEmitter();
  public typeCard = 'executive';
  public statuses = BUSINESS_TUBE_STATUSES;
  public filters = BUSINESS_TUBE_DEFAULT_FILTERS;
  public backUpFilters = JSON.parse(JSON.stringify(BUSINESS_TUBE_DEFAULT_FILTERS));

  constructor(
    public util: Util,
  ) { }

  public ngOnChanges(): void {
    this.filters = JSON.parse(JSON.stringify(this.backUpFilters));
  }

  get executiveName() {
    if (!this.infoExecutive.name) return 'Solicita actualizar tu información de ejecutivo';

    const splitted = this.infoExecutive.name.split(' ');
    const name = `${this.util.capitalizeFirstLetter(splitted[2])}`;
    const lastName = `${this.util.capitalizeFirstLetter(splitted[0])}`;
    const secondLasName = `${splitted[1] ? this.util.capitalizeFirstLetter(splitted[1]) : ''}`;
    return `${name} ${lastName} ${secondLasName}`;
  }

  public selectStatus(status: StatusBusinessTube) {
    const action = this.isArchivedView ? 'list-archived' : 'list';
    this.actionRoute.emit(action);
    this.selectedStatus.emit(status);
  }

  public createProspect() {
    this.actionRoute.emit('create');
  }

  public setSelectedFilter($event: FilterInterface) {
    this.selectedFilterEmitter.emit($event);
  }

  public selectArchived() {
    this.selectedArchivedEmitter.emit();
  }
}
