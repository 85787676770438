import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { PortfolioAffiliate } from '@interfaces/executivePortfolioResponse.interface';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
  public affiliate;
  public contactRadioForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      affiliate: PortfolioAffiliate,
    },
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
  ) {
    this.contactRadioForm = this.formBuilder.group({
      contact: [],
    });
    this.affiliate = data;
  }

  public get getNoContacted() {
    const { contact } = this.contactRadioForm.controls;
    return contact.value !== 'true';
  }

  public ngOnInit() {
  }

  public closeDialog() {
    this.dialogRef.close(false);
  }

  public confirmAction() {
    const { contact } = this.contactRadioForm.controls;
    this.dialogRef.close(contact.value === 'true');
  }

}
