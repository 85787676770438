import { Mockup } from '@interfaces/mockup.interface';

export const PRODUCT_BALANCE_LIST: Mockup = {
  success: [{
    response: [
      {
        description: 'Obligatoria',
        balanceTotal: 18884710.0,
        type: 'CCO',
        orderCode: 1,
        fundCollectionType: 'D',
        funds: [
          {
            name: 'D',
            distribution: '60',
            balance: 18418510.0,
            dueDateInstallment: '2020-07-22T00:00:00',
            installmentBalance: 500.0,
            installmentValue: 36837.02,
          },
          {
            name: 'E',
            distribution: '40',
            balance: 466200,
            dueDateInstallment: '2020-07-22T00:00:00',
            installmentBalance: 11.1,
            installmentValue: 42000.0,
          }
        ],
      }
    ]
  }],
  failures: [],
};
