import { Component, Input} from '@angular/core';
import { planvitalSite } from '@constants';

@Component({
  selector: 'app-header-biodesktop',
  templateUrl: './header-biodesktop.component.html',
  styleUrls: ['./header-biodesktop.component.scss']
})
export class HeaderBiodesktopComponent {
  public planvitalSite = planvitalSite;
  public opened = false;
  @Input() public functionalityDescription: string;

  constructor() { }
}
