import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BIOMETRIC_VALIDATION_URL, DEVICES_URL, INACTIVITY_TIME_POST_VENTA } from '@constants';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { DEVICE_TYPE } from 'util/storage.constants';

@Injectable({
    providedIn: 'root'
})
export class InactivityService {

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
    }

    private initListener(timeout: number, action: () => void): void {
        let timeoutId: any;

        const resetTimer = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                action();
            }, timeout * 1000);
        };

        const events = ['click', 'touchstart'];

        const resetTimerHandler = () => {
            resetTimer();
        };

        const cleanup = () => {
            clearTimeout(timeoutId);
            events.forEach(event => {
                document.removeEventListener(event, resetTimerHandler);
            });
        };

        events.forEach(event => {
            document.addEventListener(event, resetTimerHandler);
        });

        resetTimer();

        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                cleanup();
            }
        });
    }

    public startInactivityPostVentaService(): void {
        const timeout = INACTIVITY_TIME_POST_VENTA;
        this.initListener(timeout, async () => {
            this.authenticationService.logout();
            const isBiotablet = localStorage.getItem(DEVICE_TYPE) === 'biotablet';
            const url = isBiotablet ? DEVICES_URL : BIOMETRIC_VALIDATION_URL;
            this.router.navigateByUrl(url);
        });
    }
}
