import { Mockup } from '@interfaces/mockup.interface';

export const RECAPTCHA_VALIDATOR_MOCK: Mockup = {
  success: [{
    response: {
      isValid: true,
    },
  },
  ],
  failures: [{
    response: {
      code: 50001,
      message: 'Error',
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
    },
  }],
};
