import { AgmCoreModule } from '@agm/core';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeEsCL from '@angular/common/locales/es-CL';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE, MatDialogModule, MatFormFieldModule, MatInputModule, MatSliderModule } from '@angular/material';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OtherAfpsContactModalComponent } from '@components/contact-afps-modal/contact-afps-modal.component';
import { FooterComponent } from '@components/footer/footer.component';
import { HeaderComponent } from '@components/header/header.component';
import { LoadingComponent } from '@components/loading/loading.component';
import { AuthorizedPhonesModalComponent } from '@components/modal-authorized-phones/modal-authorized-phones.component';
import { ModalContactComponent } from '@components/modal-contact/modal-contact.component';
import { ModalComponent } from '@components/modal/modal.component';
import { SecurityQuestionsErrorModalComponent } from '@components/security-questions-error-modal/security-questions-error-modal.component';
import { SendEmailPinModalComponent } from '@components/send-email-pin-modal/send-email-pin-modal.component';
import { SideNavMenuComponent } from '@components/side-nav-menu/side-nav-menu.component';
import { TimerComponent } from '@components/timer/timer.component';
import { environment } from '@env';
import { PipeModule } from '@pipes/pipe.module';
import { CommunicationProvider } from '@providers/communication/communication';
import { EmailDomainValidator } from '@providers/emailDomainValidator/emailDomainValidator';
import { HttpClientInterceptor } from '@providers/httpClientInterceptor/httpClientInterceptor';
import { HttpInterceptorProvider } from '@providers/httpInterceptor/httpInterceptor';
import { LoadingProvider } from '@providers/loading/loading';
import { ModalProvider } from '@providers/modal/modal';
import { RecaptchaInterceptor } from '@providers/recaptchaInterceptor/recaptcha-interceptor';
import { SessionProvider } from '@providers/session/session';
import { TimerProvider } from '@providers/timer/timer';
import { Util } from '@util';
import { ChartistModule } from 'ng-chartist';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginModule } from './modules/login/login.module';
import { SharedModule } from './modules/shared/shared.module';
import { HeaderBiodesktopComponent } from '@components/header-biodesktop/header-biodesktop.component';

registerLocaleData(localeEsCL, 'es-CL');

@NgModule({
  imports: [
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsAPIKey,
      libraries: ['places']
    }),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    ChartistModule,
    HttpClientModule,
    LoginModule,
    MatSliderModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    PipeModule,
    MatSidenavModule,
    MatRadioModule
  ],
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    ModalComponent,
    ModalContactComponent,
    AuthorizedPhonesModalComponent,
    OtherAfpsContactModalComponent,
    LoadingComponent,
    SecurityQuestionsErrorModalComponent,
    SendEmailPinModalComponent,
    TimerComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-CL' },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorProvider, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'es-CL' },
    { provide: LOCALE_ID, useValue: 'es-CL' },
    { provide: RECAPTCHA_SETTINGS, useValue: { siteKey: environment.googleReCaptchaSiteKey } as RecaptchaSettings },
    { provide: HTTP_INTERCEPTORS, useClass: RecaptchaInterceptor, multi: true },
    { provide: 'googleTagManagerId', useValue: environment.gmTag },
    CommunicationProvider,
    EmailDomainValidator,
    HttpClientInterceptor,
    Util,
    ModalProvider,
    LoadingProvider,
    SessionProvider,
    TimerProvider,
    AngularFireAuth
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ModalComponent,
    ModalContactComponent,
    AuthorizedPhonesModalComponent,
    OtherAfpsContactModalComponent,
    SecurityQuestionsErrorModalComponent,
    SendEmailPinModalComponent,
  ],
  exports: [
    SideNavMenuComponent,
    HeaderBiodesktopComponent
  ]
})

export class AppModule { }
