import { Mockup } from '@interfaces/mockup.interface';

export const CLIENT_EMPLOYERS_MOCKUP: Mockup = {
  success: [{
    response:
      [{
        businessName: 'Inter de Milán',
        contractTerm: 'Vigente',
        rut: '19'
      }]
  }],
  failures: [{
    response: {
      statusCode: 500,
      message: 'Error',
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.'
    }
  }],
};
