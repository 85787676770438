import { Mockup } from '@interfaces/mockup.interface';

export const CLIENT_MOCKUP: Mockup = {
  success: [{
    response: {
      afps: [
        {
          accounts: [
            {
              funds: [
                {
                  type: 'A',
                  regimes: [
                    {
                      balance: 0,
                      installmentAmount: 9999.99,
                      installments: 2,
                      type: 'SR',
                    }
                  ]
                }
              ],
              rejectedReasons: [
                {
                  code: '11'
                },
                {
                  code: '5'
                }
              ],
              type: 'CCO'
            },
            {
              funds: [
                {
                  type: 'B',
                  regimes: [
                    {
                      balance: 1000,
                      installmentAmount: 9999.99,
                      installments: 2,
                      type: 'SR',
                    }
                  ]
                }
              ],
              rejectedReasons: [],
              type: 'CDC'
            },
            {
              funds: [],
              rejectedReasons: [],
              type: 'CCO'
            },
          ],
          code: '1033',
          descriptionError: 'Sin problemas',
          name: 'AFP CUPRUM S.A.',
          serviceCode: '0'
        },
        {
          accounts: [
            {
              funds: [
                {
                  type: 'C',
                  regimes: [
                    {
                      balance: 30000,
                      installmentAmount: 9999.99,
                      installments: 2,
                      type: 'EX',
                    },
                    {
                      balance: 25000,
                      installmentAmount: 9999.99,
                      installments: 2,
                      type: 'O7',
                    }
                  ]
                }
              ],
              rejectedReasons: [],
              type: 'CAV'
            },
            {
              funds: [
                {
                  type: 'D',
                  regimes: [
                    {
                      balance: 25000,
                      installmentAmount: 9999.99,
                      installments: 2,
                      type: 'SR',
                    }
                  ]
                },
                {
                  type: 'E',
                  regimes: [
                    {
                      balance: 40000,
                      installmentAmount: 9999.99,
                      installments: 2,
                      type: 'SR',
                    }
                  ]
                }
              ],
              rejectedReasons: [],
              type: 'CAI'
            }
          ],
          code: '1034',
          descriptionError: 'Sin problemas',
          name: 'AFP HABITAT',
          serviceCode: '0'
        },
        {
          accounts: [
            {
              funds: [
                {
                  type: 'B',
                  regimes: [
                    {
                      balance: 30000,
                      installmentAmount: 9999.99,
                      installments: 2,
                      type: 'SR',
                    }
                  ]
                }
              ],
              rejectedReasons: [],
              type: 'CAF'
            },
            {
              funds: [
                {
                  type: 'B',
                  regimes: [
                    {
                      balance: 30000,
                      installmentAmount: 9999.99,
                      installments: 2,
                      type: 'AA',
                    },
                    {
                      balance: 2000,
                      installmentAmount: 9999.99,
                      installments: 2,
                      type: 'BF',
                    }
                  ]
                },
                {
                  type: 'A',
                  regimes: [
                    {
                      balance: 90,
                      installmentAmount: 9999.99,
                      installments: 2,
                      type: 'AB',
                    }
                  ]
                }
              ],
              rejectedReasons: [],
              type: 'CVC'
            },
            {
              funds: [
                {
                  type: 'A',
                  regimes: [
                    {
                      balance: 14000,
                      installmentAmount: 9999.99,
                      installments: 2,
                      type: 'SR',
                    }
                  ]
                },
                {
                  type: 'B',
                  regimes: [
                    {
                      balance: 32000,
                      installmentAmount: 9999.99,
                      installments: 2,
                      type: 'SR',
                    }
                  ]
                }
              ],
              rejectedReasons: [],
              type: 'CDC'
            }
          ],
          code: '1035',
          descriptionError: 'Sin problemas',
          name: 'AFP CAPITAL S.A.',
          serviceCode: '0'
        },
        {
          accounts: [
            {
              funds: [
                {
                  type: 'D',
                  regimes: [
                    {
                      balance: 30000,
                      installmentAmount: 9999.99,
                      installments: 2,
                      type: 'AA',
                    },
                    {
                      balance: 2000,
                      installmentAmount: 9999.99,
                      installments: 2,
                      type: 'BF',
                    }
                  ]
                },
                {
                  type: 'C',
                  regimes: [
                    {
                      balance: 90000,
                      installmentAmount: 9999.99,
                      installments: 2,
                      type: 'AB',
                    }
                  ]
                }
              ],
              rejectedReasons: [],
              type: 'CVC'
            }
          ],
          code: '1032',
          descriptionError: 'Sin problemas',
          name: 'AFP MODELO',
          serviceCode: '0'
        },
      ],
      client: {
        lastName: 'Rodriguez',
        names: 'Juan Carls',
        motherLastName: 'Diaz',
        address: '2565 Bailey Landing Suite 099',
        communeCode: '1402',
        regionCode: '1',
        birthdate: '20150320',
        age: 49,
        cellphoneNumber: '777777777',
        email: 'corene.wiegand.gulgowski@continuum.cl',
        gender: 'M',
        phoneNumber: '999999999',
        rut: '111111111',
        type: '1'
      },
      descriptionError: 'Sin problemas',
      employers: [
        {
          address: '1634 Hackett Station Apt. 559',
          communeCode: '1402',
          regionCode: '1',
          cellphoneNumber: '0',
          phoneNumber: '0',
          email: '',
          rut: '111111111',
          businessName: 'Test SPA',
        },
        {
          address: '1634 Hackett Station Apt. 559',
          communeCode: '1402',
          regionCode: '1',
          cellphoneNumber: '0',
          phoneNumber: '0',
          email: '',
          rut: '19',
          businessName: 'Test SPA',
        }
      ],
      serviceCode: '0',
      ticket: '4x5cr6ubyomip5c6rvt7yb8u9ni0mo',
    }
  },
  {
    response: {
      afps: [],
      client: {
        lastName: 'Rodriguez',
        names: 'Juan Carls',
        motherLastName: 'Diaz',
        address: '2565 Bailey Landing Suite 099',
        communeCode: '1402',
        regionCode: '1',
        birthdate: '20150320',
        age: 49,
        cellphoneNumber: '777777777',
        email: 'corene.wiegand.gulgowski@continuum.cl',
        gender: 'M',
        phoneNumber: '999999999',
        rut: '111111111',
        type: '1'
      },
      descriptionError: 'Sin problemas',
      employers: [],
      serviceCode: '0',
      ticket: '4x5cr6ubyomip5c6rvt7yb8u9ni0mo',
    }
  }
  ],
  failures: [{
    response: {
      code: 40103,
      message: 'Get client data attempts exceeded.',
      messageDescription: 'Get client data attempts exceeded.',
    }
  }, {
    response: {
      code: 40104,
      message: 'Intentos excedidos.',
      messageDescription: 'Se alcanzó el límite de intentos para obtener la información del cliente.',
    }
  }]
};
