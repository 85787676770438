import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { accountTypeName, FUNDS_DESCRIPTION, onServiceError } from '@constants';
import { AccountsGraphicSummaryResponse } from '@interfaces/accountGraphicSummaryResponse';
import { Fund, Product } from '@interfaces/affiliateAccount.interface';
import { FormatCurrencyPipe } from '@pipes/curency.pipe';
import { LoadingProvider } from '@providers/loading/loading';
import { ModalProvider } from '@providers/modal/modal';
import { AccountsService } from '@services/accounts/accounts.service';
import { PostVentaService } from '@services/post-venta/post-venta.service';
import { Util } from '@util';
import * as Chartist from 'chartist';
import { IBarChartOptions, IChartistData, IChartistSeriesData, ILineChartOptions } from 'chartist';
import * as ChartistTooltips from 'chartist-plugin-tooltips-updated';
import { finalize } from 'rxjs/operators';
import { CLIENT_DATA } from 'util/storage.constants';

@Component({
  selector: 'app-affiliate-account',
  templateUrl: './affiliate-account.component.html',
  styleUrls: ['./affiliate-account.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AffiliateAccountComponent implements OnInit, AfterViewInit {
  public affiliateAccountForm: FormGroup;
  public data: any;
  public productList: Product[];
  public accountTypeSelected: any;
  public accountTypes = accountTypeName;
  public selectedProduct: Product;
  public accountSelected: string;
  public behaviourLoaded = false;
  public accountsLoaded = false;
  public showBehaviour = false;
  private labelsBehaviour: string[];
  private seriesBehaviour: IChartistSeriesData[];

  constructor(
    private formBuilder: FormBuilder,
    public formatCurrency: FormatCurrencyPipe,
    private utils: Util,
    private accountService: AccountsService,
    private postVentaService: PostVentaService,
    private modalProvider: ModalProvider,
    private loadingProvider: LoadingProvider,
  ) {

    this.affiliateAccountForm = this.formBuilder.group({
      gap: [{ value: 'Ninguna', disabled: true }]
    });
  }

  public get funds(): Fund[] {
    return this.selectedProduct ? this.selectedProduct.funds : [];
  }

  public ngOnInit() {
    this.loadingProvider.showLoading();
  }

  public async ngAfterViewInit() {
    this.loadAccountsInfo();
    this.loadBehaviourData();
  }

  public fundDescription(fund: Fund) {
    return this.selectedProduct.funds.length > 1 ?
      this.isCollectorFund(fund) ? 'Fondo Recaudador' : 'Distribución'
      : FUNDS_DESCRIPTION[fund.name];
  }

  public isCollectorFund(fund: Fund) {
    return this.selectedProduct.fundCollectionType === fund.name;
  }

  public formatDate(date: string) {
    return date ? this.utils.formatChileanDate(date.split('T')[0]) : '';
  }

  public loadData(type: string) {
    let options;
    this.data = {
      labels: this.labelsBehaviour,
      series: [this.getSeriesGraphic(type).data]
    } as IChartistData;

    if (this.selectedProduct.funds.length === 1) {
      options = {
        fullWidth: true,
        showLine: true,
        showPoint: true,
        showArea: false,
        plugins: [ChartistTooltips({ currency: '$ ' })]
      } as ILineChartOptions;
      return new Chartist.Line('.ct-chart', this.data, options);
    }

    options = {
      axisX: { offset: 20 },
      plugins: [ChartistTooltips({ currency: '$ ' })]
    } as IBarChartOptions;

    return new Chartist.Bar('.ct-chart', this.data, options);

  }

  public getFundTypeClass(type: string) {
    return `type-${ type.toLowerCase() }`;
  }

  public handleError(error) {
    error = error.error || error;
    if (!error.code) { error = onServiceError; }
    this.modalProvider.openGenericErrorModal(error);
  }

  private accountTypeChanges() {
    this.affiliateAccountForm.get('accountType').valueChanges.subscribe(
      (value) => {
        this.selectedProduct = this.productList.find((product) => {
          return product.type === value;
        });
        this.loadData(value.toLowerCase());
      }
    );
  }

  private loadBehaviourData() {
    this.postVentaService.graphicSummary()
      .pipe(finalize(() => {
        this.behaviourLoaded = true;
        if (this.accountsLoaded) {
          this.loadingProvider.hideLoading();
        }
      }))
      .subscribe((data: AccountsGraphicSummaryResponse) => {
        this.normalizeIChartData(data);
      });
  }

  private normalizeIChartData(summaryReponse: AccountsGraphicSummaryResponse) {
    this.showBehaviour = true;
    const normalizedSerie = ([name, data]) => ({ name: name.toLowerCase(), data }) as Chartist.IChartistSeriesData;
    this.labelsBehaviour = summaryReponse.periods.map((period) => period.replace('‘', '20'));
    this.seriesBehaviour = Object.entries(summaryReponse.accounts).map(normalizedSerie);
  }

  private getSeriesGraphic(type: string): IChartistSeriesData {
    return this.seriesBehaviour.find((serie) => {
      return serie.name === type;
    });
  }

  private loadAccountsInfo() {
    const clientData = JSON.parse(localStorage.getItem(CLIENT_DATA));
    this.accountService.getProductsBalance(clientData.rut)
      .pipe(finalize(() => {
        this.accountsLoaded = true;
        if (this.behaviourLoaded) {
          this.affiliateAccountForm.get('accountType').setValue(this.productList[0].type);
          this.loadingProvider.hideLoading();
        }
      }))
      .subscribe(
        (accounts) => this.setAccounts(accounts),
        (error) => this.handleError(error)
      );
  }

  private setAccounts(accounts) {
    this.productList = accounts;
    this.accountSelected = accounts[0].type;
    const accountTypeControl = new FormControl(this.accountSelected);
    this.affiliateAccountForm.addControl('accountType', accountTypeControl);
    this.accountTypeChanges();
  }

}
