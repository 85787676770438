import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatCheckboxModule,
  MatDialogModule,
  MatDividerModule,
  MatFormFieldModule,
  MatInputModule,
  MatRadioModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatTabsModule,
} from '@angular/material';
import { ContactCenterComponent } from '@components/contact-center/contact-center.component';
import { ErrorIdentityValidationComponent } from '@components/error-identity-validation/error-identity-validation.component';
import { ExecutiveIdentityValidationComponent } from '@components/executive-identity-validation/executive-identity-validation.component';
import { GoBackComponent } from '@components/go-back/go-back.component';
import { ListRedirectionButtonsComponent } from '@components/list-redirection-buttons/list-redirection-buttons.component';
import { ProfitabilityDisclaimerComponent } from '@components/profitability-disclaimer/profitability-disclaimer.component';
import { WhatsappResultModalComponent } from '@components/whatsapp-result-modal/whatsapp-result-modal.component';
import { DirectivesModule } from '@directives/directives.module';
import { environment } from '@env';
import { PipeModule } from '@pipes/pipe.module';
import { AutentiaService } from '@services/autentia/autentia.service';
import { PlatformGuardService } from '@services/platform-guard/platform-guard.service';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaFormsModule, RecaptchaV3Module } from 'ng-recaptcha';
import { RutValidator } from 'ng2-rut';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { SharedModule } from '../shared/shared.module';
import { AccountsComponent } from './accounts/accounts.component';
import { AssistedComponent } from './assisted/assisted.component';
import { BioDesktopComponent } from './biodesktop/biodesktop.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { ContactSuccessfulComponent } from './contact-successful/contact-successful.component';
import { ContactComponent } from './contact/contact.component';
import { ContinueComponent } from './continue/continue.component';
import { ExecutiveComponent } from './executive/executive.component';
import { LoginRoutingModule } from './login-routing.module';
import { OnlineComponent } from './online/online.component';
import { PostVentaComponent } from './post-venta/post-venta.component';
import { QuestionsComponent } from './questions/questions.component';
import { UnassistedComponent } from './unassisted/unassisted.component';
import { VoucherPreviewComponent } from './voucher/voucher-preview.component';


@NgModule({
  declarations: [
    AssistedComponent,
    BioDesktopComponent,
    ErrorIdentityValidationComponent,
    ExecutiveComponent,
    ExecutiveIdentityValidationComponent,
    ListRedirectionButtonsComponent,
    ProfitabilityDisclaimerComponent,
    GoBackComponent,
    UnassistedComponent,
    ContactComponent,
    OnlineComponent,
    ContactSuccessfulComponent,
    QuestionsComponent,
    PostVentaComponent,
    ContinueComponent,
    ConfirmComponent,
    VoucherPreviewComponent,
    ContactCenterComponent,
    AccountsComponent,
    WhatsappResultModalComponent,
  ],
  imports: [
    AgmCoreModule,
    CommonModule,
    LoginRoutingModule,
    DeviceDetectorModule.forRoot(),
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    RecaptchaV3Module,
    RecaptchaFormsModule,
    MatTabsModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatDividerModule,
    PipeModule,
    SharedModule,
  ],
  exports: [],
  providers: [
    PlatformGuardService,
    AutentiaService,
    RutValidator,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.siteKey },
  ],
  entryComponents: [
    ListRedirectionButtonsComponent,
    WhatsappResultModalComponent,
  ],
})
export class LoginModule {
}
