import { Directive, HostListener, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Util } from '@util';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[formatRut]'
})
export class FormatRutDirective {
  @Input('formatRut') public formControl: FormControl;

  constructor(
    private util: Util,
  ) { }

  @HostListener('input', ['$event.target.value']) public onChange(typedValue: string) {
    if (!typedValue) return;
    const rut = this.util.rutFormat(typedValue);
    this.formControl.setValue(rut);
  }
}
