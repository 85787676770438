import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import {
  postVentaServiceError,
  SEND_PASSWORD_NO_DATA_TEXT,
  SEND_PASSWORD_TEXT,
  SEND_PASSWORD_URL,
  SEND_SECURITY_PASSWORD_URL,
  WAITING_TIME_FAILURE,
  WAITING_TIME_SUCCESS
} from '@constants';
import { ClientDataResponse } from '@interfaces/clientData.interface';
import { LoadingProvider } from '@providers/loading/loading';
import { ModalProvider } from '@providers/modal/modal';
import { PostVentaService } from '@services/post-venta/post-venta.service';
import { finalize } from 'rxjs/operators';
import { CLIENT_DATA } from 'util/storage.constants';

@Component({
  selector: 'app-affiliate-password',
  templateUrl: './affiliate-password.component.html',
  styleUrls: ['./affiliate-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AffiliatePasswordComponent implements OnInit {
  @Input() public passwordMethod: string;
  @Output() public goToContact = new EventEmitter();
  public SEND_PASSWORD_NO_DATA_TEXT = SEND_PASSWORD_NO_DATA_TEXT;
  public SEND_PASSWORD_TEXT = SEND_PASSWORD_TEXT;
  public WAITING_TIME_SUCCESS = WAITING_TIME_SUCCESS;
  public WAITING_TIME_FAILURE = WAITING_TIME_FAILURE;
  public loading = false;
  public ready = false;
  public success = false;
  public hasMethodData = true;
  private clientData: ClientDataResponse;
  private executiveInfo: string;

  constructor(
    private router: Router,
    private loadingProvider: LoadingProvider,
    private modalProvider: ModalProvider,
    private postVentaService: PostVentaService
  ) {
  }

  public ngOnInit(): void {
    this.checkUserData();
    this.sendPassword(this.passwordMethod);
  }

  public goToSendPassword(): void {
    if (this.ready || this.loading) return;
    this.router.navigateByUrl(SEND_PASSWORD_URL);
  }

  public async goToSendSecurityPassword() {
    if (this.ready || this.loading) return;
    this.loadingProvider.showLoading();
    this.postVentaService.getSecurityPasswordState(this.clientData.rut)
      .pipe(finalize(() => this.loadingProvider.hideLoading()))
      .subscribe((response) => {
        localStorage.setItem('securityKeyStatus', String(response.code));
        this.router.navigateByUrl(SEND_SECURITY_PASSWORD_URL);
      }, error => this.handleServiceError(error));
  }

  private handleServiceError(error) {
    error = error.error || error;
    if (!error.code) error = postVentaServiceError;
    this.modalProvider.openGenericRetryErrorModal(error);
  }

  private checkUserData(): void {
    this.clientData = JSON.parse(localStorage.getItem(CLIENT_DATA));
    this.hasMethodData = !!(this.clientData && this.clientData.email || this.clientData.cellphoneNumber);
  }

  private sendPassword(passwordMethod: string): void {

    if (!passwordMethod) return;
    this.loading = true;
    const request = this.createRequestPayload(passwordMethod);
    this.postVentaService.sendTemporalPassword(request).pipe(
      finalize(() => {
        this.loading = false;
        this.ready = true;
      })
    ).subscribe(
      () => {
        this.handleSuccessSendPassword();
      },
      () => {
        this.success = false;
        setTimeout(() => {
          this.ready = false;
        }, WAITING_TIME_FAILURE * 1000);
      }
    );
  }

  private handleSuccessSendPassword() {
    this.success = true;
    setTimeout(() => {
      this.ready = false;
    }, WAITING_TIME_SUCCESS * 1000);
  }

  private createRequestPayload(passwordMethod: string) {
    this.executiveInfo = localStorage.getItem('infoExecutive');
    const personalData = {
      rut: this.clientData.rut,
      name: this.clientData.name
    };

    let passwordMethodObject;
    if (passwordMethod === 'sms') passwordMethodObject = { cellphoneNumber: Number(this.clientData.cellphoneNumber) };
    if (passwordMethod === 'mail') passwordMethodObject = { email: this.clientData.email };
    return { ...personalData, ...passwordMethodObject, executiveInfo: this.executiveInfo };
  }
}
