import { Injectable } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { formatNumber } from '@angular/common';
import {
  distributableAccounts,
  httpErrorCodes,
  mandatoryAccounts,
  MIN_PESOS,
  normalizedAccountType,
  PHONE_MIN_LENGTH,
  planvitalSite,
  regexOnlyNumbers,
  SCREEN_BREAKPOINTS,
  TRIPLE_DES_KEY,
  voluntaryAccounts,
  WORD_COMPOUND_NAMES
} from '@constants';
import { environment } from '@env';
import { AccountWithAfp, PersonalInfo, Regime } from '@interfaces/client.interface';
import { ClientDataResponse } from '@interfaces/clientData.interface';
import { Region } from '@interfaces/region.interface';
import { SplitDate } from '@interfaces/splitDate.interface';
import { ClientRequest } from '@interfaces/transfer.interface';
import * as CryptoJS from 'crypto-js';
import { format } from 'date-fns';
import * as esLocale from 'date-fns/locale/es/index.js';
import * as rutHelpers from 'rut-helpers';
import { throwError } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

const DONT_EXIST = -1;

@Injectable()
export class Util {
  private formatNumbers = [new Intl.NumberFormat('es-CL')];

  constructor() {
  }

  public get isTablet(): boolean {
    return window.innerWidth <= SCREEN_BREAKPOINTS.TABLET;
  }

  public get isMediumMobile(): boolean {
    return window.innerWidth <= SCREEN_BREAKPOINTS.MEDIUM_MOBILE;
  }

  public get isSmallMobile(): boolean {
    return window.innerWidth <= SCREEN_BREAKPOINTS.SMALL_MOBILE;
  }

  public getCompleteSpanishDate(date: string): string {
    return format(date, 'dddd D [de] MMMM [del] YYYY [a las] HH:mm', { locale: esLocale });
  }

  public scrollUp() {
    window.scroll(0, 0);
  }

  public isProductionEnvironment() {
    return environment.name === 'prod' || environment.name === 'prod-biodesktop';
  }

  public isBiodesktopEnvironment(): boolean {
    return environment.biodesktopEnv;
  }

  public handleError(error: any) {
    return throwError(this.getErrorMessage(error));
  }

  public getErrorMessage(error: any) {
    const responseObject = {
      statusCode: httpErrorCodes.internalServerError.code,
      message: httpErrorCodes.internalServerError.message,
      messageDescription: httpErrorCodes.internalServerError.messageDescription,
    };

    if (error && error.hasOwnProperty('status')) {
      responseObject.statusCode = error.status;

      if (error.status === httpErrorCodes.unauthorized.code) {
        responseObject.message = httpErrorCodes.unauthorized.message;
        responseObject.messageDescription = httpErrorCodes.unauthorized.messageDescription;
      }
    }
    return responseObject;
  }

  public generalCatchError(error) {
    return (error.status === httpErrorCodes.badRequest.code) ?
      throwError(error) : this.handleError(error);
  }

  public getClientFullName(clientData: ClientDataResponse) {
    return `${clientData.name || ''} ${clientData.lastName || ''} ${clientData.motherLastName || ''}`;
  }

  public rutFormat(unformatteRut: string) {
    return rutHelpers.rutFormat(unformatteRut);
  }

  public setClientPersonalData(client: PersonalInfo | ClientRequest) {
    const { rut, names, lastName, motherLastName } = client;
    const clientRut = this.rutFormat(rut);
    const clientName = names ? names : '-';
    const clientLastNames = lastName && motherLastName ? `${lastName} ${motherLastName}` : '-';
    return [clientRut, clientName, clientLastNames];
  }

  public rutClean(formattedRut: string) {
    return rutHelpers.rutClean(formattedRut);
  }

  public getFundColor(letterType: string) {
    return `type-${letterType.toLowerCase()}`;
  }

  public getAccountColor(initialsType: string) {
    return `type-${normalizedAccountType[initialsType].toLowerCase()}`;
  }

  public getTraceID() { return sessionStorage.getItem('traceID'); }

  public setAndGetTraceId() {
    const traceId = uuidv4();
    sessionStorage.setItem('traceID', traceId);
    return traceId;
  }

  public getOriginTransfer() { return sessionStorage.getItem('originTransfer'); }

  public isMandatoryAccount(accountType: string) {
    return mandatoryAccounts.includes(accountType);
  }

  public isVoluntaryAccount(accountType: string) {
    return voluntaryAccounts.includes(accountType);
  }

  public isDistributableAccount(accountType: string) {
    return distributableAccounts.includes(accountType);
  }

  public capitalizeFirstLetter(text: string) {
    if (!text) return '';
    text = text.toLowerCase();
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  public capitalizeText(text: string) {
    if (!text) return '';
    return text.toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(' ');
  }

  public getTaxIndex(account: AccountWithAfp, regimeIndex: number) {
    const regimeSelected = account.regimes[regimeIndex];
    return account.regimes.findIndex(this.regimeCondition(regimeSelected));
  }

  public fundWithTaxBonus(account: AccountWithAfp, regimeIndex: number): boolean {
    const regimeSelected = account.regimes[regimeIndex];
    return regimeSelected.regimeType === 'AA' && account.regimes.some(this.regimeCondition(regimeSelected));
  }

  public splitDate(date: string): string {
    return date.split(' ')[0];
  }

  public goToPlanVital() {
    window.open(planvitalSite);
  }

  public base64toBlob(base64Data) {
    const sliceSize = 512;
    const byteCharacters = atob(base64Data);

    const byteArrays = this.range(byteCharacters.length / sliceSize + 1)
      .map(digit => digit * sliceSize)
      .map(offset => byteCharacters.slice(offset, offset + sliceSize))
      .map(selectedBytes => Array.from(selectedBytes).map(char => char.charCodeAt(0)))
      .map(byteNumbers => new Uint8Array(byteNumbers));

    return new Blob(byteArrays, { type: 'application/pdf' });
  }

  public formatChileanDate(date: string, symbol?: string) {
    const separator = symbol ? symbol : '-';
    const dateSplit = date.split(separator);
    return `${dateSplit[2].split('T')[0]}/${dateSplit[1]}/${dateSplit[0]}`;
  }

  public formatCurrency(amount: number, precision = 0): string {
    return '$' + this.formatCurrencyNumber(amount, precision);
  }

  public formatCurrencyNumber(unformattedNumber: number, precision = 2): string {
    return formatNumber(unformattedNumber, 'es-CL', `1.${precision}-${precision}`);
  }

  public removeCurrencySymbols(currency): number {
    if (!currency) return 0;
    return Number(String(currency).replace(regexOnlyNumbers, '') || 0);
  }

  public formatDate(date: string, nullCase: string): string {
    return date ? this.splitDate(date) : nullCase;
  }

  public getSplitDate(date: Date): SplitDate {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    return { day, month, year };
  }

  public amountValidator(): ValidatorFn {
    return (control) => {
      let amount = String(control.value);
      amount = amount.replace(regexOnlyNumbers, '');
      const error = {};
      if (Number(amount) < MIN_PESOS) {
        error['insufficientAmount'] = true;
      }
      return (Object.keys(error).length) ? error : null;
    };
  }

  public decryptRut(encryptedRut: string): string {
    try {
      const key = this.getMd5Key();

      const options = { mode: CryptoJS.mode.ECB };
      const cipherText = { ciphertext: CryptoJS.enc.Base64.parse(encryptedRut) };
      const decrypted = CryptoJS.TripleDES.decrypt(cipherText, key, options);

      const rut = decrypted.toString(CryptoJS.enc.Utf8);

      if (!rutHelpers.rutValidate(rut)) return rut;

      return rut;
    } catch {
      return '';
    }
  }

  public checkSpelling(regions: Array<Region>): Array<Region> {
    regions.forEach(region => {
      region.description = this.addRegionsAccents(region.description);
      region.communes.forEach(commune => commune.description = this.addCommunesAccents(commune.description));
    });
    return regions;
  }

  public mapCompoundNames(name: string): string[] {
    const splitted = name.split(' ');
    const index = splitted.findIndex(word => WORD_COMPOUND_NAMES.includes(word));
    if (index === DONT_EXIST) return splitted;
    const response = [];

    for (let i = 0; i < splitted.length; i++) {
      const insert = i === index + 1 ? `${splitted[index]} ${splitted[i]}` : splitted[i];
      if (index !== i) response.push(insert);
    }
    return response;
  }

  public getPercentage(value: number, base: number): number {
    return ((value * 100) / base);
  }

  public addNumberPunctuation(unformattedNumber: number | string, precision = 0) {
    if (!(precision in this.formatNumbers)) {
      this.formatNumbers[precision] = new Intl.NumberFormat('es-CL',
        {
          minimumFractionDigits: precision,
          maximumFractionDigits: precision,
        });
    }
    const formatedNumber = this.formatNumbers[precision];
    let formattedNumber = unformattedNumber;
    if (typeof formattedNumber !== 'number') {
      formattedNumber = Number(formattedNumber);
    }
    return isNaN(formattedNumber) ? String(unformattedNumber) : formatedNumber.format(formattedNumber);
  }

  public completeCellphone(cellphone: string) {
    const extraDigits = '9'.repeat(PHONE_MIN_LENGTH - cellphone.length);
    return extraDigits.concat(cellphone);
  }

  public removeAccents(input: string): string {
    return input.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  private regimeCondition(regimeSelected: Regime) {
    return (regime: Regime) => {
      return regime.regimeType === 'BF' && regime.originFundType === regimeSelected.originFundType;
    };
  }

  private range(length: number) {
    return Array.from({ length }, (value, key) => key);
  }

  private getMd5Key() {
    let md5Key = CryptoJS.enc.Utf8.parse(TRIPLE_DES_KEY);
    md5Key = CryptoJS.MD5(md5Key);
    md5Key.words.push(md5Key.words[0], md5Key.words[1]);

    return md5Key;
  }

  private addRegionsAccents(text: string): string {
    text = text.replace('Region', 'Región');
    text = text.replace('eptima', 'éptima');
    text = text.replace('ecima', 'écima');
    return text;
  }

  private addCommunesAccents(text: string): string {
    text = text.replace('Camina', 'Camiña');
    text = text.replace('Maria', 'María');
    text = text.replace('Ollague', 'Ollagüe');
    text = text.replace('Chanaral', 'Chañaral');
    text = text.replace('Copiapo', 'Copiapó');
    text = text.replace('Combarbala', 'Combarbalá');
    text = text.replace('Rio ', 'Río ');
    text = text.replace('Vicuna', 'Vicuña');
    text = text.replace('Concon', 'Concón');
    text = text.replace('Fernandez', 'Fernández');
    text = text.replace('Olmue', 'Olmué');
    text = text.replace('Puchuncavi', 'Puchuncaví');
    text = text.replace('Quilpue', 'Quilpué');
    text = text.replace('Valparaiso', 'Valparaíso');
    text = text.replace('Vina ', 'Viña ');
    text = text.replace('Chepica', 'Chépica');
    text = text.replace('Donihue', 'Doñihue');
    text = text.replace('Machali', 'Machalí');
    text = text.replace('Requinoa', 'Requínoa');
    text = text.replace('Colbun', 'Colbún');
    text = text.replace('Constitucion', 'Constitución');
    text = text.replace('Curico', 'Curicó');
    text = text.replace('Hualane', 'Hualañé');
    text = text.replace('Licanten', 'Licantén');
    text = text.replace('Longavi', 'Longaví');
    text = text.replace('Vichuquen', 'Vichuquén');
    text = text.replace('Bío', 'Bio');
    text = text.replace('Canete', 'Cañete');
    text = text.replace('Concepcion', 'Concepción');
    text = text.replace('Hualpen', 'Hualpén');
    text = text.replace('Alamos', 'Álamos');
    text = text.replace('Angeles', 'Ángeles');
    text = text.replace('Mulchen', 'Mulchén');
    text = text.replace('Barbara', 'Bárbara');
    text = text.replace('Tirua', 'Tirúa');
    text = text.replace('Tome', 'Tomé');
    text = text.replace('Curacautin', 'Curacautín');
    text = text.replace('Pitrufquen', 'Pitrufquén');
    text = text.replace('Pucon', 'Pucón');
    text = text.replace('Puren', 'Purén');
    text = text.replace('Tolten', 'Toltén');
    text = text.replace('Traiguen', 'Traiguén');
    text = text.replace('Vilcun', 'Vilcún');
    text = text.replace('Chaiten', 'Chaitén');
    text = text.replace('Cochamo', 'Cochamó');
    text = text.replace('Velez', 'Vélez');
    text = text.replace('Futaleufu', 'Futaleufú');
    text = text.replace('Hualaihue', 'Hualaihué');
    text = text.replace('Maullin', 'Maullín');
    text = text.replace('Puqueldon', 'Puqueldón');
    text = text.replace('Queilen', 'Queilén');
    text = text.replace('Quellon', 'Quellón');
    text = text.replace('Aisen', 'Aisén');
    text = text.replace('Ibanez', 'Ibáñez');
    text = text.replace('Antartica', 'Antártica');
    text = text.replace('Union', 'Unión');
    text = text.replace('Mafil', 'Máfil');
    text = text.replace('Chillan', 'Chillán');
    text = text.replace('Niquen', 'Ñiquén');
    text = text.replace('Quillon', 'Quillón');
    text = text.replace('Ranquil', 'Ránquil');
    text = text.replace('Fabian', 'Fabián');
    text = text.replace('Nicolas', 'Nicolás');
    text = text.replace('Alhue', 'Alhué');
    text = text.replace('Conchali', 'Conchalí');
    text = text.replace('Curacavi', 'Curacaví');
    text = text.replace('Estacion', 'Estación');
    text = text.replace('Maipu', 'Maipú');
    text = text.replace('Nunoa', 'Ñuñoa');
    text = text.replace('Penaflor', 'Peñaflor');
    text = text.replace('Penalolen', 'Peñalolén');
    text = text.replace('Joaquin', 'Joaquín');
    text = text.replace('Jose ', 'José ');
    text = text.replace('Ramon', 'Ramón');
    return text;
  }

}
