import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { ClientDataResponse } from '@interfaces/clientData.interface';
import { EmployerDataResponse } from '@interfaces/employerDataResponse.interface';
import { Region } from '@interfaces/region.interface';
import { LoadingProvider } from '@providers/loading/loading';
import { ClientService } from '@services/client/client.service';
import { PostVentaService } from '@services/post-venta/post-venta.service';
import { Util } from '@util';
import { forkJoin, throwError } from 'rxjs';
import { finalize } from 'rxjs/internal/operators/finalize';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-employers-opening-request',
  templateUrl: './employers-opening-request.component.html',
  styleUrls: ['./employers-opening-request.component.scss']
})

export class EmployersOpeningRequestComponent implements AfterViewInit {
  @Input() public clientData: ClientDataResponse;
  @Input() public selectedOption: string;
  @Output() public next = new EventEmitter();
  @Output() public goBack = new EventEmitter();
  @Output() public servicesStatus = new EventEmitter<Array<boolean>>();
  public regions;
  public communes;
  public serviceError: boolean;
  public employers: Array<EmployerDataResponse>;
  public loaded = false;
  public selectedId: number;
  public selectedEmployerForm;
  private employeeTypesServiceFailed: boolean;
  private regionsServiceFailed: boolean;

  constructor(
    public util: Util,
    private loadingProvider: LoadingProvider,
    private postVentaService: PostVentaService,
    private clientService: ClientService,
  ) {}

  public ngAfterViewInit() {
    this.loadData();
  }

  public send() {
    return this.next.emit({ step: 3, employer: this.selectedEmployerForm });
  }

  public back() {
    return this.goBack.emit();
  }

  public loadData(retry = false) {
    this.loadingProvider.showLoading();

    const employers = this.postVentaService.getClientEmployers(this.clientData.rut).pipe(
      (response) => {
        this.employeeTypesServiceFailed = false;
        return response;
      }, catchError(error => {
        this.employeeTypesServiceFailed = true;
        return throwError(error);
      })
    );
    const regionsService = this.clientService.getRegions().pipe(
      (response) => {
        this.regionsServiceFailed = false;
        return response;
      }
      , catchError(error => {
        this.regionsServiceFailed = true;
        return throwError(error);
      })
    );

    forkJoin([employers, regionsService]).pipe(
      finalize(() => {
        this.loadingProvider.hideLoading();
        this.servicesStatus.emit([this.employeeTypesServiceFailed, this.regionsServiceFailed]);
      }),
    ).subscribe(
      ([employersResponse, regions]: [Array<EmployerDataResponse>, Array<Region>]) => {
        this.employers = employersResponse;
        this.regions = regions;
        this.serviceError = false;
        this.loaded = true;
      },
      () => this.serviceError = true
    );
  }

  public saveEmployer(index: number, $event: { response, newEmployer }) {
    if ($event.newEmployer) {
      this.employers.push($event.response);
      return;
    }
    return this.employers[index] = $event.response;
  }

  public select(data) {
    this.selectedId = data.id;
    this.selectedEmployerForm = data.form;
  }
}
