import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { ExecutivePorfolioResponse, PortfolioAffiliate, PortfolioAffiliateCard } from '@interfaces/executivePortfolioResponse.interface';
import { Util } from '@util';
import * as _ from 'lodash';
import { MONTHS_SHOWN_PORTFOLIO } from 'util/portfolio.constants';
import { DEVICE_TYPE } from 'util/storage.constants';

@Component({
  selector: 'app-portfolio-search',
  templateUrl: './portfolio-search.component.html',
  styleUrls: ['./portfolio-search.component.scss']
})
export class PortfolioSearchComponent implements OnInit {
  @Input() public portfolioMonth: ExecutivePorfolioResponse;
  @Output() public affiliateSelect$ = new EventEmitter<string>();
  @Output() public actionRoute = new EventEmitter();

  public portfolioView: PortfolioAffiliate[];
  public searchAndSortForm: FormGroup;
  public pageSize = 5;
  public paginationFrom = 0;
  public paginationTo = 5;
  public selectedOpt;

  public options = MONTHS_SHOWN_PORTFOLIO;

  private portfolioMonthView: number;

  constructor(
    public util: Util,
    private formBuilder: FormBuilder,
  ) {
    this.searchAndSortForm = this.formBuilder.group({
      sort: [''],
      search: [''],
    });
  }

  get isSearchActive() {
    const { search } = this.searchAndSortForm.controls;
    return search.value !== '';
  }

  public getMonthNoContact(): number {
    return this.portfolioMonth.affiliates.filter((affiliate) => affiliate.contacted === false).length;
  }

  public selectOption(opt: string) {
    this.selectedOpt = opt;
    this.actionRoute.emit(opt);
  }

  public generateInfoCard(affiliate: PortfolioAffiliate): Array<PortfolioAffiliateCard> {
    const infoCard: Array<PortfolioAffiliateCard> = [
      {
        title: 'Tipo de afiliado:',
        description: affiliate.affiliateType,
      },
      {
        title: 'Suscripción:',
        description: affiliate.transferDate ? this.util.formatChileanDate(affiliate.transferDate) : 'Sin Información',
      },
      {
        title: 'Afiliación:',
        description: affiliate.affiliationDate ? this.util.formatChileanDate(affiliate.affiliationDate) : 'Sin Información',
      },
      {
        title: 'Último Contacto:',
        description: affiliate.contactDate ? this.util.formatChileanDate(affiliate.contactDate) : 'Sin Información',
      }
    ];
    return infoCard;
  }

  public getNameAndLastNames(affiliate) {
    return `${ affiliate.name.split(' ')[0] } ${ affiliate.fatherLastName } ${ affiliate.motherLastName }`;
  }

  public getFormatDate(affiliate) {
    if (!affiliate.departureDate) return;
    return this.util.formatChileanDate(affiliate.departureDate);
  }

  public getRut(affiliate) {
    return this.util.rutFormat(affiliate.rut);
  }

  public getAction(rut: string) {
    return () => this.affiliateSelect$.emit(rut);
  }

  public ngOnInit() {
    this.portfolioView = [...this.portfolioMonth.affiliates];
    this.orderBySubscription();
    this.selectedOpt = this.portfolioMonth.month;
    this.setPaginationValues();
  }

  public orderBySubscription() {
    const { sort, search } = this.searchAndSortForm.controls;
    sort.valueChanges.subscribe((value) => this.orderPortfolioMonthBy(value));
    search.valueChanges.subscribe((value) => this.searchPortfolioMonth(value));
  }

  public pagination($event: PageEvent) {
    this.paginationFrom = $event.pageIndex * this.pageSize;
    this.paginationTo = ($event.pageIndex + 1) * this.pageSize;
  }

  private setPaginationValues() {
    const isBiotablet = localStorage.getItem(DEVICE_TYPE) === 'biotablet';
    this.pageSize = isBiotablet ? 5 : 20;
    this.paginationTo = isBiotablet ? 5 : 20;
  }

  private orderPortfolioMonthBy(value: string) {
    this.portfolioMonth.affiliates = (_.orderBy(this.portfolioMonth.affiliates, value, 'asc'));
  }

  private searchPortfolioMonth(value: string) {
    const reg = new RegExp(this.util.removeAccents(value).replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'gi');

    // To maintain search integrity when changing months
    if (this.portfolioMonthView !== this.portfolioMonth.month) this.portfolioView = [...this.portfolioMonth.affiliates];
    this.portfolioMonthView = this.portfolioMonth.month;

    // It returns to the backup of the list
    if (value === '') return this.portfolioMonth.affiliates = [...this.portfolioView];

    // Filters
    this.portfolioMonth.affiliates = this.portfolioView.filter((affiliate) => {
      return this.util.removeAccents(affiliate.name).match(reg) ||
        this.util.removeAccents(affiliate.fatherLastName).match(reg) ||
        this.util.removeAccents(affiliate.motherLastName).match(reg) ||
        this.util.rutClean(affiliate.rut).match(reg);
    });

    const { sort } = this.searchAndSortForm.controls;
    this.orderPortfolioMonthBy(sort.value);
  }

}
