import { Mockup } from '@interfaces/mockup.interface';

export const REGIONS_MOCKUP: Mockup = {
  success: [{
    response: [
      {
        code: '1',
        description: 'PRIMERA REGION',
        communes: [
          {
            code: '1107',
            description: 'ALTO HOSPICIO',
            regionCode: '1',
            city: 'IQUIQUE'
          },
          {
            code: '1402',
            description: 'CAMINA',
            regionCode: '1',
            city: 'CAMINA'
          },
          {
            code: '1403',
            description: 'COLCHANE',
            regionCode: '1',
            city: 'ARICA'
          },
          {
            code: '1404',
            description: 'HUARA',
            regionCode: '1',
            city: 'IQUIQUE'
          },
          {
            code: '1101',
            description: 'IQUIQUE',
            regionCode: '1',
            city: 'IQUIQUE'
          },
          {
            code: '1405',
            description: 'PICA',
            regionCode: '1',
            city: 'IQUIQUE'
          },
          {
            code: '1401',
            description: 'POZO ALMONTE',
            regionCode: '1',
            city: 'IQUIQUE'
          }
        ]
      },
      {
        code: '2',
        description: 'SEGUNDA REGION',
        communes: [
          {
            code: '2101',
            description: 'ANTOFAGASTA',
            regionCode: '2',
            city: 'ANTOFAGASTA'
          },
          {
            code: '2201',
            description: 'CALAMA',
            regionCode: '2',
            city: 'CALAMA'
          },
          {
            code: '2302',
            description: 'MARIA ELENA',
            regionCode: '2',
            city: 'MARIA ELENA'
          }
        ]
      },
      {
        code: '3',
        description: 'TERCERA REGION',
        communes: []
      }
    ]
  }],
  failures: [{
    response: {
      code: 40081,
      message: 'No hay regiones o comunas.',
      messageDescription: 'La lista de regiones y/o comunas está vacía.',
    }
  }]
};

