/* tslint:disable:no-magic-numbers */
export const PORTFOLIO_COMPLAIN_MOCKUP = {
  success: [
    {
      response: [
        {
          ticket: 123093,
          estado: 'ATRASADO',
          fechaNormativa: '2021-01-11 18:00:00.000',
          respuestaFinal: null
        },
        {
          ticket: 123092,
          estado: 'ATRASADO',
          fechaNormativa: '2021-01-11 18:00:00.000',
          respuestaFinal: null
        },
        {
          ticket: 123091,
          estado: 'ATRASADO',
          fechaNormativa: '2021-01-11 18:00:00.000',
          respuestaFinal: null
        },
        {
          ticket: 123090,
          estado: 'ATRASADO',
          fechaNormativa: '2021-01-11 18:00:00.000',
          respuestaFinal: null
        },
        {
          ticket: 123089,
          estado: 'ATRASADO',
          fechaNormativa: '2022-01-12 18:00:00.000',
          respuestaFinal: null
        },
        {
          ticket: 123088,
          estado: 'ATRASADO',
          fechaNormativa: '2022-01-07 16:19:00.000',
          respuestaFinal: null
        },
        {
          ticket: 123087,
          estado: 'ATRASADO',
          fechaNormativa: '2021-12-28 16:21:00.000',
          respuestaFinal: null
        },
        {
          ticket: 123086,
          estado: 'ATRASADO',
          fechaNormativa: '2021-12-30 16:19:00.000',
          respuestaFinal: null
        },
        {
          ticket: 123085,
          estado: 'PENDIENTE',
          fechaNormativa: '2022-01-27 16:18:00.000',
          respuestaFinal: null
        }
      ]
    },
  ],
  failures: [
    {
      response: {
        'code': 50001,
        'folioNumber': null,
        'messageDescription': 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
        'serviceErrorMessage': '',
        'message': 'Error',
      },
    },
  ],
};
