import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import 'firebase/firestore';

export type loginStepType = 'login' | 'loginIdentityCard' | 'waitingIdentityCard' |
  'waiting' | 'errorValidation' | 'errorService' | 'ok';

@Component({
  selector: 'app-error-identity-validation',
  templateUrl: './error-identity-validation.component.html',
  styleUrls: ['./error-identity-validation.component.scss']
})

export class ErrorIdentityValidationComponent implements OnInit {
  @Input() public userType: string;
  @Input() public errorCode: string;
  @Output() public status = new EventEmitter();
  public errorDescription: string;

  constructor(
  ) {}

  public async ngOnInit() {
    this.getErrorDescription();
  }

  private getErrorDescription() {
    // this.errorDescription = BIODESKTOP_ERRORS[this.errorCode];
    this.errorDescription = this.errorCode.replace('Error: ', '');
  }

  public goBack() {
    if (this.userType === 'client') this.goToClientValidation();
    if (this.userType === 'executive') this.goToExecutiveValidation();
  }

  private goToClientValidation() {
    this.status.emit('clientValidation');
  }

  private goToExecutiveValidation() {
    this.status.emit('executiveValidation');
  }
}
