import { Mockup } from '@interfaces/mockup.interface';

export const VALIDATE_EMAIL_DOMAIN_MOCKUP: Mockup = {
  success: [{
    response:
    {
      isValidDomain: true,
      errorMessage: [],
      invalidEmailList: []
    }
  }],
  failures: [{
    response: {
      statusCode: 500,
      message: 'Error',
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.'
    }
  }],
};
