import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-status-card',
  templateUrl: './status-card.component.html',
  styleUrls: ['./status-card.component.scss']
})

export class BusinessTubeStatusCardComponent {
  @Input() public statusType: string;
  @Input() public clientsLength: number;

  constructor() { }
}
