import { Mockup } from '@interfaces/mockup.interface';

export const EMPLOYEE_TYPES_MOCKUP: Mockup = {
  success: [{
    response: [
      {
        code: '1',
        description: 'Dependiente antiguo'
      },
      {
        code: '2',
        description: 'Dependiente nuevo'
      },
      {
        code: '3',
        description: 'Independiente'
      },
      {
        code: '4',
        description: 'Pensionado cotizante'
      },
      {
        code: '5',
        description: 'Pensionado no cotizante'
      },
      {
        code: '6',
        description: 'Afiliado Voluntario'
      },
      {
        code: '7',
        description: 'Cesante'
      }
    ]
  }],
  failures: [],
};
