import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-switch-button',
  templateUrl: './switch-button.component.html',
  styleUrls: ['./switch-button.component.scss'],
})
export class SwitchButtonComponent {
  @Input() public firstSegment: string;
  @Input() public secondSegment: string;
  @Input() public selectedSegment: number;
  @Output() public segmentClicked: EventEmitter<number>;

  constructor() {
    this.segmentClicked = new EventEmitter();
  }

  public selectSegment(option: number) {
    this.segmentClicked.emit(option);
  }

}
