import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ACTIVE_SECURITY_PASSWORD_MESSAGE,
  cancelContractError,
  FUN_CODES,
  FUN_MODULES,
  INACTIVE_SECURITY_PASSWORD_MESSAGE,
  ORIGIN_CODES,
  SECURITY_KEY_DOESNT_EXIST_CODE,
  SEND_CONTRACT_URL
} from '@constants';
import { ClientDataResponse } from '@interfaces/clientData.interface';
import { LoadingProvider } from '@providers/loading/loading';
import { ModalProvider } from '@providers/modal/modal';
import { InactivityService } from '@services/inactivityTime/inactivityTime.service';
import { PostVentaService } from '@services/post-venta/post-venta.service';
import { Util } from '@util';
import { finalize } from 'rxjs/operators';
import { CLIENT_DATA } from 'util/storage.constants';

@Component({
  selector: 'app-send-security-password',
  templateUrl: './send-security-password.component.html',
  styleUrls: ['./send-security-password.component.scss']
})
export class SendSecurityPasswordComponent implements OnInit {
  public activeSecurityPassword = true;
  public clientData: ClientDataResponse;
  public activePasswordMessage = ACTIVE_SECURITY_PASSWORD_MESSAGE;
  public inactivePasswordMessage = INACTIVE_SECURITY_PASSWORD_MESSAGE;
  public selectedOption: string;
  public sendOption = 'send';
  public cancelOption = 'cancel';
  public executiveInfo: string;

  constructor(
    private loadingProvider: LoadingProvider,
    private location: Location,
    private modalProvider: ModalProvider,
    private postVentaService: PostVentaService,
    private router: Router,
    public util: Util,
    private inactivityService: InactivityService
  ) { }

  public ngOnInit() {
    this.inactivityService.startInactivityPostVentaService();
    const fromPage = localStorage.getItem('originAffiliate');
    this.clientData = JSON.parse(localStorage.getItem(CLIENT_DATA));
    if (fromPage === 'sacuPassword') return this.goBack();
    this.setActiveSecurityPassword();
    this.setOriginAffiliate();
  }

  public goBack() {
    this.location.back();
  }

  public select(option: string): void {
    if ((this.activeSecurityPassword && option === this.sendOption) || (!this.activeSecurityPassword && option === this.cancelOption) ||
      (option !== this.sendOption && option !== this.cancelOption)) return;
    this.selectedOption = option;
  }

  public isSelected(option: string): boolean {
    return this.selectedOption === option;
  }

  public send() {
    this.selectedOption === this.cancelOption ? this.openCancelModal() : this.goToSendContract();
  }

  private goToSendContract() {
    this.router.navigateByUrl(SEND_CONTRACT_URL);
  }

  private setOriginAffiliate() {
    localStorage.setItem('originAffiliate', 'sacuPassword');
    localStorage.setItem('sacuPassword', 'true');
  }

  private setActiveSecurityPassword() {
    const securityKeyStatus = localStorage.getItem('securityKeyStatus');
    if (securityKeyStatus === SECURITY_KEY_DOESNT_EXIST_CODE) this.activeSecurityPassword = false;
  }

  private openCancelModal() {
    this.modalProvider.openEndContractModal(this.clientData.name)
      .afterClosed()
      .subscribe((response) => {
        if (!response) return;
        this.cancelContract();
      });
  }

  private cancelContract() {
    this.executiveInfo = localStorage.getItem('infoExecutive');
    this.loadingProvider.showLoading();
    this.postVentaService.cancelContract(this.clientData.rut)
      .pipe(
        finalize(() => {
          this.loadingProvider.hideLoading();
        }))
      .subscribe(() => {
        this.handleSuccessCancelContract();
      }, error => this.handleServiceError(error));
  }

  private handleSuccessCancelContract() {
    this.postVentaService.sendAttentionCode(this.clientData.rut, ORIGIN_CODES.BTABLET, this.executiveInfo,
      FUN_CODES.CLAVESEGURIDAD, FUN_MODULES.REVOCARCONTRATO, 1).subscribe(() => { }, () => { });
    this.goBack();
  }

  private handleServiceError(error) {
    error = error.error || error;
    if (!error.code) {
      error = cancelContractError;
    }
    this.modalProvider.openGenericRetryErrorModal(error);
  }
}
