import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Util } from '@util';
import { BusinessTubeComponent } from '../business-tube.component';
import { ClientBusinessTube } from '@interfaces/clientsBusinessTube.interface';
import { PostVentaService } from '@services/post-venta/post-venta.service';
import { finalize } from 'rxjs/operators';
import { BUSINESS_TUBE_ERRORS } from '@constants';
import { BusinessTubeConfirmationComponent } from '@components/business-tube-components/confirmation/confirmation.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss'],
})
export class BusinessTubeClientDetailComponent {
  @Input() public clientSelected: ClientBusinessTube;
  @Output() public actionRoute = new EventEmitter();
  @Output() public reloadClients = new EventEmitter();
  public serviceError = '';
  public loading = false;
  private innerWidth: number;

  constructor(
    public util: Util,
    public businessTubeComponent: BusinessTubeComponent,
    private postVentaService: PostVentaService,
    private matDialog: MatDialog,
  ) {
    this.innerWidth = window.innerWidth;
  }

  get isClosedClient(): boolean {
    return this.clientSelected.status === 'closed';
  }

  public getBusinessExemptPaymentDescription(businessRut: string, businessExempt: boolean): string {
    return this.businessTubeComponent.getBusinessExemptPaymentDescription(businessRut, businessExempt);
  }

  public confirmationUpdateArchiveStatus() {
    let isBiodesktop = false;
    const { archived } = this.clientSelected;

    if (this.innerWidth > 420) isBiodesktop = true;

    this.matDialog.open(BusinessTubeConfirmationComponent, {
      maxWidth: '95vw',
      width: isBiodesktop ? '50vw' : '95vw',
      height: isBiodesktop ? '50vh' : '99vh',
      data: { archiveFunctionality: true, archiving: !Boolean(archived) }
    }).afterClosed().subscribe((response) => {
      if (response) this.updateClientArchiveStatus(!Boolean(archived));
    });
  }

  private updateClientArchiveStatus(archived: boolean) {
    this.loading = true;
    const request = {
      ...this.clientSelected,
      archived
    } as ClientBusinessTube;
    this.postVentaService.updateClientArchiveStatus(request).pipe(
      finalize(() => this.loading = false))
      .subscribe((client) => {
        this.clientSelected.archived = client.archived;
        this.reloadClients.emit();
      },
        () => this.serviceError = BUSINESS_TUBE_ERRORS.UPDATE);
  }

  public cancel() {
    this.actionRoute.emit('cancel-details');
  }

  public edit() {
    this.actionRoute.emit('edit');
  }
}
