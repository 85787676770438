// tslint:disable: ban-types
import { Component } from '@angular/core';
import { ModalComponent } from '@components/modal/modal.component';

@Component({
  selector: 'app-contact-modal',
  templateUrl: './modal-contact.component.html',
  styleUrls: ['./modal-contact.component.scss'],
})
export class ModalContactComponent extends ModalComponent {
  public conditionArray: string[] = [
    'Tener cédula de identidad vigente.',
    'No tener su cuenta obligatoria en PlanVital.',
    'Tener saldo en su cuenta obligatoria.',
    'No estar en proceso de traspaso a otra AFP.',
    'Tener mínimo 24 meses de afiliación a sistema previsional.',
    'No estar en proceso de pensión.',
    'No estar en proceso de distribución de fondos.',
    'No estar pensionado por aseguradora.',
  ];
}
