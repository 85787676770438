import { Mockup } from '@interfaces/mockup.interface';

export const PV_ACCOUNTS_MOCKUP: Mockup = {
  success: [{
    response: [
      {
        description: 'Cuenta 2',
        funds: ['D', 'E'],
        type: 'CAV'
      },
      {
        description: 'Cuenta APV',
        funds: ['C'],
        type: 'CCV'
      },
      {
        description: 'Depósitos Convenidos',
        funds: ['A', 'B'],
        type: 'CDC'
      }
    ]
  },
    {
      response: []
    }
  ],
  failures: [
    {
      response: {
        code: '401',
        message: '',
        messageDescription: '',
        extraData: {
          message: 'UNAUTHORIZED',
          serviceCode: '1',
          descriptionError: 'Error'
        }
      }
    }, {
      response: {
        errorMessage: 'Service returned an error.',
        stack: '',
        path: '/client/accounts/',
      }
    }
  ],
};
