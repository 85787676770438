// tslint:disable: ban-types
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AUTHORIZED_PHONES } from '@constants';
import { ModalData } from '@interfaces/modalData.interface';

@Component({
  selector: 'app-modal-authorized-phones',
  templateUrl: './modal-authorized-phones.component.html',
  styleUrls: ['./modal-authorized-phones.component.scss'],
})
export class AuthorizedPhonesModalComponent {
  public phones = AUTHORIZED_PHONES;

  constructor(
    public dialogRef: MatDialogRef<AuthorizedPhonesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
  ) {
  }

  public dialogClose(): void {
    this.dialogRef.close();
  }
}
