import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UnassistedComponent } from './unassisted/unassisted.component';
import { AssistedComponent } from './assisted/assisted.component';
import { PlatformGuardService } from '@services/platform-guard/platform-guard.service';
import { ContactComponent } from './contact/contact.component';
import { OnlineComponent } from './online/online.component';
import { ContactSuccessfulComponent } from './contact-successful/contact-successful.component';
import { QuestionsComponent } from './questions/questions.component';
import { ExecutiveComponent } from './executive/executive.component';
import { PostVentaComponent } from './post-venta/post-venta.component';
import { ContinueComponent } from './continue/continue.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { BioDesktopComponent } from './biodesktop/biodesktop.component';
import { AccountsComponent } from './accounts/accounts.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [PlatformGuardService],
    component: UnassistedComponent,
  },
  {
    path: 'contactar',
    component: ContactComponent,
    pathMatch: 'full',
  },
  {
    path: 'contacto-exitoso',
    component: ContactSuccessfulComponent,
    pathMatch: 'full',
  },
  {
    path: 'ingresar',
    canActivate: [PlatformGuardService],
    component: OnlineComponent,
    pathMatch: 'full',
  },
  {
    path: 'continuar',
    component: ContinueComponent,
    pathMatch: 'full',
  },
  {
    path: 'continuar/apertura',
    component: ContinueComponent,
    pathMatch: 'full',
  },
  {
    path: 'continuar/confirmar',
    component: ConfirmComponent,
    pathMatch: 'full',
  },
  {
    path: 'ejecutivo',
    canActivate: [PlatformGuardService],
    component: ExecutiveComponent,
  },
  {
    path: 'devices',
    canActivate: [PlatformGuardService],
    component: ExecutiveComponent,
  },
  {
    path: 'ingreso-post-venta',
    canActivate: [PlatformGuardService],
    component: PostVentaComponent,
  },
  {
    path: 'ingreso-asistido',
    canActivate: [PlatformGuardService],
    component: AssistedComponent,
  },
  {
    path: 'validacion-biometrica',
    // canActivate: [PlatformGuardService],
    component: BioDesktopComponent,
  },
  {
    path: 'ingresar/preguntas',
    component: QuestionsComponent,
    pathMatch: 'full',
  },
  {
    path: 'ingresar/accounts',
    component: AccountsComponent,
    canActivate: [PlatformGuardService],
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule { }
