import { Mockup } from '@interfaces/mockup.interface';

export const OT_EXEMPT_PAYMENT_MOCKUP: Mockup = {
  success: [{
    response: {
      business: ['19', '20'],
      clients: ['21', '22'],
    }
  }],
  failures: [{
    response: {
      code: 500,
      message: 'Error',
      messageDescription: 'Error en el servicio',
    }
  }]
};
