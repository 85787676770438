import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-card-person',
  templateUrl: './info-card-person.component.html',
  styleUrls: ['./info-card-person.component.scss']
})
export class InfoCardPersonComponent {
  @Input() public title: string;
  @Input() public typeCard: string;
  @Input() public info: string[];
  @Input() public infoIcons: string[];

  constructor() { }

}
