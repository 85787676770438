import { Component, Input } from '@angular/core';
import { PortfolioComplain } from '@interfaces/portfolioComplain.interface';
import { Util } from '@util';

@Component({
  selector: 'app-complain-ticket-card',
  templateUrl: './complain-ticket-card.component.html',
  styleUrls: ['./complain-ticket-card.component.scss']
})
export class ComplainTicketCardComponent {
  @Input() public complain: PortfolioComplain;

  constructor(
    public util: Util,
  ) { }

  public statusClass(status: string): string {
    if (typeof status === 'string' && status.toUpperCase() === 'PENDIENTE') {
      return 'pending';
    }
    return '';
  }
}
