import { Component, Input } from '@angular/core';
import { ActionButton } from '@interfaces/actionButton.interface';

@Component({
  selector: 'app-list-redirection-buttons',
  templateUrl: './list-redirection-buttons.component.html',
  styleUrls: ['./list-redirection-buttons.component.scss']
})
export class ListRedirectionButtonsComponent {
  @Input() public buttons: ActionButton[];

  constructor() { }

}
