import { Component, Input, OnChanges } from '@angular/core';
import { MONTH_DICT } from '@constants';
import { MONTH_DICT_PORTFOLIO } from 'util/portfolio.constants';

const MONTH_IN_YEAR = 12;
@Component({
  selector: 'app-portfolio-month',
  templateUrl: './portfolio-month.component.html',
  styleUrls: ['./portfolio-month.component.scss']
})

export class PortfolioMonthComponent implements OnChanges {
  @Input() public number: number;
  @Input() public title: string;
  @Input() public info: number;
  public month: string;
  public year: number;

  constructor() { }

  public get iconName() {
    return MONTH_DICT_PORTFOLIO[this.number];
  }

  public get isMonthActive() {
    return Boolean(this.title);
  }

  public ngOnChanges() {
    const date = new Date();
    const currentMonth = date.getMonth() + 1;
    const currentYear = date.getFullYear();
    [this.month, this.year] = this.getMonthAndYear(currentMonth - (this.number - 1), currentYear);
  }

    private getMonthAndYear(month: number, currentYear: number): [string, number] {
    if (month > 0) return [MONTH_DICT[month], currentYear];
    if (month <= 0 && month > MONTH_IN_YEAR * -1) return [MONTH_DICT[MONTH_IN_YEAR + month], currentYear - 1];
    return [MONTH_DICT[MONTH_IN_YEAR * 2 + month], currentYear - 2];
  }
}
