import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SideMenuItem } from '@interfaces/sideMenuItem.interface';

@Component({
  selector: 'app-side-nav-menu',
  templateUrl: './side-nav-menu.component.html',
  styleUrls: ['./side-nav-menu.component.scss']
})
export class SideNavMenuComponent implements OnInit {
  public opened = false;
  @Output() public actionBack = new EventEmitter();
  @Output() public actionRoute = new EventEmitter();
  @Input() public items: SideMenuItem[];
  @Input() public showSearcher: boolean;

  constructor() { }

  public ngOnInit() { }

  public routeTo(route: any) {
    this.opened = false;
    this.actionRoute.emit(route);
  }

  public back() {
    this.actionBack.emit();
  }
}
