import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MyPortfolioComponent } from 'app/modules/post-venta/my-portfolio/my-portfolio.component';
import { FunctionalitySelectionComponent } from './functionality-selection/functionality-selection.component';
import { SearchAffiliateComponent } from './search-affiliate/search-affiliate.component';
import { AffiliateComponent } from './affiliate/affiliate.component';
import { SendPasswordComponent } from './send-password/send-password.component';
import { SendSecurityPasswordComponent } from './send-security-password/send-security-password.component';
import { SendContractComponent } from './send-contract/send-contract.component';
import { QuoteCertificateComponent } from './quote-certificate/quote-certificate.component';
import { ProductOpeningComponent } from './product-opening/product-opening.component';
import { InvestorProfileBoxComponent } from './investor-profile-box/investor-profile-box.component';
import { BusinessTubeComponent } from './business-tube/business-tube.component';
import { CertificatesVouchersComponent } from './certificates-vouchers/certificates-vouchers.component';
import { PostVentaGuardService } from '@services/post-venta-guard/post-venta-guard.service';

const routes: Routes = [
  {
    path: 'post-venta',
    component: FunctionalitySelectionComponent,
    canActivate: [PostVentaGuardService]
  },
  {
    path: 'buscar-afiliado',
    component: SearchAffiliateComponent,
    canActivate: [PostVentaGuardService]

  },
  {
    path: 'afiliado',
    component: AffiliateComponent,
    canActivate: [PostVentaGuardService]

  },
  {
    path: 'enviar-clave',
    component: SendPasswordComponent,
    canActivate: [PostVentaGuardService]

  },
  {
    path: 'enviar-clave-seguridad',
    component: SendSecurityPasswordComponent,
    canActivate: [PostVentaGuardService]

  },
  {
    path: 'enviar-contrato',
    component: SendContractComponent,
    canActivate: [PostVentaGuardService]

  },
  {
    path: 'seleccionar-periodo',
    component: QuoteCertificateComponent,
    canActivate: [PostVentaGuardService]

  },
  {
    path: 'apertura-productos',
    component: ProductOpeningComponent,
    canActivate: [PostVentaGuardService]

  },
  {
    path: 'mi-agenda',
    component: MyPortfolioComponent,
    canActivate: [PostVentaGuardService]
  },
  {
    path: 'investor-profile',
    component: InvestorProfileBoxComponent,
    canActivate: [PostVentaGuardService]
  },
  {
    path: 'tubo-negocios',
    component: BusinessTubeComponent,
    canActivate: [PostVentaGuardService]

  },
  {
    path: 'certificates-vouchers',
    component: CertificatesVouchersComponent,
    canActivate: [PostVentaGuardService]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
})
export class PostVentaRoutingModule { }
