export const GET_CAV_DISCOUNT_FORM_MOCK = {
  success: [{
    response: {
      'regimes': [
        {
          'description': 'GENERAL',
          'code': 'A',
        },
        {
          'description': '54BIS',
          'code': 'D',
        },
      ],
      'countries': [
        {
          'code': 'AT',
          'name': 'AUSTRIA',
        },
        {
          'code': 'AU',
          'name': 'AUSTRALIA',
        },
        {
          'code': 'BE',
          'name': 'BELGICA',
        },
        {
          'code': 'BR',
          'name': 'BRAZIL',
        },
        {
          'code': 'BS',
          'name': 'BAHAMAS',
        },
        {
          'code': 'CA',
          'name': 'CANADA',
        },
        {
          'code': 'CL',
          'name': 'CHILE',
        },
        {
          'code': 'CM',
          'name': 'CAMERUN',
        },
        {
          'code': 'CN',
          'name': 'CHINA',
        },
        {
          'code': 'CO',
          'name': 'COLOMBIA',
        },
        {
          'code': 'CR',
          'name': 'COSTA RICA',
        },
        {
          'code': 'CU',
          'name': 'CUBA',
        },
      ],
      'inputTypes': {
        'P': 'Pesos',
        'U': 'UF',
        'R': '% RIM',
      },
      'funds': [
        {
          'description': 'FONDO A',
          'type': 'A',
        },
        {
          'description': 'FONDO B',
          'type': 'B',
        },
        {
          'description': 'FONDO C',
          'type': 'C',
        },
        {
          'description': 'FONDO D',
          'type': 'D',
        },
        {
          'description': 'FONDO E',
          'type': 'E',
        },
      ],
      'contractsValidity': [
        'Temporal',
        'Indefinido',
      ],
      'incomeSources': [
        {
          'description': 'Ingresos Trabajador',
          'code': 'I',
        },
        {
          'description': 'Herencias',
          'code': 'H',
        },
        {
          'description': 'Premios',
          'code': 'P',
        },
        {
          'description': 'Venta de activos',
          'code': 'V',
        },
        {
          'description': 'Otros (Detallar)',
          'code': 'O',
        },
      ],
    },
  }],
  failures: [{
    response: {
      'code': 50001,
      'folioNumber': null,
      'messageDescription': 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
      'serviceErrorMessage': '',
      'message': 'Error',
    },
  }],
};
