import { Mockup } from '@interfaces/mockup.interface';

export const SECURITY_QUESTIONS_MOCKUP: Mockup = {
  success: [{
    response: {
      questions: [
        {
          id: 1,
          description: '¿En cuál de las siguientes comunas vivió Usted el año 2012?',
          answers: [
            {
              id: 1,
              description: 'LA GRANJA'
            },
            {
              id: 2,
              description: 'PUYEHUE'
            },
            {
              id: 3,
              description: 'SANTIAGO'
            },
            {
              id: 4,
              description: 'YUMBEL'
            },
            {
              id: 5,
              description: 'Ninguna de las anteriores'
            }
          ]
        },
        {
          id: 2,
          description: '¿De qué marca era el auto que tuvo Usted en el año 2012?',
          answers: [
            {
              id: 1,
              description: 'FIAT'
            },
            {
              id: 2,
              description: 'NISSAN'
            },
            {
              id: 3,
              description: 'PEUGEOT'
            },
            {
              id: 4,
              description: 'SAMSUNG'
            },
            {
              id: 5,
              description: 'Ninguna de las anteriores'
            }
          ]
        },
        {
          id: 3,
          description: '¿En cuál de las siguientes sociedades tiene o tuvo participación?',
          answers: [
            {
              id: 1,
              description: 'ACEROS PARGUA STEEL LIMITADA'
            },
            {
              id: 2,
              description: 'CAR S A'
            },
            {
              id: 3,
              description: 'SCHLEYER Y CIA LIMITADA'
            },
            {
              id: 4,
              description: 'SOC DE FERTILIZANTES NATURALES LLULL Y KOUZNETSOV LIMITADA'
            },
            {
              id: 5,
              description: 'Ninguna de las anteriores'
            }
          ]
        },
        {
          id: 4,
          description: '¿Cuál de los siguientes números de teléfono es o ha sido suyo?',
          answers: [
            {
              id: 1,
              description: '79228300'
            },
            {
              id: 2,
              description: '79249658'
            },
            {
              id: 3,
              description: '79278829'
            },
            {
              id: 4,
              description: '79289436'
            },
            {
              id: 5,
              description: 'Ninguna de las anteriores'
            }
          ]
        }
      ],
      transactionId: 'c8f24bc5-339f-4aa1-8084-d3896e9351c4',
      interactiveQuestionId: 12
    },
  }],
  failures: [
    {
      response: {
        code: 422,
        errorMessage: 'The request could not be understood',
        extraData: [{
          code: '3021',
          description: 'Usuario bloqueado',
        }],
        statusCode: 400
      }
    },
    {
    response: {
      code: 50001,
      message: 'Error',
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
    },
  }, {
    response: {
      code: 40081,
      message: 'Hubo un error búscando las preguntas de seguridad.',
      messageDescription: '',
    }
  },
  {
    response: {
      code: 40117,
      message: 'Error',
      messageDescription: 'Error al obtener preguntas.',
    }
  },
  {
    response: {
      code: 40118,
      message: 'Error',
      messageDescription: 'Rut invalido.',
    }
  },
  {
    response: {
      code: 40124,
      message: 'Error',
      messageDescription: 'Número de serie inválido.',
    }
  }]
};

export const SECURITY_QUESTIONS_LOGIN_MOCKUP: Mockup = {
  success: [{
    response: {
      token: 'z4xe5cr6vt7by8nu9mi0'
    },
  }],
  failures: [
    {
      response: {
        code: 40119,
        errorMessage: 'The request could not be understood',
        extraData: null,
        message: 'Respuestas incorrectas',
        messageDescription: 'No has acertado correctamente a las preguntas, por favor revisa tus respuestas y vuelve a intentarlo.',
        path: '/authentication/security-questions/',
        statusCode: 400,
      }
    },
    {
      response: {
        code: 50001,
        message: 'Error',
        messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
      }
    }
  ]
};
