import { Mockup } from '@interfaces/mockup.interface';

export const CLIENTS_BUSINESS_TUBE_MOCKUP: Mockup = {
  success: [{
    response: {
      prospect: [
        {
          clientRut: '123456789-0',
          executiveRut: '987654321-0',
          phone: 1234567890,
          comments: 'Prospect comment',
          creationDate: '21-07-2023',
          modificationDate: '21-07-2023',
          name: 'Monkey D. Luffy',
          status: 'prospect',
          exemptPayment: { client: false, business: false }
        },
        {
          clientRut: '987654321-0',
          executiveRut: '123456789-0',
          phone: 9876543210,
          comments: 'Prospect comment',
          creationDate: '19-05-2023',
          modificationDate: '19-05-2023',
          name: 'Roronoa Zoro',
          status: 'prospect',
          exemptPayment: { client: false, business: false }
        }
      ],
      contacted: [
        {
          clientRut: '111111111-0',
          executiveRut: '222222222-0',
          phone: 1111111111,
          comments: 'Contacted comment',
          creationDate: '19-06-2023',
          modificationDate: '19-06-2023',
          name: 'Nami',
          status: 'contacted',
          exemptPayment: { client: false, business: false }
        },
        {
          clientRut: '222222222-0',
          executiveRut: '111111111-0',
          phone: 2222222222,
          comments: 'Contacted comment',
          creationDate: '19-05-2023',
          modificationDate: '19-05-2023',
          name: 'Usopp',
          status: 'contacted',
          exemptPayment: { client: false, business: false }
        }
      ],
      interviewed: [
        {
          clientRut: '333333333-0',
          executiveRut: '444444444-0',
          phone: 3333333333,
          comments: 'Interviewed comment',
          creationDate: '19-05-2023',
          modificationDate: '19-05-2023',
          name: 'Sanji',
          status: 'interviewed',
          exemptPayment: { client: false, business: false }
        },
        {
          clientRut: '1-0',
          executiveRut: '987654321-0',
          phone: 1234567890,
          comments: 'Interviewed comment',
          creationDate: '21-03-2023',
          modificationDate: '19-08-2023',
          name: 'Monkey D. Dragon',
          status: 'interviewed',
          exemptPayment: { client: false, business: false }
        },
        {
          clientRut: '444444444-0',
          executiveRut: '333333333-0',
          phone: 4444444444,
          comments: 'Interviewed comment',
          creationDate: '19-06-2023',
          modificationDate: '19-06-2023',
          name: 'Tony Tony Chopper',
          status: 'interviewed',
          exemptPayment: { client: false, business: false }
        }
      ],
      closed: [
        {
          clientRut: '555555555-0',
          executiveRut: '666666666-0',
          phone: 5555555555,
          comments: 'Closed comment',
          creationDate: '31-05-2023',
          modificationDate: '19-05-2023',
          name: 'Nico Robin',
          status: 'closed',
          exemptPayment: { client: false, business: false }
        },
        {
          clientRut: '666666666-0',
          executiveRut: '555555555-0',
          phone: 6666666666,
          comments: 'Closed comment',
          creationDate: '19-07-2023',
          modificationDate: '19-07-2023',
          name: 'Franky',
          status: 'closed',
          exemptPayment: { client: false, business: false }
        },
        {
          clientRut: '1-1',
          executiveRut: '987654321-0',
          phone: 1234567890,
          comments: 'Closed comment',
          creationDate: '21-03-2023',
          modificationDate: '19-08-2023',
          name: 'Monkey D. Garp',
          status: 'closed',
          exemptPayment: { client: false, business: false }
        },
      ]
    }
  }],
  failures: [{
    response: {
      code: 500,
      message: 'Error',
      messageDescription: 'Error en el servicio',
    }
  }]
};
