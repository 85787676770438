import { ValidatorFn } from '@angular/forms';
import {
  MAX_RIM, MIN_PESOS, MIN_RIM, MIN_UF, PESOS_INPUT_CODE, regexNumbersAndCommas, regexOnlyNumbers, RIM_INPUT_CODE,
  UF_INPUT_CODE
} from '@constants';

export function amountValidator(type: 'R' | 'P' | 'U'): ValidatorFn {
  return (control) => {
    let amount = control.value as string;

    if (!amount) {
      return {insufficientAmount: true};
    }

    if (type === UF_INPUT_CODE) {
      amount = amount.replace(regexNumbersAndCommas, '');
      amount = amount.replace(',', '.');
    } else {
      amount = amount.replace(regexOnlyNumbers, '');
    }
    const error = {};
    if ((type === UF_INPUT_CODE && Number(amount) < MIN_UF) || (type === PESOS_INPUT_CODE && Number(amount) < MIN_PESOS) ||
        (type === RIM_INPUT_CODE && (Number(amount) < MIN_RIM || Number(amount) > MAX_RIM))) {
      error['insufficientAmount'] = true;
    }
    return (Object.keys(error).length) ? error : null;
  };
}
