import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class CommunicationProvider {
  private requestInterceptedSource: Subject<number> = new Subject<number>();
  public requestIntercepted: Observable<number> = this.requestInterceptedSource.asObservable();

  constructor() { }

  public getInterceptedSource(): Subject<number> {
    return this.requestInterceptedSource;
  }
}
