import { Mockup } from '@interfaces/mockup.interface';

export const SACU_AUTHENTICATION_MOCKUP: Mockup = {
  success: [{
      response: {
        token: 'z4xe5cr6vt7by8nu9mi0',
        name: 'Valentina'
      },
    }
  ],
  failures: [{
    response: {
      code: 40101,
      message: 'Error de autenticación.',
      messageDescription: 'Hubo un error realizando request de autenticación.',
      extraData: {
        serviceError: 'Error de autenticación.'
      },
    },
  }, {
    response: {
      code: 40102,
      message: 'Clave autenticación incorrecta.',
      messageDescription: 'La clave ingresada es incorrecta.',
      extraData: {
        serviceError: 'Clave autenticación incorrecta.'
      },
    },
  }],
};


export const MESSAGING_AUTHENTICATION_MOCKUP: Mockup = {
  success: [
    {
      response: {
        success: true,
        error: null
      }
    }, {
      response: {
        success: false,
        error: 'DUPLICATED_TICKET',
      }
    }
  ],
  failures: [{
    response: { },
  }],
};
