import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Injectable({
  providedIn: 'root'
})
export class GtmService {

  private basePath = '/traspaso';

  private pathGTM = {
    contact: '/contactar',
    succesfulContact: '/contactar/exitoso'
  };

  constructor( private googleTagManagerService: GoogleTagManagerService) { }

  public addTag( page: gtmPages  ) {
    this.googleTagManagerService.pushTag( {event: 'page', pageName: `${this.basePath + this.pathGTM[page]}` } );
  }
}

export type gtmPages = 'contact' | 'contactSuccesful';
