import { Mockup } from '@interfaces/mockup.interface';

export const BIOMETRIC_AUTHENTICATION_MOCKUP: Mockup = {
  success: [
    {
      response: {
        token: 'j8jmd485nfiw'
      },
    }
  ],
  failures: [{
    response: {
      messageError: {
        statusCode: 400,
        error: 'Bad Request',
        message: []
      },
      path: '/authentication/biometric',
    },
  }, {
    response: {
      errorMessage: 'Previred returns an error',
      stack: '{\"statusCode\":403,\"message\":\"\",\"errors\":[{\"errorCode\":1003,\"message\":\"afiliado no existe\"}]}',
      path: '/authentication/biometric',
    }
  }],
};
