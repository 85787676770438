import { Mockup } from '@interfaces/mockup.interface';

export const OPENING_CAV_MOCK: Mockup = {
  success: [{
    response: {
      folio: 1000053
    }
  }],
  failures: [{
    response: {
      code: 40106,
    }
  }]
};
