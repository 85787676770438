/* tslint:disable:no-magic-numbers */
export const EARNED_PROFITABILITY_MOCKUP = {
  success: [
    {
      response: {
        customerContribution: 10000,
        endMonth: '11-2011',
        balanceEndMonth: 53405,
        withdrawalAmount: 4255,
        profit: 52523,
      },
    },
  ],
  failures: [
    {
      response: {
        'code': 500,
        'folioNumber': null,
        'messageDescription': 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
        'serviceErrorMessage': '',
        'message': 'Error',
      },
    },
  ],
};
