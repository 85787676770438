import { catchError } from 'rxjs/operators';
import { CONTACT_DATA_MOCKUP } from '@mocks/contactData.mock';
import { ContactData } from '@interfaces/contactData.interface';
import { environment } from '@env';
import { HttpClientInterceptor } from '@providers/httpClientInterceptor/httpClientInterceptor';
import { Injectable } from '@angular/core';
import { Util } from '@util';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  public baseURL: string;

  constructor(
    public http: HttpClientInterceptor,
    public util: Util,
  ) {
    this.baseURL = environment.baseURL;
  }

  public sendData(contactData: ContactData) {
    if (contactData.cellphoneNumber) contactData.cellphoneNumber = Number(contactData.cellphoneNumber);
    return this.http.post(`${this.baseURL}contacts/`, contactData, CONTACT_DATA_MOCKUP).pipe(
      catchError(error => this.util.generalCatchError(error))
    );
  }

  public verifyAffiliateByRUT(rut: number): Observable<any> {
    return this.http.get(`${environment.clientServiceURL}terminal-illness/verify-affiliate/${rut}`, CONTACT_DATA_MOCKUP).pipe(
        catchError(error => this.util.generalCatchError(error))
    );
  }
}
