import { Pipe, PipeTransform } from '@angular/core';
import { formatIncompletePhoneNumber, CountryCode } from 'libphonenumber-js/min';

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {

  public transform(phoneValue: number | string, country: string): any {
    try {
      return formatIncompletePhoneNumber(phoneValue + '', country as CountryCode);
    } catch (error) {
      return phoneValue;
    }
  }

}
