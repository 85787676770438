export class CampaignData {
  public rut: string;
  public cellphoneNumber: string;
  public email: string;

  constructor(response: CampaignUrlData) {
    const { rut, email, cellphone } = response;
    this.rut = rut;
    this.email = email;
    this.cellphoneNumber = cellphone;
  }
}

export interface CampaignUrlData {
  rut: string;
  cellphone: string;
  email: string;
  utm_campaign?: string;
  utm_id?: string;
  utm_medium?: string;
  utm_source?: string;
  gad_source?: string;
  gclid?: string;
  campana?: string;
  tipo?: string;
}
