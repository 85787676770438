import { Mockup } from '@interfaces/mockup.interface';

export const LOG_MOCKUP: Mockup = {
  success: [{
    response: 'Created',
  }],
  failures: [
    {
      response: {}
    }
  ]
};
