import { Component, ElementRef, ViewChild, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CHILEAN_CELLPHONE_PATTERN,
  CONTACT_SUCCESSFUL_URL, EMAIL_PATTERN,
  ERROR_MESSAGES_CONTACT_FORM,
  ERROR_MESSAGES_FORM, MAX_LENGTH_RUT,
  onServiceError, TAXABLE_INCOME_RANGES
} from '@constants';
import { CampaignData, CampaignUrlData } from '@interfaces/campaignData.interface';
import { Commune, Region } from '@interfaces/region.interface';
import { EmailDomainValidator } from '@providers/emailDomainValidator/emailDomainValidator';
import { FacebookPixelProvider } from '@providers/facebookPixel/facebookPixel';
// import { GoogleAnalyticsProvider } from '@providers/googleAnalytics/googleAnalytics';
import { LoadingProvider } from '@providers/loading/loading';
import { ModalProvider } from '@providers/modal/modal';
import { ContactService } from '@services/contact/contact.service';
import { Util } from '@util';
import {catchError, finalize} from 'rxjs/operators';
import { GtmService } from '../../../services/gtm/gtm.service';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit, OnDestroy {
  public contactForm: FormGroup;
  public regions: Array<Region>;
  public communes: Array<Commune>;
  public minLength = 9;
  public maxLength = 9;
  public maxLengthForRut = MAX_LENGTH_RUT;
  public serviceError: boolean;
  @ViewChild('searchContact')
  public searchElementRef: ElementRef;
  public taxableIncomeRanges = TAXABLE_INCOME_RANGES;
  public rutInputDisabled = false;
  public isExistAffiliate = false;
  public utmQueryData;

  // Page Title
  public titlePageInBold = 'Quiero';
  public titlePageNormal = 'que me llamen';

  // Page Img
  public imgPage = './assets/img/contact-executive.svg';

  constructor(
    private emailDomainValidator: EmailDomainValidator,
    private formBuilder: FormBuilder,
    private contactService: ContactService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalProvider: ModalProvider,
    private loadingProvider: LoadingProvider,
    // private googleAnalyticsProvider: GoogleAnalyticsProvider,
    private facebookPixel: FacebookPixelProvider,
    private gtmService: GtmService,
    public util: Util,
    private renderer: Renderer2
  ) {
    this.contactForm = this.formBuilder.group({
      rut: ['', [Validators.required]],
      cellphoneNumber: ['', [Validators.maxLength(this.maxLength),
        Validators.minLength(this.minLength), Validators.pattern(CHILEAN_CELLPHONE_PATTERN)]],
      email: [undefined, [Validators.email, Validators.pattern(EMAIL_PATTERN)]],
    }, { validators: [this.atLeastOneContactValue] });

    this.serviceError = false;
    // this.googleAnalyticsProvider.registerPageView('contact');
    this.facebookPixel.registerPageView('contact');
    this.gtmService.addTag('contact');
    this.getUrlData();
  }

  public ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'recaptcha');
  }

  public ngOnInit() {
    // setTimeout(() => this.openModal(), 0);
    this.renderer.addClass(document.body, 'recaptcha');
  }

  get rutContact() {
    return this.contactForm.controls['rut'] as FormControl;
  }

  get form() {
    return this.contactForm.controls;
  }

  public removeRutFormat() {
    this.form.rut.setValue(this.util.rutClean(this.form.rut.value));
  }

  public addRutFormat() {
    this.form.rut.setValue(this.util.rutFormat(this.form.rut.value));
  }

  public async validateEmailDomain() {
    const emailControl = this.contactForm.controls['email'];
    const email = this.contactForm.value['email'];
    if (this.invalidEmail('email') || !email) return;
    const validDomain = await this.emailDomainValidator.validateEmailDomain(email);
    if (!validDomain) return emailControl.setErrors({ invalidDomain: true });
  }

  public getErrorMessage(controlName: string) {
    const control = this.form[controlName];

    if (control.hasError('affiliate')) {
      return ERROR_MESSAGES_CONTACT_FORM.affiliate;
    }
    if (control.hasError('required')) {
      return ERROR_MESSAGES_CONTACT_FORM['required'];
    }
    if (control.hasError('invalidRut')) {
      return ERROR_MESSAGES_FORM['invalidRut'];
    }
    if (this.invalidEmail(controlName)) {
      return ERROR_MESSAGES_FORM['invalidEmail'];
    }
    if (this.invalidPhoneFormat(controlName)) {
      return ERROR_MESSAGES_CONTACT_FORM['invalidPhoneFormat'];
    }
    if (this.invalidPhone(controlName)) {
      return ERROR_MESSAGES_FORM['invalidPhone'];
    }
    if (control.hasError('invalidDomain')) {
      return ERROR_MESSAGES_FORM['invalidDomain'];
    }
    return '';
  }

  public verifyAffiliateByRUT(event: any) {
    this.contactService.verifyAffiliateByRUT(event.target.value).pipe(
        // @ts-ignore
        catchError(() => {
           this.isExistAffiliate = false;
         })
     ).subscribe((res) => {
       if (Boolean(res) === true) {
         this.isExistAffiliate = true;
         this.contactForm.controls['rut'].setErrors({
           affiliate: true
         });
       }
     });
  }

  public sendContactData() {
    if (this.contactForm.invalid) {
      return;
    }
    this.loadingProvider.showLoading();
    const contactData = {...this.contactForm.value, ...this.utmQueryData};
    if (!contactData.email) delete contactData.email;
    if (!contactData.cellphoneNumber) delete contactData.cellphoneNumber;
    this.contactService.sendData(contactData)
      .pipe(finalize(() => this.loadingProvider.hideLoading()))
      .subscribe(() => {
          this.router.navigateByUrl(CONTACT_SUCCESSFUL_URL);
        }, error => this.handleError(error)
      );
  }

  public handleError(error) {
    error = error.error || error;

    if (!error.code) {
      error = onServiceError;
    }

    this.modalProvider.openGenericErrorModal(error);
  }

  private invalidEmail(controlName: string) {
    const control = this.form[controlName];
    return control.hasError('email') || (control.hasError('pattern') && controlName === 'email');
  }

  private invalidPhone(controlName: string) {
    const control = this.form[controlName];
    return control.hasError('minlength') || control.hasError('maxlength') ||
      (control.hasError('pattern') && controlName === 'cellphoneNumber');
  }

  private invalidPhoneFormat(controlName: string) {
    const cellphoneNumber = this.form[controlName].value;
    return cellphoneNumber && cellphoneNumber[0] !== '9' && controlName === 'cellphoneNumber';
  }

  private atLeastOneContactValue(form: FormGroup): ValidationErrors {
    return Object.keys(form.value).some(key => {
      return key !== 'rut' && form.value[key];
    }) ?
      null :
      { atLeastOneRequired: 'Al menos uno de los campos es obligatorio.' };
  }

  private getUrlData() {
    this.activatedRoute.queryParams.subscribe((response: CampaignUrlData) => {
      const { utm_campaign, utm_id, utm_medium, utm_source, gclid, campana, tipo} = response;
      this.utmQueryData = {
        utmCampaign: campana ? campana : utm_campaign,
        utmId: utm_id,
        utmMedium: tipo ? tipo : utm_medium,
        utmSource: gclid ? 'Google' : utm_source
      };
      if (!Object.keys(response).length) return;
      const mapedResponse = this.mapUrlResponse(response);
      if (!mapedResponse.cellphoneNumber) delete mapedResponse.cellphoneNumber;
      this.fillWithQueryParams(mapedResponse);
      this.addRutFormat();
    });
  }

  private mapUrlResponse(response: CampaignUrlData): CampaignData {
    const mapedResponse = new CampaignData(response);
    Object.keys(mapedResponse).forEach((key) => {
      if (!mapedResponse[key]) delete mapedResponse[key];
    });
    return mapedResponse;
  }

  private fillWithQueryParams(response: CampaignData) {
    Object.keys(response).forEach((key) => {
      this.fillField(response[key], key);
    });
    this.validateEmailDomain();
  }

  private fillField(value: string, controlName: string) {
    const control = this.form[controlName];
    control.setValue(value);
    control.markAsTouched();
    this.rutInputDisabled = true;
  }

  // private openModal() {
  //   this.modalProvider.openCustomContactModal({
  //     primaryCallback: () => {},
  //     secondaryCallback: () => this.location.back(),
  //   });
  // }
}
