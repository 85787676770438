import { Injectable } from '@angular/core';
import { TimerConfigInterface } from '@interfaces/timerConfig.interface';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class TimerProvider {
  private timerRequestSource: Subject<TimerConfigInterface> = new Subject<TimerConfigInterface>();
  public timerRequestIntercepted: Observable<TimerConfigInterface> = this.timerRequestSource.asObservable();

  constructor() { }

  public getTimerRequestIntercepted() {
    return this.timerRequestIntercepted;
  }

  public stopTimer() {
    return this.timerRequestSource.next({ activeTimer: false, hideTimer: false } as TimerConfigInterface);
  }

  public startTimer() {
    return this.timerRequestSource.next({ activeTimer: true, hideTimer: false } as TimerConfigInterface);
  }

  public hideTimer() {
    return this.timerRequestSource.next({ activeTimer: false, hideTimer: true } as TimerConfigInterface);
  }
}
