import { Injectable } from '@angular/core';
import { environment } from '@env';
import { AutentiaResponse } from '@interfaces/autentiaResponse.interface';
import { LogInterface } from '@interfaces/log.interface';
import { LogService } from '@services/logs/log.service';

declare const plgAutentiaJS;

@Injectable({
  providedIn: 'root'
})
export class AutentiaService {
  public baseURL: string;
  private autentia: any;
  private validated = false;
  private callback = Function.prototype;
  private errCallback = Function.prototype;

  constructor(
    private logService: LogService,
  ) {
    this.baseURL = environment.baseURL;
    this.autentia = new plgAutentiaJS();
  }

  public response = (value: AutentiaResponse) => {
    this.sendLog(value);
    this.validated = value.ParamsGet.ErcDesc === 'Verificacion Exitosa' && !!value.ParamsGet.UrlOTI;
    if (this.validated) return this.callback(value.ParamsGet);
    this.errCallback(value.ParamsGet);
  }

  // tslint:disable-next-line:ban-types
  public async loginFingerprint(rut: string, callback: Function, errCallback: Function) {
    this.callback = callback;
    this.errCallback = errCallback;
    const inputs = {
      Rut: rut.substring(0, rut.length - 1),
      DV: rut.substring(rut.length - 1),
    };

    const outputs = ['Erc', 'ErcDesc', 'NroAudit', 'UrlOTI', 'nombre_af', 'apellido_paterno_af', 'apellido_materno_af',
      'rut_numero_af', 'dv_af'];
    const token = String(Math.random());
    this.autentia.Transaccion2('../AFPPLANVITAL/verificaOTI', inputs, outputs, false, token, this.response);
  }

  private sendLog(logObject: AutentiaResponse, isError: boolean = false) {
    const request = {
      logObject: { ...logObject, rut: logObject.ParamsGet.rut_numero_af + logObject.ParamsGet.dv_af },
      isError,
      customMessage: `[AutentiaService] Biometric authentication Response`,
    } as LogInterface;
    this.logService.sendLog(request);
  }
}
