import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { ModalProvider } from '@providers/modal/modal';
import { Subscription, timer } from 'rxjs';
import {
  DELAY_TIMER,
  FIFTEEN_MINUTES_MESSAGE,
  MINUTE_IN_SECONDS,
  ONLINE_LOGIN_URL,
  SECOND_TIMER,
  timeUpError,
  TIME_QUESTIONS,
  TWO_MINUTES_MESSAGE
} from '@constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})

export class TimerComponent implements OnInit, OnChanges {
  @Input() public totalTime: number;
  @Input() public activeTimer: boolean;
  @Input() public hideTimer: boolean;
  public countDown: Subscription;
  public currentTime: number;
  public totalMin: number;
  public message: string;
  private activeUntil = 4;

  constructor(
    private modalProvider: ModalProvider,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) { }

  public get isActive(): boolean {
    return this.elapsedTime < this.activeUntil;
  }

  public get elapsedTime(): number {
    return this.totalTime - this.currentTime;
  }

  public get shouldPassTime(): boolean {
    return this.currentTime > 0 && this.elapsedTime < this.totalTime;
  }

  private countDownTimer() {
    if (this.countDown) return;
    this.countDown = timer(DELAY_TIMER, SECOND_TIMER).subscribe(() => {
      if (this.shouldPassTime) {
        --this.currentTime;
      }
      this.timeOutModal(this.currentTime);
    });
  }

  public ngOnInit() {
    this.message = this.isQuestionTimer() ? TWO_MINUTES_MESSAGE : FIFTEEN_MINUTES_MESSAGE;
  }

  public ngOnChanges() {
    if (this.hideTimer) return;
    if (!this.activeTimer) return this.stopCountDown();
    if (this.activeTimer && !this.countDown) this.countDownTimer();
    this.updateMinutes();
    this.currentTime = this.totalTime;
  }

  private isQuestionTimer() {
    return this.totalTime === TIME_QUESTIONS;
  }

  private stopCountDown() {
    this.countDown.unsubscribe();
    this.countDown = null;
  }

  private updateMinutes() {
    this.totalMin = this.totalTime / MINUTE_IN_SECONDS;
  }

  private async timeOutModal(timeUp: number) {
    if (timeUp) return;
    this.countDown.unsubscribe();
    this.modalProvider.openGenericErrorModalWithCallback(timeUpError)
      .afterClosed().subscribe(async () => {
        sessionStorage.removeItem('currentUser');
        await this.authenticationService.logout();
        return this.router.navigateByUrl(ONLINE_LOGIN_URL);
      });
  }
}
