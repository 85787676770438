import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SESSION_ALERT_LENGTH, TRANSFER_AFP_URL } from '@constants';
import { ModalData } from '@interfaces/modalData.interface';
import { ModalProvider } from '@providers/modal/modal';

@Injectable()
export class SessionProvider {
  private sessionModalEvent;
  private sessionAlertLength = SESSION_ALERT_LENGTH;

  constructor(
    public modalProvider: ModalProvider,
    public router: Router,
  ) { }

  public clearSessionEvents() {
    clearTimeout(this.sessionModalEvent);
  }

  public setSessionEvents() {
    this.clearSessionEvents();
    this.sessionModalEvent = setTimeout(() => this.showSessionModal(), this.sessionAlertLength);
  }

  private showSessionModal() {
    if (this.router.url !== TRANSFER_AFP_URL) return;

    this.modalProvider.openModal({
      message: '¡Atención! Tú sesión está por expirar',
      messageDescription: 'Tienes 3 minutos de sesión para realizar el traspaso de tus cuentas a PlanVital',
      iconName: 'i-warning',
      primaryCallback: () => null,
      firstBtnText: 'Continuar',
    } as ModalData);
  }
}
