import { Mockup } from '@interfaces/mockup.interface';

export const EXECUTIVE_PORTFOLIO_MOCKUP: Mockup = {
  success: [{
    response: [{
      month: 1,
      title: '',
      affiliates: [{
        rut: '181',
        id: '4014499',
        name: 'FELIPE IGNACIO',
        fatherLastName: 'MORENO',
        motherLastName: 'SAAVEDRA',
        affiliateType: 'Activo',
        pensionerType: 'NA',
        productsNumber: 1,
        affiliationDate: '2020-02-01T00:00:00-03:00',
        transferDate: '2020-01-21T00:00:00-03:00',
        sellerID: '1537776',
        permanence: 18,
        contacted: true,
      },
      {
        rut: '19',
        id: '432424',
        name: 'ALEXIS',
        fatherLastName: 'SANCHEZ',
        motherLastName: 'SANCHEZ',
        affiliateType: 'Activo',
        pensionerType: 'NA',
        productsNumber: 1,
        affiliationDate: '2020-04-01T00:00:00-03:00',
        transferDate: '2020-04-21T00:00:00-03:00',
        sellerID: '1537776',
        permanence: 18,
        contacted: false,
      }
      ]
    },
    {
      month: 2,
      title: 'Bienvenida',
      affiliates: [{
        rut: '18',
        id: '4014499',
        name: 'FELIPE IGNACIO',
        fatherLastName: 'MORENO',
        motherLastName: 'SAAVEDRA',
        affiliateType: 'Activo',
        pensionerType: 'NA',
        productsNumber: 1,
        departureDate: '2022-07-08T00:00:00-04:00',
        affiliationDate: '2020-02-01T00:00:00-03:00',
        transferDate: '2020-01-21T00:00:00-03:00',
        sellerID: '1537776',
        permanence: 18,
        contacted: true,
      },
      {
        rut: '18353424-1',
        id: '432424',
        name: 'ALEXIS',
        fatherLastName: 'SANCHEZ',
        motherLastName: 'SANCHEZ',
        affiliateType: 'Activo',
        pensionerType: 'NA',
        productsNumber: 1,
        departureDate: '',
        affiliationDate: '2020-04-01T00:00:00-03:00',
        transferDate: '2020-04-21T00:00:00-03:00',
        sellerID: '1537776',
        permanence: 18,
        contacted: false,
      },
      {
        rut: '16313424-1',
        id: '432425',
        name: 'ARTURO',
        fatherLastName: 'VIDAL',
        motherLastName: '',
        affiliateType: 'No Activo',
        pensionerType: 'NA',
        productsNumber: 0,
        departureDate: '2022-07-08T00:00:00-04:00',
        affiliationDate: '2020-04-01T00:00:00-03:00',
        transferDate: '2020-04-21T00:00:00-03:00',
        sellerID: '1537776',
        permanence: 18,
        contacted: false,
      },
      ]
    },
    {
      month: 3,
      title: '',
      affiliates: []
    },
    {
      month: 4,
      title: '',
      affiliates: []
    },
    {
      month: 5,
      title: '',
      affiliates: []
    },
    {
      month: 6,
      title: '',
      affiliates: []
    },
    {
      month: 7,
      title: '',
      affiliates: []
    },
    {
      month: 8,
      title: '',
      affiliates: []
    },
    {
      month: 9,
      title: '',
      affiliates: []
    },
    {
      month: 10,
      title: '',
      affiliates: []
    },
    {
      month: 11,
      title: '',
      affiliates: []
    },
    {
      month: 12,
      title: '',
      affiliates: []
    },
    {
      month: 13,
      title: '',
      affiliates: []
    },
    {
      month: 14,
      title: '',
      affiliates: []
    },
    {
      month: 15,
      title: '',
      affiliates: []
    },
    {
      month: 16,
      title: 'Bienvenida',
      affiliates: []
    },
    {
      month: 17,
      title: '',
      affiliates: []
    },
    {
      month: 18,
      title: '',
      affiliates: []
    },
    {
      month: 19,
      title: '',
      affiliates: []
    },
    {
      month: 20,
      title: '',
      affiliates: []
    },
    {
      month: 21,
      title: '',
      affiliates: [{
        rut: '18',
        id: '4014499',
        name: 'FELIPE IGNACIO',
        fatherLastName: 'MORENO',
        motherLastName: 'SAAVEDRA',
        affiliateType: 'Activo',
        pensionerType: 'NA',
        productsNumber: 1,
        affiliationDate: '2020-02-01T00:00:00-03:00',
        transferDate: '2020-01-21T00:00:00-03:00',
        sellerID: '1537776',
        permanence: 18,
        contacted: true,
      }]
    },
    {
      month: 22,
      title: '',
      affiliates: [{
        rut: '18',
        id: '4014499',
        name: 'FELIPE IGNACIO',
        fatherLastName: 'MORENO',
        motherLastName: 'SAAVEDRA',
        affiliateType: 'Activo',
        pensionerType: 'NA',
        productsNumber: 1,
        affiliationDate: '2020-02-01T00:00:00-03:00',
        transferDate: '2020-01-21T00:00:00-03:00',
        sellerID: '1537776',
        permanence: 18,
        contacted: true,
      }]
    },
    {
      month: 23,
      title: '',
      affiliates: []
    },
    {
      month: 24,
      title: '',
      affiliates: []
    }]
  }],
  failures: [{
    response: {
      code: 500,
      message: 'Error',
      messageDescription: 'Error en el servicio',
    }
  }]
};
