import { EnvInterface } from './env';

export const environment: EnvInterface = {
  name: 'qa',
  baseURL: 'http://34.120.156.248/transfer/',
  baseAppURL: 'http://34.120.156.248/',
  clientServiceURL: 'http://34.120.156.248/clients/',
  mockHttpCalls: false,
  biodesktopEnv: false,
  mockedResponseDelay: 0,
  mockedResponseSuccessRate: 1,
  mockedResponseRandom: false,
  mockedUrlFailure: [],
  servicesNotMocked: [],
  biometricApkUrl: 'http://www.autentia.cl/?action=cl.autentia.operacion',
  biometricCallbackUrl: 'https://us-east4-plan-vital-dev-qa.cloudfunctions.net/biometric-authentication?webcallbackmethod=post',
  baseRequest10URL: 'http://34.120.221.155/',
  siteKey: '6LeoVP0ZAAAAAPvWIJ8yZvU28NlmRvt8xyW0V2lk',
  firebaseConfig: {
    apiKey: 'AIzaSyAGbRArJYj704Prbq8iEtcAx88qBQFH_wk',
    authDomain: 'plan-vital-dev-qa.firebaseapp.com',
    databaseURL: 'https://plan-vital-dev-qa.firebaseio.com',
    projectId: 'plan-vital-dev-qa',
    storageBucket: 'plan-vital-dev-qa.appspot.com',
    messagingSenderId: '381542100011',
  },
  production: false,
  googleReCaptchaSiteKey: '6LfQ6qIUAAAAAKFPMoPWLtXsprw_oIypu1NLiY97',
  googleMapsAPIKey: 'AIzaSyA3izR1s6eMifyjRokeoLaqN1rVGrFbFhA',
  gaMeasurementId: 'G-ES7YRDCSZN',
  pixelFacebooktId: '',
  bioFacialSite: 'https://biometriafacial-qa.planvital.cl/',
  publicSiteUrl: 'http://35.244.156.43/',
  apiwebBaseUrl: 'https://apiweb-qa.planvital.cl/',
};
