import { FormatCurrencyPipe } from '@pipes/curency.pipe';
import { PhonePipe } from '@pipes/phone.pipe';
import { NgModule } from '@angular/core';
import { FormatTimePipe} from '@pipes/time.pipe';

@NgModule({
  imports: [],
  declarations: [
    FormatCurrencyPipe,
    PhonePipe,
    FormatTimePipe
  ],
  exports: [
    FormatCurrencyPipe,
    PhonePipe,
    FormatTimePipe
  ],
  providers: [
    FormatCurrencyPipe,
    PhonePipe,
    FormatTimePipe
  ]
})
export class PipeModule {}
