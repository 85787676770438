import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { planvitalSite } from '@constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() public showBackButton: boolean;

  public titleInBold = 'Únete a AFP PlanVital';
  public titleInNormal = 'y mejora tu pensión';

  public planvitalSite = planvitalSite;
  constructor(private router: Router,
    private location: Location
  ) { }

  public isContactForm() {
    const path = this.router.url;
    return path.includes('/contactar');
  }

  public back() {
    return this.location.back();
  }
}
