import { Mockup } from '@interfaces/mockup.interface';

export const GOVERNMENT_CHECK_MOCK: Mockup = {
  success: [{
    response: {
      bonusAmount: 10000,
      withdrawalAvailable: 'S'
    },
  },
  ],
  failures: [{
    response: {
      code: 50001,
      message: 'Error',
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
    },
  }],
};
