import { Mockup } from '@interfaces/mockup.interface';

export const SEND_ASSISTED_CODE_MOCKUP: Mockup = {
  success: [
    {
      response: true
    }
  ],
  failures: [
    {
      response: {
        statusCode: 400,
        messageError: {
          statusCode: 400,
          error: 'Bad Request',
          message: [
            {
              target: {
                name: 'Felipe Pérez',
                email: '',
                code: '17347377FMd3',
                clientId: 'Uu1rCd4Q3Q8Bs6iBNDDIJcw7o7M='
              },
              value: '',
              property: 'email',
              children: [],
              constraints: {
                matches:
                  `email must match /^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))
                  @((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))
                  $/ regular expression`
              }
            }
          ]
        },
        path: '/authentication/send-code-email'
      }
    }
  ]
};
