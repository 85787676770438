import { Component, OnInit } from '@angular/core';
import { ClientDataResponse } from '@interfaces/clientData.interface';
import { Util } from '@util';
import { Location } from '@angular/common';
import { CLIENT_DATA } from 'util/storage.constants';
import { InactivityService } from '@services/inactivityTime/inactivityTime.service';

@Component({
  selector: 'app-send-password',
  templateUrl: './send-password.component.html',
  styleUrls: ['./send-password.component.scss']
})
export class SendPasswordComponent implements OnInit {

  public disabledMail = true;
  public disabledSMS = false;
  public selectedMethod: string;
  public clientData: ClientDataResponse;

  constructor(
    public util: Util,
    private location: Location,
    private inactivityService: InactivityService

  ) { }

  public ngOnInit() {
    this.inactivityService.startInactivityPostVentaService();
    localStorage.setItem('sendPassword', JSON.stringify({ option: 'password' }));
    localStorage.setItem('originAffiliate', 'sendPassword');
    this.clientData = JSON.parse(localStorage.getItem(CLIENT_DATA));
    this.checkDisabledMethods();
  }

  private checkDisabledMethods(): void {
    this.disabledSMS = !this.clientData.cellphoneNumber;
    this.disabledMail = !this.clientData.email;
    if (this.disabledMail) this.select('sms', this.disabledSMS);
    if (this.disabledSMS) this.select('mail', this.disabledMail);
  }

  public select(method: string, disabled: boolean): void {
    if (disabled) return;
    if (this.selectedMethod === method) {
      if (method === 'sms' && this.disabledMail) return;
      if (method === 'mail' && this.disabledSMS) return;
      this.selectedMethod = null;
      return;
    }
    this.selectedMethod = method;
  }

  public isSelected(method: string): boolean {
    return this.selectedMethod === method;
  }

  public send() {
    const state = {
      option: 'password',
      method: this.selectedMethod
    };
    localStorage.setItem('sendPassword', JSON.stringify(state));
    this.location.back();
  }

  public goBack() {
    localStorage.setItem('sendPassword', JSON.stringify({ option: 'password' }));
    this.location.back();
  }
}
