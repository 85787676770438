import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { COMMON_QUANTITY_NAMES } from '@constants';
import { ExecutivePorfolioResponse } from '@interfaces/executivePortfolioResponse.interface';
import { InfoExecutivePortfolio } from '@interfaces/infoExecutivePortfolio.interface';
import { Util } from '@util';

const LAST_MONTH_WITH_ACTION = 16;
@Component({
  selector: 'app-portfolio-summary',
  templateUrl: './portfolio-summary.component.html',
  styleUrls: ['./portfolio-summary.component.scss']
})
export class PortfolioSummaryComponent implements OnInit {
  @Input() public infoExecutive: InfoExecutivePortfolio;
  @Input() public portfolio: ExecutivePorfolioResponse[];
  @Output() public monthSelect$ = new EventEmitter<number>();
  public typeCard = 'executive';
  private allMonthChecked = false;
  private monthsNotShown = [];

  constructor(
    public util: Util,
  ) { }

  get executiveName() {
    if (!this.infoExecutive.name) return 'Solicita actualizar tu información de ejecutivo';

    let splitted = this.infoExecutive.name.split(' ');
    splitted = splitted.length > COMMON_QUANTITY_NAMES ? this.util.mapCompoundNames(this.infoExecutive.name) : splitted;
    const name = this.util.capitalizeText(splitted[2]);
    const lastName = this.util.capitalizeText(splitted[0]);
    const secondLasName = splitted[1] ? this.util.capitalizeText(splitted[1]) : '';
    return `${name} ${lastName} ${secondLasName}`;
  }

  public ngOnInit() {
    const portfolioLength = this.portfolio.length - 1;
    this.getMonthNotShown(portfolioLength);
  }

  private getMonthNotShown(portfolioMonth: number) {
    if (this.portfolio[portfolioMonth].affiliates.length || portfolioMonth < LAST_MONTH_WITH_ACTION) return this.monthsNotShown;
    this.monthsNotShown.push(portfolioMonth + 1);
    return this.getMonthNotShown(portfolioMonth - 1);
  }

  public getMonthNoContact(monthNumber: number): number {
    return this.portfolio.find((element) => element.month === monthNumber).affiliates
      .filter((affiliates) => affiliates.contacted === false).length;
  }

  public emitMonthSelect(monthNumber: number) {
    this.monthSelect$.emit(monthNumber);
  }

  public clickCheckbox() {
    this.allMonthChecked = !this.allMonthChecked;
  }

  public displayMonth(objectMonth: ExecutivePorfolioResponse): boolean {
    const monthNotShown = this.monthsNotShown.some(month => month === objectMonth.month);
    if (monthNotShown) return false;
    if (this.allMonthChecked) return true;
    if (objectMonth.title) return true;
    return false;
  }
}
