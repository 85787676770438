import { Injectable } from '@angular/core';
import { ClientService } from '@services/client/client.service';

@Injectable()
export class EmailDomainValidator {
  constructor(private clientService: ClientService) {
  }

  public async validateEmailDomain(email: string): Promise<boolean> {
    return this.clientService.validateEmailDomain(email)
    .toPromise()
    .then((response) => response.isValidDomain)
    .catch(() => true);
  }
}
