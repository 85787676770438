import { Component, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { CERTIFICATE_TYPES, SELECT_QUOTE_URL, NO_CELLPHONE_NUMBER_MESSAGE, WAITING_TIME_FAILURE, WAITING_TIME_SUCCESS } from '@constants';
import { Router } from '@angular/router';
import { PostVentaService } from '@services/post-venta/post-venta.service';
import { CLIENT_DATA } from 'util/storage.constants';
import { TransactionTypes, CertificateTypes, GeneralTypes } from '../quote-certificate/quote-certificate.component';
import { CertificateRequest } from '@interfaces/certificate.request.interface';
import { finalize } from 'rxjs/operators';
import { AccountCert, ClientDataResponse } from '@interfaces/clientData.interface';
import { CertificateModel } from '@interfaces/certificate.interface';
import { LoadingProvider } from '@providers/loading/loading';
import { AuthenticationService } from '@services/authentication/authentication.service';

@Component({
  selector: 'app-affiliate-certificate',
  templateUrl: './affiliate-certificate.component.html',
  styleUrls: ['./affiliate-certificate.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AffiliateCertificateComponent implements OnInit {
  @Output()
  public resetView: EventEmitter<any> = new EventEmitter();
  public clientData: ClientDataResponse;
  public generalCertificate: Array<CertificateModel>;
  public transactionalCertificate: Array<CertificateModel>;
  public noCellphoneMessage = NO_CELLPHONE_NUMBER_MESSAGE;
  public canShowAffiliateCertificate = false;
  public canShowRemunerationsCertificate = false;
  public canShowVacationsCertificate = false;
  public serviceError: boolean;
  public sendingCertificate: boolean;
  public successCertificate: boolean;
  public readyCertificate: boolean;
  public sendingTypeCertificate: CertificateTypes;
  public loading: boolean;
  public productType: 'CCO' | 'CAF' = 'CCO';

  constructor(
    private router: Router,
    public postVenta: PostVentaService,
    private loadingProvider: LoadingProvider,
    private authenticationService: AuthenticationService,
  ) { }

  public async ngOnInit() {
    this.loadClientData();
    this.loading = true;
    const sendingCertification = localStorage.getItem('transactionCertificateType');
    this.loadAccounts(sendingCertification);
    this.sendAffiliateCertificate();
  }

  public loadClientData() {
    this.clientData = JSON.parse(localStorage.getItem(CLIENT_DATA));
  }

  public loadAccounts(isFromInternal) {
    this.loadingProvider.showLoading();
    this.postVenta.currentAccounts().pipe(
      finalize(() => {
        this.filterCertificates();
        if (!isFromInternal) this.loadingProvider.hideLoading();
        this.loading = false;
      })
    )
    .subscribe(({accounts}) => {
      if (this.hasMandatoryAccount(accounts)) {
        this.canShowAffiliateCertificate = true;
        this.canShowRemunerationsCertificate = true;
        this.canShowVacationsCertificate = true;
        return;
      }
      this.canShowAffiliateCertificate = this.hasAffiliateVolunteerAccount(accounts);
      this.serviceError = false;
    }, () => {
      this.serviceError = true;
    });
  }

  private filterCertificates() {
    this.generalCertificate = CERTIFICATE_TYPES['general'].filter(({ name }) => {
      if (name === 'Afiliación') return this.canShowAffiliateCertificate;
      if (name === 'Vacaciones Progresiva') return this.canShowVacationsCertificate;
      return name;
    });
    this.transactionalCertificate = CERTIFICATE_TYPES['transactional'].filter(({ name }) => {
      if (name === 'Renta Imponible') return this.canShowRemunerationsCertificate;
      return name;
    });
  }

  public goToSelectQuote(certificate: string): void {
    const email = this.clientData.email;
    localStorage.setItem('certificateType', certificate);
    localStorage.setItem('email', email);
    this.router.navigateByUrl(SELECT_QUOTE_URL);
  }

  public goToContactData() {
    this.resetView.emit('true');
  }

  private async validateAndSetToken(rut: string) {
    const tokenResponse = await this.authenticationService.getToken(rut).toPromise();
    this.authenticationService.setAffiliateIdToken(tokenResponse.token);
  }


  public sendAffiliateCertificate(certificateType?: CertificateTypes) {
    const executiveInfo = localStorage.getItem('infoExecutive');
    const transactionCertificateType = certificateType || localStorage.getItem('transactionCertificateType') as CertificateTypes;
    this.productType = localStorage.getItem('productTypeSelected') as 'CCO' | 'CAF';
    this.sendingTypeCertificate = transactionCertificateType;
    localStorage.removeItem('transactionCertificateType');
    localStorage.removeItem('productTypeSelected');
    if (!transactionCertificateType) return;
    this.sendingCertificate = true;
    const request = this.isTransactionCertificate(transactionCertificateType) ?
      this.createTransactionCertificateRequestPayload(transactionCertificateType) :
      this.createGeneralCertificateRequestPayload(transactionCertificateType);
    if (request) this.getCertificate({ executiveInfo, ...request } as CertificateRequest);
  }

  private getCertificate(request: CertificateRequest) {
    this.loadingProvider.showLoading();
    this.postVenta.getCertificate(request).pipe(
      finalize(() => {
        this.sendingCertificate = false;
        this.readyCertificate = true;
        this.validateAndSetToken(this.clientData.rut);
        this.loadingProvider.hideLoading();
      })).subscribe(() => {
        this.successCertificate = true;
        setTimeout(() => {
          this.sendingTypeCertificate = undefined;
          this.readyCertificate = false;
        }, WAITING_TIME_SUCCESS * 1000);
      }, () => {
        this.successCertificate = false;
        setTimeout(() => {
          this.sendingTypeCertificate = undefined;
          this.readyCertificate = false;
        }, WAITING_TIME_FAILURE * 1000);
      });
  }

  private createTransactionCertificateRequestPayload(transactionCertificateType: TransactionTypes) {
    const toDateString = localStorage.getItem('toDate');
    const fromDateString = localStorage.getItem('fromDate');
    const token = sessionStorage.getItem('affiliateIdToken');
    localStorage.removeItem('toDate');
    localStorage.removeItem('fromDate');
    if (!toDateString || !fromDateString || !token) return;
    const toDate = this.getLastDay(new Date(toDateString));
    const fromDate = this.getFirstDay(new Date(fromDateString));
    return {
      token,
      rut: this.clientData.rut,
      name: this.clientData.name,
      fromDate,
      toDate,
      certificateType: transactionCertificateType,
      productType: this.productType,
      email: this.clientData.email
    } as CertificateRequest;
  }

  private createGeneralCertificateRequestPayload(transactionCertificateType: GeneralTypes) {
    const token = sessionStorage.getItem('affiliateIdToken');
    if (!token) return;
    return {
      token,
      rut: this.clientData.rut,
      name: this.clientData.name,
      certificateType: transactionCertificateType,
      email: this.clientData.email
    } as CertificateRequest;
  }

  private isTransactionCertificate(certificateType: CertificateTypes): certificateType is TransactionTypes {
    return ['quotations', 'remunerations'].some(type => type === certificateType);
  }

  private getLastDay(date: Date) {
    const dateSplited = date.toJSON().substring(0, 10).split('-');
    return new Date(Number(dateSplited[0]), Number(dateSplited[1]), 0).toJSON().substring(0, 10);
  }

  private getFirstDay(date: Date) {
    const dateSplited = date.toJSON().substring(0, 10).split('-');
    return new Date(Number(dateSplited[0]), Number(dateSplited[1]) - 1, 1).toJSON().substring(0, 10);
  }

  private hasMandatoryAccount(accounts: Array<AccountCert>) {
    return accounts.some(account => account.type === 'CCO');
  }

  private hasAffiliateVolunteerAccount(accounts: Array<AccountCert>) {
    return accounts.some(account => account.type === 'CAF');
  }

}
